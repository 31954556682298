import { Howl } from 'howler';
import outputMp3 from './output.mp3';
import outputM4a from './output.m4a';
import outputAc3 from './output.ac3';
import outputOgg from './output.ogg';
import outputEnMp3 from './output-en.mp3';
import outputEnM4a from './output-en.m4a';
import outputEnAc3 from './output-en.ac3';
import outputEnOgg from './output-en.ogg';
import outputDeMp3 from './output-de.mp3';
import outputDeM4a from './output-de.m4a';
import outputDeAc3 from './output-de.ac3';
import outputDeOgg from './output-de.ogg';

/**
 * Sprite for being able to play 'well-done' audio fragments.
 * The audio itself was created via https://cloud.google.com/text-to-speech/.
 */
const sound = {
  nl: new Howl({
    src: [
      outputOgg,
      outputM4a,
      outputMp3,
      outputAc3,
    ],
    sprite: {
      'dat-heb-je-goed-gedaan': [0, 1907.5056689342405],
      'ga-zo-door': [3000, 1372.9251700680277],
      'goed-bezig': [6000, 1345.0113378684812],
      'goed-gedaan': [9000, 1197.1882086167795],
      'op-naar-de-volgende-pagina': [12000, 2402.81179138322],
      'wat-goed': [16000, 1029.1383219954646],
    },
  }),
  en: new Howl({
    src: [
      outputEnOgg,
      outputEnM4a,
      outputEnMp3,
      outputEnAc3,
    ],
    sprite: {
      welldone1_en: [0, 1008.0045351473923],
      welldone2_en: [3000, 1032.018140589569],
      welldone3_en: [6000, 1488.004535147392],
      welldone4_en: [9000, 1068],
      welldone5_en: [12000, 1298.4013605442177],
      welldone6_en: [16000, 1493.6009070294784],
    },
  }),
  de: new Howl({
    src: [
      outputDeOgg,
      outputDeM4a,
      outputDeMp3,
      outputDeAc3,
    ],
    sprite: {
      welldone1_de: [0, 984.013],
      welldone2_de: [2000, 888.004],
      welldone3_de: [4000, 2400],
      welldone4_de: [8000, 1728.0045],
      welldone5_de: [11000, 1032.0181],
      welldone6_de: [14000, 1272.0181],
    },
  }),
};

export default sound;
