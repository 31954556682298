import React from 'react';
import { Route, IndexRedirect, IndexRoute } from 'react-router';
import axios from 'axios';
import { store } from '../shared/store';
import App from './App';
import LoginChoice from '../login/LoginChoice';
import LoginUser from '../login/LoginUser';
import Logout from '../login/Logout';
import ChooseGroup from '../group/ChooseGroup';
import ChooseCategory from '../category/ChooseCategory';
import ChooseAction from '../action/ChooseAction';
import ChooseProgress from '../progress/ChooseProgress';
import ReadingAssignment from '../reading/ReadingAssignment';

function checkAuth(nextState, redirect) {
  const { authentication: { isAuthenticated } } = store.getState();
  if (!isAuthenticated) {
    redirect({
      pathname: '/login',
    });
  }
}

function checkUserRole(nextRouterState, redirect, callback) {
  const { REACT_APP_TUTO_API_URL } = process.env;
  axios({
    method: 'get',
    url: `${REACT_APP_TUTO_API_URL}/user`,
  }).then((response) => {
    if (!response.data.role.includes('TEACHER')) {
      redirect({
        pathname: '/themas',
        query: {
          groupId: nextRouterState.params.groupId,
        },
      });
    }
    callback();
  });
}

export default (
  <Route component={App}>
    <Route path="/login" component={LoginChoice} />
    <Route path="/login/:userType" component={LoginUser} />
    <Route path="/login/:userType/:orgId" component={LoginUser} />
    <Route path="/logout" component={Logout} />
    <Route path="/" onEnter={checkAuth}>
      <IndexRedirect to="groepen" />
      <Route path="groepen" component={ChooseGroup} />
      <Route path="groepen/:groupId" onEnter={checkUserRole}>
        <IndexRoute component={ChooseAction} />
        <Route path="voortgang(/:themeId)(/:weekId)(/:assignmentId)" component={ChooseProgress} />
      </Route>
      <Route path="themas" component={ChooseCategory} />
      <Route path="themas/:themeId">
        <IndexRedirect to="weken" />
        <Route path="weken" component={ChooseCategory} />
        <Route path="weken/:weekId">
          <IndexRedirect to="leesopdrachten" />
          <Route path="leesopdrachten" component={ChooseCategory} />
          <Route path="leesopdrachten/:assignmentId" component={ReadingAssignment} />
        </Route>
      </Route>
    </Route>
  </Route>
);
