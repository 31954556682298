import styled from 'styled-components';

const Container = styled.div`
  align-items: center;
  background-color: #fff;
  bottom: 0;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  left: 0;
  position: absolute;
  right: 0;
  text-align: center;
  top: 0;
  z-index: 99;
`;

export default Container;
