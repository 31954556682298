import styled from 'styled-components';

const Title = styled.div`
  background-color: #153952;
  display: block;
  height: 40px;
  margin: 0;
  padding: .25rem 0;
  position: relative;
  text-align: center;
  width: 100%;
`;

export default Title;
