import styled, { css } from 'styled-components';
import PropTypes from 'prop-types';

const P = styled.p`
  margin: 0;
  padding: 0;
  position: relative;

  ${({ marked }) => marked && css`
    background-color: #c7e6f3;
  `}

  ${({ marginBefore }) => marginBefore && css`
    margin-top: 1.5rem;
  `}  

  ${({ marginAfter }) => marginAfter && css`
    margin-bottom: 1.5rem;
  `}
`;

P.propTypes = {
  marked: PropTypes.bool,
  marginBefore: PropTypes.bool,
  marginAfter: PropTypes.bool,
};

P.defaultProps = {
  marked: false,
  marginBefore: false,
  marginAfter: false,
};

export default P;
