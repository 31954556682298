/**
 * File with some utility functions everyone will want to use;
 * Rock on 🎸🤘!
 */

/**
 * An async function that allows to wait.
 *
 * @param {number} time=1000 - Miliseconds to wait before resolving.
 * @returns {Promise} - Resolves once the timeout has ended.
 */
export function wait(time = 1000) {
  return new Promise((resolve, reject) => {
    if (typeof time !== 'number') {
      reject(new Error('no valid time to wait passed'));
    }

    setTimeout(() => {
      resolve('the wait is over');
    }, time);
  });
}

/**
 * Get a random value from an array.
 *
 * @param {array} array - Array to randomly get a value from.
 * @returns {string} - A value from the array.
 */
export function getRandomValueFromArray(array) {
  const min = 0;
  const max = array.length - 1;
  const id = Math.floor(Math.random() * (max - min + 1)) + min;
  return array[id];
}
