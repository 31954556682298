import styled, { css } from 'styled-components';
import { Link } from 'react-router';

const Button = styled(Link)`
  border: 1px solid #d3d3d3;
  display: inline-block;
  font-size: 1rem;
  font-weight: normal;
  padding: 1rem 0.8rem;
  position: relative;
  user-select: none;
  text-align: left;
  width: 233px;

  &:hover {
    background-color: #eee;
    cursor: pointer;
  }

  ${({ icon }) => icon && css`
    &::after {
      background-image: url("${(props) => props.icon}");
      background-size: 2.5rem 2.5rem;
      background-repeat: no-repeat;
      content: ' ';
      height: 2.5rem;
      margin-top: -0.5rem;
      position: absolute;
      right: 1rem;
      width: 2.5rem;
    }
  `}
`;

export default Button;
