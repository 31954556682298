import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import styled from 'styled-components';
import Button from './Buttons';
import { prevAssignmentPage, nextAssignmentPage, showErrorMessage } from './ReadingAssignmentActions';

const Pagination = styled.div`
  display: flex;
  flex-direction: row;
  font-size: 0.889rem;
  justify-content: center;
`;

const PageStatus = styled.span`
  display: flex;
  flex-direction: column;
  margin-right: 0.516rem;
  justify-content: center;
  text-align: center;
`;

export class PaginationControls extends Component {
  static propTypes = {
    activePage: PropTypes.shape({
      pageId: PropTypes.number.isRequired,
      allowNavigation: PropTypes.bool,
    }).isRequired,
    pageCount: PropTypes.number.isRequired,
    prevAssignmentPage: PropTypes.func.isRequired,
    nextAssignmentPage: PropTypes.func.isRequired,
    showErrorMessage: PropTypes.func.isRequired,
    disabled: PropTypes.bool.isRequired,
    location: PropTypes.shape({
      pathname: PropTypes.string.isRequired,
      query: PropTypes.shape({
        pageId: PropTypes.string,
      }).isRequired,
    }).isRequired,
  };

  constructor(props) {
    super(props);

    this.nextPage = this.nextPage.bind(this);
    this.prevPage = this.prevPage.bind(this);
  }

  prevPage() {
    this.props.prevAssignmentPage(this.props.activePage.pageId);
  }

  nextPage(event) {
    if (this.props.activePage.allowNavigation) {
      this.props.nextAssignmentPage(this.props.activePage.pageId);
    } else {
      event.preventDefault();
      this.props.showErrorMessage();
    }
  }

  render() {
    const {
      activePage: {
        pageId,
      },
      pageCount,
      disabled,
      location: {
        pathname,
      },
    } = this.props;

    const next = {
      pathname,
      query: {
        pageId: Number(pageId) + 1,
      },
    };

    const previous = {
      pathname,
      query: {
        pageId: Number(pageId) - 1,
      },
    };

    return (
      <Pagination>
        <Button control="prev" hidden={pageId <= 1 || disabled} to={previous} onClick={this.prevPage} />
        <Button control="prev-disabled" hidden={pageId > 1 && !disabled} />
        <PageStatus>
          {pageId}
          /
          {pageCount}
        </PageStatus>
        <Button control="next" hidden={pageId >= pageCount || disabled} to={next} onClick={this.nextPage} />
        <Button control="next-disabled" hidden={pageId < pageCount && !disabled} />
      </Pagination>
    );
  }
}

// container part below
const mapStateToProps = (state) => ({ ...state.ReadingAssignment });

const mapDispatchToProps = {
  prevAssignmentPage,
  nextAssignmentPage,
  showErrorMessage,
};

export default connect(mapStateToProps, mapDispatchToProps)(PaginationControls);
