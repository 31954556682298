import { browserHistory } from 'react-router';
import { doAuthentication } from '../shared/itslanguage';

/*
 * Action types
 */

export const LOGIN_REQUEST = 'LOGIN_REQUEST';
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGIN_FAILURE = 'LOGIN_FAILURE';
export const LOGIN_RESET = 'LOGIN_RESET';

/*
 * Other constants
 */
export const LoginStates = {
  isFetching: false,
  isAuthenticated: false,
  errorMessage: '',
};

/*
 * Action creators
 */

export function requestLogin({
  schoolcode, username, emailaddress, password,
}) {
  return {
    type: LOGIN_REQUEST,
    isFetching: true,
    isAuthenticated: false,
    errorMessage: '',
    credentials: {
      schoolcode,
      username,
      emailaddress,
      password,
    },
  };
}

export function receiveLogin(user) {
  return {
    type: LOGIN_SUCCESS,
    isFetching: false,
    isAuthenticated: true,
    user,
  };
}

export function loginError(errorMessage) {
  return {
    type: LOGIN_FAILURE,
    isFetching: false,
    isAuthenticated: false,
    errorMessage,
  };
}

export function loginReset() {
  return {
    type: LOGIN_RESET,
    ...LoginStates,
  };
}

export function logoutUser() {
  return (dispatch) => dispatch(loginReset());
}

export function loginUser(credentials, location) {
  return (dispatch) => {
    dispatch(requestLogin(credentials));
    doAuthentication(credentials)
      .then((result) => {
        dispatch(receiveLogin(result));
        browserHistory.push(location || '/');
      })
      .catch((error) => {
        dispatch(loginError(error));
      });
  };
}
