import * as Sentry from '@sentry/browser';

const {
  REACT_APP_SENTRY_DSN,
  REACT_APP_ENVIRONMENT,
  REACT_APP_RELEASE,
} = process.env;

if (REACT_APP_SENTRY_DSN) {
  Sentry.init({
    dsn: REACT_APP_SENTRY_DSN,
    environment: REACT_APP_ENVIRONMENT,
    release: `its-tuto@${REACT_APP_RELEASE}`,
  });
} else {
  // eslint-disable-next-line no-console
  console.warn('Sentry config not loaded, make sure you set the correct DSN!');
}
