import React from 'react';
import PropTypes from 'prop-types';
import Line from '../Line';

function Lines(props) {
  const { sentences, startIndicatorEnabled } = props;
  const lines = Object.keys(sentences);

  return (
    <div>
      {
        lines.map((item) => (
          <Line
            key={`line-${item}`}
            sentence={sentences[item]}
            startIndicatorEnabled={startIndicatorEnabled}
          />
        ))
      }
    </div>
  );
}

Lines.propTypes = {
  sentences: PropTypes.object.isRequired,
  startIndicatorEnabled: PropTypes.bool,
};

export default Lines;
