import React, { Component } from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';

const verticalCenteredText = () => `
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

const ProgressBar = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: flex-start;
  overflow: hidden;
  height: 50px;
`;

const ProgressItem = styled.div`
  ${verticalCenteredText}
  ${(props) => {
    if (!props.activeItem) {
      return css`
        background: linear-gradient(-45deg, #f4fafc 0%, #deeef4 100%);
        color: #36556a;
      `;
    }
    return css`
        background: linear-gradient(-45deg, #77b4f6 0%, #357bb1 100%);
        color: #fff;
      `;
  }}
  transform: rotate(45deg);
  border: 1rem solid #deeef4;
  margin-top: -121px;
  margin-left: 0;
  padding: 128px;
  width: 1px;
  z-index: ${(prop) => (prop.z ? prop.z : '1')};

  > span {
    display: block;
    transform: rotate(-45deg);
    margin-top: -44px;
    margin-left: -92px;
    padding-left: 26px;
    width: 226px;
  }

  &:first-child {
    > span {
      padding-left: 0;
    }
  }
`;

export default class ChoiceProgressSteps extends Component {
  static propTypes = {
    progressSteps: PropTypes.array.isRequired,
  };

  static defaultProps = {
    progressSteps: [],
  };

  render() {
    const { progressSteps: items } = this.props;
    const nItems = (items && items.length) ? items.length : 0;

    return (

      <ProgressBar>
        {items
          && items.map((item, index) => (
            // eslint-disable-next-line react/no-array-index-key
            <ProgressItem z={nItems - index} activeItem={item.isActive} key={index}>
              <span>
                { item.name }
              </span>
            </ProgressItem>
          ))}
      </ProgressBar>
    );
  }
}
