import { combineReducers } from 'redux';
import {
  LOGIN_REQUEST,
  LOGIN_SUCCESS,
  LOGIN_FAILURE,
  LOGIN_RESET,
  LoginStates,
} from '../login/LoginActions';
import {
  UPDATE_BACKGROUND_COLOR,
  defaultAppStates,
} from '../app/AppActions';
import {
  UPDATE_MIC_CHECK,
  defaultMicCheckStates,
} from '../containers/MicrophoneCheckDialog/actions';
import {
  UPDATE_CHOICE_PAGE_STATUS,
  UPDATE_CHOICE_PROGRESS_STEPS,
  PREV_CHOICE_PAGE,
  NEXT_CHOICE_PAGE,
  UPDATE_CHOICES_COUNT,
  UPDATE_CHOICE_PAGES_COUNT,
  UPDATE_ACTIVE_CHOICES,
  RESET_CHOICE_PAGE_NAV_STATE,
  RESET_CHOICE_PAGE_STATE,
  defaultChoicePageStates,
} from '../choice/ChoiceActions';
import {
  UPDATE_READING_ASSIGNMENT_PAGE,
  PREV_ASSIGNMENT_PAGE,
  NEXT_ASSIGNMENT_PAGE,
  RESET_ASSIGNMENT_PAGE,
  UPDATE_PAGE_COUNT,
  UPDATE_SPEECHCHALLENGE,
  UPDATE_SPEECHCHALLENGE_RECORDING,
  UPDATE_FEEDBACK_ON_WORDS,
  RESET_FEEDBACK_ON_WORDS,
  ALLOW_NAVIGATION,
  PROHIBIT_NAVIGATION,
  SHOW_ERROR_MESSAGE,
  CLOSE_ERROR_MESSAGE,
  SHOW_CONNECTION_ERROR_MESSAGE,
  CLOSE_CONNECTION_ERROR_MESSAGE,
  SHOW_READY_MESSAGE,
  CLOSE_READY_MESSAGE,
  UPDATE_RECORDING_STATE,
  ENABLE_FEEDBACK_MODEL,
  DISABLE_FEEDBACK_MODEL,
  readingStates,
} from '../reading/ReadingAssignmentActions';

const authentication = (state = LoginStates, action) => {
  switch (action.type) {
    case LOGIN_REQUEST:
      return {
        ...state,
        isFetching: true,
        isAuthenticated: false,
        errorMessage: '',
        credentials: action.credentials,
      };
    case LOGIN_SUCCESS:
      return {
        ...state,
        isFetching: false,
        isAuthenticated: true,
        errorMessage: '',
        user: action.user,
        credentials: {
          ...state.credentials,
          password: null,
        },
      };
    case LOGIN_FAILURE:
      return {
        ...state,
        isFetching: false,
        isAuthenticated: false,
        errorMessage: action.errorMessage,
      };
    case LOGIN_RESET:
      return LoginStates;
    default:
      return state;
  }
};

const MicrophoneCheck = (state = defaultMicCheckStates, action) => {
  switch (action.type) {
    case UPDATE_MIC_CHECK:
      return {
        ...state,
        micChecked: action.micChecked,
      };
    default:
      return state;
  }
};

const App = (state = defaultAppStates, action) => {
  switch (action.type) {
    case UPDATE_BACKGROUND_COLOR:
      return {
        ...state,
        backgroundColor: action.backgroundColor,
      };
    default:
      return state;
  }
};

const Choices = (state = defaultChoicePageStates, action) => {
  switch (action.type) {
    case UPDATE_CHOICE_PAGE_STATUS:
      return {
        ...state,
        ...action,
        activeList: {
          ...state.activeList,
          ...action.activeList,
        },
      };
    case UPDATE_CHOICE_PROGRESS_STEPS:
      return {
        ...state,
        choiceProgressSteps: action.items,
      };
    case PREV_CHOICE_PAGE:
    case NEXT_CHOICE_PAGE:
      return {
        ...state,
        activeList: {
          ...state.activeList,
          pageId: action.pageId,
        },
      };
    case UPDATE_CHOICES_COUNT:
      return {
        ...state,
        choicesCount: action.choicesCount,
      };
    case UPDATE_CHOICE_PAGES_COUNT:
      return {
        ...state,
        choicePagesCount: action.choicePagesCount,
      };
    case UPDATE_ACTIVE_CHOICES:
      return {
        ...state,
        activeList: {
          ...state.activeList,
          choices: action.choices,
        },
      };
    case RESET_CHOICE_PAGE_NAV_STATE:
      return {
        ...state,
        choicesCount: 0,
        choicePagesCount: 0,
        activeList: {
          ...defaultChoicePageStates.activeList,
        },
      };
    case RESET_CHOICE_PAGE_STATE:
      return {
        ...defaultChoicePageStates,
      };
    default:
      return state;
  }
};

const ReadingAssignment = (state = readingStates, action) => {
  switch (action.type) {
    case UPDATE_READING_ASSIGNMENT_PAGE:
      return {
        ...state,
        ...action,
        activePage: {
          ...state.activePage,
          ...action.activePage,
        },
      };
    case PREV_ASSIGNMENT_PAGE:
    case NEXT_ASSIGNMENT_PAGE:
    case RESET_ASSIGNMENT_PAGE:
      return {
        ...state,
        recordingState: null,
        activePage: {
          startAtWord: 1,
          pageId: action.pageId,
          feedbackOnWords: action.feedbackOnWords,
          speechChallenge: action.speechChallenge,
        },
      };
    case UPDATE_PAGE_COUNT:
      return {
        ...state,
        pageCount: action.pageCount,
      };
    case UPDATE_SPEECHCHALLENGE:
      return {
        ...state,
        activePage: {
          ...state.activePage,
          speechChallenge: action.speechChallenge,
        },
      };
    case UPDATE_SPEECHCHALLENGE_RECORDING:
      return {
        ...state,
        activePage: {
          ...state.activePage,
          speechChallengeRecording: action.speechChallengeRecording,
        },
      };
    case UPDATE_FEEDBACK_ON_WORDS:
      return {
        ...state,
        activePage: {
          ...state.activePage,
          feedbackOnWords: action.feedbackOnWords,
        },
      };
    case RESET_FEEDBACK_ON_WORDS:
      return {
        ...state,
        activePage: {
          ...state.activePage,
          feedbackOnWords: action.feedbackOnWords,
          speechChallengeRecording: {
            ...state.activePage.speechChallengeRecording,
            sentences: [],
          },
        },
      };
    case ALLOW_NAVIGATION:
    case PROHIBIT_NAVIGATION:
      return {
        ...state,
        activePage: {
          ...state.activePage,
          allowNavigation: action.allowNavigation,
        },
      };
    case CLOSE_ERROR_MESSAGE:
    case SHOW_ERROR_MESSAGE:
      return {
        ...state,
        showErrorMessage: action.showErrorMessage,
      };
    case SHOW_CONNECTION_ERROR_MESSAGE:
    case CLOSE_CONNECTION_ERROR_MESSAGE:
      return {
        ...state,
        showConnectionErrorMessage: action.showConnectionErrorMessage,
      };
    case CLOSE_READY_MESSAGE:
    case SHOW_READY_MESSAGE:
      return {
        ...state,
        showReadyMessage: action.showReadyMessage,
      };
    case UPDATE_RECORDING_STATE:
      return {
        ...state,
        recordingState: action.recordingState,
        activePage: {
          ...state.activePage,
          startAtWord: action.word || state.activePage.startAtWord,
        },
      };
    case ENABLE_FEEDBACK_MODEL:
    case DISABLE_FEEDBACK_MODEL:
      return {
        ...state,
        feedbackModelEnabled: action.feedbackModelEnabled,
      };
    default:
      return state;
  }
};

const reducers = combineReducers({
  authentication,
  App,
  MicrophoneCheck,
  Choices,
  ReadingAssignment,
});

export default reducers;
