import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { useTranslation } from 'react-i18next';
import Bar from './Bar';
import Message from './Message';
import Link from './Link';
import User from './User';
import Logo from './Logo';
import { getTenantId } from '../../shared/itslanguage';

const { REACT_APP_TUTO_HEADER_MESSAGE: headerMessage = '' } = process.env;

const Button = styled.button`
  background: linear-gradient(180deg, #77b4f6 0%, #357bb1 100%);
  border-radius: 1px;
  border-width: 0;
  box-shadow: 0 3px 0 0 rgba(189, 187, 187, 0.5);
  color: #fff;
  display: block;
  font-size: 0.6rem;
  height: 6.92px;
  line-height: 1;
  margin-top: 5px;
  margin-bottom: 5px;
  padding: 0 1rem;
  position: relative;
  text-align: left;
  width: 100%;

  &:hover {
    background: #357bb1;
    cursor: pointer;
  }

  &:active {
    background: #02487e;
  }

  &:focus {
    outline: none;
  }
`;

/**
 * Header component. It can contain three items:
 *  - A profile popup
 *  - Optionally, a clickable Header
 *  - The Malmberg logo
 */
const Header = (props) => {
  const tenantId = getTenantId();
  const header = `${process.env.PUBLIC_URL}/${tenantId}/header.png`;
  const { t, i18n } = useTranslation();

  const lngs = {
    en: { nativeName: 'English' },
    nl: { nativeName: 'Nederlands' },
    de: { nativeName: 'Deutsche' },
  };

  return (
    <Bar>
      { props.authenticated && (
        <div>
          <Link to="/">{t('header.start')}</Link>
          <Link as="button" onClick={props.micCheckClicked}>{t('header.mictest')}</Link>
          <Link to="/logout">{t('header.logout')}</Link>
          { (props.username && props.usertype)
          && (
          <User>
            <span>{props.username}</span>
            &nbsp;|&nbsp;
            <span>{props.usertype}</span>
          </User>
          )}
        </div>
      )}
      <div>
        {Object.keys(lngs).map((lng) => (
          <div key={lng}>
            <Button key={lng} style={{ fontWeight: i18n.resolvedLanguage === lng ? 'bold' : 'normal' }} type="submit" onClick={() => i18n.changeLanguage(lng)}>
              {lngs[lng].nativeName}
            </Button>
          </div>
        ))}
      </div>
      <div>
        <Logo src={header} alt={t('header.customerlogo')} />
      </div>
      { headerMessage && <Message>{ headerMessage }</Message>}
    </Bar>
  );
};

Header.propTypes = {
  authenticated: PropTypes.bool.isRequired,
  username: PropTypes.string.isRequired,
  usertype: PropTypes.string.isRequired,
  micCheckClicked: PropTypes.func.isRequired,
};

export default Header;
