import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Link } from 'react-router';
import styled from 'styled-components';

import {
  prevChoicePage,
  nextChoicePage,
  defaultChoicePageStates,
} from './ChoiceActions';
import CategoryControls from './ChoiceControls';
import ProgressSteps from './ChoiceProgressSteps';
import { verticalCenter } from '../shared/styles';
import navBack from './nav-back.png';

const Container = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  margin-left: 1.444rem;
  margin-right: 1.444rem;
  overflow: hidden;
`;

const Main = styled.div`
  background-color: #deeef4;
  display: flex;
  flex: 1;
  flex-direction: column;
  padding: 0.756rem;
`;

const ControlStrip = styled.div`
  ${verticalCenter()}
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  height: 3.6rem;
`;

const GoBack = styled(Link)`
  font-size: 0.722rem;
  font-weight: normal;
  margin-left: 1.75rem;
  text-decoration: underline;

  &::before {
    background-image: url(${navBack});
    background-size: 1.778rem 1.778rem;
    background-repeat: no-repeat;
    content: ' ';
    height: 1.778rem;
    margin-top: 0.75rem;
    margin-left: -1.75rem;
    position: absolute;
    width: 1.778rem;
  }

  &:hover {
    cursor: pointer;
  }
`;

const H2 = styled.h2`
  ${verticalCenter()}
  flex: 1;
  font-size: 0.889rem;
  font-weight: bold;
  text-align: center;
`;

export class ChooseChoice extends Component {
  static propTypes = {
    prevChoicePage: PropTypes.func.isRequired,
    activeList: PropTypes.shape().isRequired,
    nextChoicePage: PropTypes.func.isRequired,
    choiceProgressSteps: PropTypes.array.isRequired,
    children: PropTypes.node.isRequired,
    choicePagesCount: PropTypes.number.isRequired,
    showProgressionSteps: PropTypes.bool,
    choiceTitle: PropTypes.string,
    goBackLink: PropTypes.oneOfType([
      PropTypes.shape(),
      PropTypes.string,
    ]),
  };

  static defaultProps = {
    ...defaultChoicePageStates,
  };

  constructor(props) {
    super(props);

    this.nextPage = this.nextPage.bind(this);
    this.prevPage = this.prevPage.bind(this);
  }

  prevPage() {
    this.props.prevChoicePage(this.props.activeList.pageId);
  }

  nextPage() {
    this.props.nextChoicePage(this.props.activeList.pageId);
  }

  render() {
    const {
      choiceTitle, choiceProgressSteps, children, activeList, choicePagesCount, goBackLink, showProgressionSteps,
    } = this.props;
    // XXX this render function should be refactored in the future to be smaller and simpler.
    return (
      <Container>
        <ControlStrip>
          {goBackLink
            && <GoBack to={goBackLink} name="back" />}
          <H2>{choiceTitle}</H2>
        </ControlStrip>
        <Main>
          { showProgressionSteps && choiceProgressSteps
            && <ProgressSteps progressSteps={choiceProgressSteps} />}
          {children}
        </Main>
        <ControlStrip>
          <CategoryControls
            nextPage={this.nextPage}
            prevPage={this.prevPage}
            pageId={activeList.pageId}
            pageCount={choicePagesCount}
          />
        </ControlStrip>
      </Container>
    );
  }
}

// container part below
const mapStateToProps = ({ Choices }) => ({
  ...Choices,
  choiceProgressSteps: [...Choices.choiceProgressSteps],
  activeList: { ...Choices.activeList },
});

const mapDispatchToProps = {
  prevChoicePage,
  nextChoicePage,
};

export default connect(mapStateToProps, mapDispatchToProps)(ChooseChoice);
