import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';

function getBackgroundColor(props) {
  const {
    highlight,
    showFeedback,
    feedback,
    error,
    startIndicatorEnabled,
    showStartIndicator,
  } = props;

  if (feedback && !error) {
    return '#ddddd';
  }

  if ((startIndicatorEnabled && showStartIndicator) || highlight) {
    return '#f4d149';
  }

  if (showFeedback && feedback && error) {
    return '#c7e6f3';
  }

  return 'rgba(0, 0, 0, 0)';
}

function getTextUnderline(props) {
  const {
    feedback,
    error,
    underlineFeedback,
    targetWord,
    underlineTargetWord,
    pronunciationChange,
    clipping,
  } = props;
  if (underlineFeedback) {
    // If clipping occured skip all other visualizations and make it purple
    if (feedback && clipping) {
      return css`
    text-decoration-line: underline;
    text-decoration-color: #F0F;
    text-decoration-thickness: 3px;
    text-decoration-style: solid;
`;
    }

    if (feedback && error && pronunciationChange) {
      return css`
    text-decoration-line: underline;
    text-decoration-color: #ffbd52;
    text-decoration-thickness: 3px;
    text-decoration-style: solid;
`;
    }

    if (feedback && error) {
      return css`
    text-decoration-line: underline;
    text-decoration-color: #F00;
    text-decoration-thickness: 3px;
    text-decoration-style: solid;
`;
    }

    if (feedback && !error) {
      return css`
    text-decoration-line: underline;
    text-decoration-color: #0F0;
    text-decoration-thickness: 3px;
    text-decoration-style: solid;
`;
    }
  }

  if (targetWord && underlineTargetWord) {
    return css`
    text-decoration-line: underline;
    text-decoration-color: #000;
    text-decoration-thickness: 3px;
    text-decoration-style: solid;
`;
  }

  return css`
  text-decoration-line: underline;
  text-decoration-color: rgba(0, 0, 0, 0);
  text-decoration-thickness: 3px;
  text-decoration-style: dotted;
`;
}

function getOpacity(props) {
  const {
    feedback,
  } = props;

  if (feedback) {
    return 0.65;
  }

  return 1;
}

const Word = styled.span`
  background-color: ${(props) => getBackgroundColor(props)};
  opacity: ${(props) => getOpacity(props)};
  display: inline-block;
  height: 100%;
  margin: 0px;
  position: relative;
  ${(props) => getTextUnderline(props)};
  transition: all 1s ease-out;
`;

Word.propTypes = {
  textIndex: PropTypes.number.isRequired,
  sentenceIndex: PropTypes.number.isRequired,
  sentence: PropTypes.number.isRequired,
  start: PropTypes.number.isRequired,
  end: PropTypes.number.isRequired,
  startIndicatorEnabled: PropTypes.bool.isRequired,
  showStartIndicator: PropTypes.bool.isRequired,
  highlight: PropTypes.bool.isRequired,
  error: PropTypes.bool.isRequired,
  showFeedback: PropTypes.bool,
  underlineFeedback: PropTypes.bool,
  feedback: PropTypes.bool.isRequired,
  targetWord: PropTypes.bool,
  targetWordIndex: PropTypes.number,
  underlineTargetWord: PropTypes.bool,
  pronunciationChange: PropTypes.bool,
  clipping: PropTypes.bool,
};

Word.defaultProps = {
  showFeedback: false,
  underlineFeedback: false,
  underlineTargetWord: false,
};

export default Word;
