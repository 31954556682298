import React from 'react';
import { Link } from 'react-router';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import LoginHeader from './LoginHeader';
import teacherIcon from './teacher.png';
import studentIcon from './student.png';

const Section = styled.section`
  flex: 1;
`;

const Tiles = styled.div`
  align-items: center;
  display: flex;
  flex-direction: row;
  height: 481.42px;
  justify-content: center;
`;

const Tile = styled(Link)`
  align-items: center;
  background-color: #fff;
  border-radius: 3px;
  box-shadow: 0 2px 14px 0 rgba(176, 176, 176, 0.5);
  display: flex;
  flex-direction: column;
  height: 220px;
  justify-content: space-around;
  margin: 1.8rem;
  padding: 20px;
  text-align: center;
  width: 270px;

  &:hover {
    box-shadow: 0 2px 14px 0 rgba(53, 53, 53, 0.5);
    cursor: pointer;
  }
`;

const TileIcon = styled.img`
  height: 81px;
`;

/**
 * Login component for tuto.
 */
export default () => {
  const { t } = useTranslation();

  return (
    <Section>
      <LoginHeader />
      <Tiles>
        <Tile to="/login/teacher">
          <TileIcon src={teacherIcon} alt={t('login.teacher')} />
          <p>{t('login.teacher')}</p>
        </Tile>
        <Tile to="/login/student">
          <TileIcon src={studentIcon} alt={t('login.student')} />
          <p>{t('login.student')}</p>
        </Tile>
      </Tiles>
    </Section>
  );
};
