import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const ChoiceListStyled = styled.div`
  align-content: space-around;
  display: flex;
  flex: 1;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-start;
  margin-top: 1.5rem;
`;

const ChoiceList = (props) => (
  <ChoiceListStyled>
    { props.children }
  </ChoiceListStyled>
);

ChoiceList.propTypes = {
  children: PropTypes.node.isRequired,
};

export default ChoiceList;
