/*
 * Export some common styling to be used with styled-components.
 * These 'styling' components could be compared with mixins or functions
 * as in sass/less
 *
 * Usage example:
 *
 * import styled from 'styled-components';
 * import { verticalCenter } from './styles';
 *
 * const NiceContainer = styled.div`
 *   ${ verticalCenter() }
 * `;
 */

import Color from 'color';
import { createGlobalStyle } from 'styled-components';

/**
 * Use flexbox to vertical align the content of
 * a container in the center.
 *
 * @param {object} args
 * @param {string} args.flexDirection - Set the flex-direction property. Defaults to 'column'
 * @param {string} args.textDirection - Set the text-direction property. Defaults to 'left'
 */
export const verticalCenter = ({ flexDirection = 'column', textAlign = 'left' } = {}) => `
  display: flex;
  flex-direction: ${flexDirection};
  justify-content: center;
  text-align: ${textAlign};
`;

/**
 * On overflow of an element, show an ellipsis '...'.
 */
export const truncate = () => `
  display: block;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

/**
 * Easy resetting box with margin/padding to zero.
 */
export const resetBox = () => `
  margin: 0;
  padding: 0;
`;

/**
 * Lighten a color with a specified ratio.
 * The result wil be returned as rgb string.
 *
 * This function makes use of the color library.
 * https://github.com/qix-/color
 *
 * @param {string} color - A string with a valid css color definition: hex, rgb(a), hsl, etc.
 * @param {number} ratio - Ratio of the lightning you want, a number from 0 to 1.
 */
export const lighten = (color, ratio) => Color(color).lighten(ratio).rgb().toString();

export const GlobalStyle = createGlobalStyle`
  html {
    font-size: 18px;
    box-sizing: border-box;
  }

  *,
  *::before,
  *::after {
    box-sizing: inherit;
  }

  html,
  body,
  #root {
    height: 100%;
    position: relative;
  }

  body {
    background: radial-gradient(circle, #fff 0%, #7cb5cb 100%);
    color: rgb(24, 63, 116);
    font-family: 'Roboto';
    margin: 0;
    padding: 0;
  }

  a {
    color: inherit;
    text-decoration: none;
  }

  a:hover {
    text-decoration: none;
  }
`;
