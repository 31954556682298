import { Howl } from 'howler';
import outputMp3 from './dag.mp3';

/**
 * Sprite for the start beep, interrupt beep and "lees het nog eens" feedback.
 */
const sound = {
  nl: new Howl({
    src: [
      outputMp3,
    ],
    sprite: {
      dag: [0, 1051],
    },
  }),
  en: new Howl({
    src: [
      outputMp3,
    ],
    sprite: {
      dag: [0, 1051],
    },
  }),
  de: new Howl({
    src: [
      outputMp3,
    ],
    sprite: {
      again: [0, 1051],
    },
  }),
};

export default sound;
