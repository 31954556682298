import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import ChooseChoice from '../choice/ChooseChoice';
import ActionList from '../choice/ChoiceList';
import Theme from '../category/Theme';
import tekstenIcon from './Teksten.png';
import voortgangIcon from './Voortgang.png';

/**
 * Render the options for a teacher. Examine the progress or jump into the exercises.
 */
const ChooseAction = ({ params }) => {
  const { t } = useTranslation();
  const { groupId } = params;

  return (
    <ChooseChoice goBackLink="/groepen" choiceTitle={t('choose_action.title')}>
      <ActionList>
        <Theme
          to={`/groepen/${groupId}/voortgang`}
          name={t('choose_action.title_progress')}
          description={t('choose_action.description_progress')}
          color="#68738f"
          icon={voortgangIcon}
          type="theme"
        />
        <Theme
          to={{
            pathname: '/themas',
            query: {
              groupId,
            },
          }}
          name={t('choose_action.title_texts')}
          description={t('choose_action.description_texts')}
          color="#4299f7"
          icon={tekstenIcon}
          type="theme"
        />
      </ActionList>
    </ChooseChoice>
  );
};

ChooseAction.propTypes = {
  params: PropTypes.shape().isRequired,
};

export default ChooseAction;
