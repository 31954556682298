import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import debug from 'debug';
import { useTranslation } from 'react-i18next';

import {
  establishConnection, cleanup,
} from '@itslanguage/websocket';
import axios from 'axios';
import { updateMicrophoneChecked } from './actions';

import ModalMessage from '../../components/ModalMessage';
import ButtonGroup from '../../components/ButtonGroup';
import AreYouSure from '../../components/AreYouSure';
import Button from '../../reading/Buttons';
import { wait } from '../../shared/utils';

import { playAudio } from '../../shared/audio';
import feedbackModelSounds from '../../audiofiles/feedback';
import micSounds from '../../audiofiles/mic';
import { getRecorder } from '../../shared/itslanguage';
import iconContinue from '../../reading/icons/message-continue.png';
import errorMark from '../../reading/icons/message-error-mark.png';

const logger = debug('its-tuto:debug');

let recorder = null;
let socket = null;

function MicrophoneCheckDialog(props) {
  const [volume, setVolume] = React.useState(0);
  const [tryToLeave, setTryToLeave] = React.useState(false);
  const [micError, setMicError] = React.useState(null);
  const { t } = useTranslation();

  const leave = () => {
    props.updateMicrophoneChecked(true);
  };

  const leaveTest = () => {
    leave();
  };

  const notLeaveTest = () => {
    setTryToLeave(false);
  };

  const closeDialogHandler = () => {
    leave();
  };

  React.useEffect(() => {
    const recordingVolumeHandler = ({ data }) => {
      // If a microphone is recording volume should always be more than 0.
      if (data.volume === 0) {
        setMicError(t('reading.microphone.error.missing'));
      }
      // Volume level will be a value between 0.00 and 1.00
      const currentVolume = Math.min(
        Math.max(parseInt(data.volume * 30 * 64, 10), 4),
        24,
      );
      setVolume(currentVolume);
    };

    async function initRecorder() {
      if (recorder) {
        cleanup();
      }
      logger('initialise recorder and set some listeners');
      try {
        recorder = await getRecorder();
        recorder.addEventListener('amplitudelevels', recordingVolumeHandler);
      } catch (error) {
        let microphoneError = t('reading.microphone.error.unknown');

        if (error.name === 'NotFoundError' || error.name === 'DevicesNotFoundError') {
          microphoneError = t('reading.microphone.error.missing');
        } else if (error.name === 'NotReadableError' || error.name === 'TrackStartError') {
          microphoneError = t('reading.microphone.error.inuse');
        } else if (error.name === 'OverconstrainedError' || error.name === 'ConstraintNotSatisfiedError') {
          microphoneError = t('reading.microphone.error.requirements');
        } else if (error.name === 'NotAllowedError' || error.name === 'PermissionDeniedError') {
          microphoneError = t('reading.microphone.error.permission');
        } else if (error.name === 'TypeError' || error.name === 'TypeError') {
          microphoneError = t('reading.microphone.error.type');
        }

        setMicError(microphoneError);

        throw error;
      }

      // Take the first audio track and log the sample rate
      try {
        const maxSampleRate = recorder.stream.getAudioTracks()[0].getCapabilities().sampleRate.max;
        const recommendedSampleRate = 16000;
        if (maxSampleRate < recommendedSampleRate) {
          logger(`WARNING: Your max sample rate: ${maxSampleRate} is lower than the recommended: ${recommendedSampleRate}`);
        } else {
          logger(`Your max sample rate is ${maxSampleRate}`);
        }
      } catch (e) { // Catch here as it does not work on firefox (https://developer.mozilla.org/en-US/docs/Web/API/MediaStreamTrack/getCapabilities)
        logger(`Could not get the max samplerate: ${e}`);
      }
    }

    async function startRecorder() {
      const { REACT_APP_TUTO_API_URL, REACT_APP_TUTO_ITS_API_URL } = process.env;

      const response = await axios({
        method: 'get',
        url: `${REACT_APP_TUTO_API_URL}/wstoken`,
      });
      const wstoken = response.data;

      socket = establishConnection(
        wstoken,
        `${REACT_APP_TUTO_ITS_API_URL}/prompt`,
        recorder,
        (feedback) => {
          const fb = JSON.parse(feedback);
          fb.labels.forEach((label) => {
            if (label.type === 'CW') {
              recorder.stop();
              socket.emit('end_recording');
              props.updateMicrophoneChecked(true);
            }
          });
        },
        () => {},
      );

      await socket.emit('start_recording', {
        // NOTE: could change based on language but this could be universal?
        text: 'dag_eos',
        language: 'nld',
        age_group: 'adult',
      });
      await playAudio(feedbackModelSounds.nl, 'start');
      recorder.start(1000);
      await playAudio(micSounds.nl, 'dag');
    }

    initRecorder().then(() => {
      startRecorder();
    });

    return async () => {
      await wait(1000);
      if (recorder) {
        recorder.stop();
        cleanup();
      }
      if (socket) {
        socket.emit('end_recording');
      }
    };
  }, [props, t]);

  if (micError) {
    const lines = [
      t('reading.error.constraint_description_1'),
      t('reading.error.constraint_description_2'),
      t('reading.error.constraint_description_3'),
      t('reading.error.constraint_description_4'),
      `${t('reading.error.constraint_description_5')} ${micError}`,
    ];

    return (
      <ModalMessage
        lines={lines}
        imageSrc={errorMark}
      />
    );
  }

  return (
    <>
      {tryToLeave && (
        <AreYouSure
          title={t('microphone.areyousure')}
          onThumbUpClick={leaveTest}
          onThumbDownClick={notLeaveTest}
          yes={t('confirm.yes')}
          no={t('confirm.no')}
        />
      )}
      <ModalMessage
        lines={[
          t('microphone.title'),
          t('microphone.description1'),
          <br />,
          (
            <>
              <ButtonGroup center>
                <Button control="record" volume={volume} />
              </ButtonGroup>
            </>
          ),
        ]}
        buttonText={t('microphone.continue')}
        buttonIcon={iconContinue}
        onClose={closeDialogHandler}
      />
    </>
  );
}

MicrophoneCheckDialog.propTypes = {
  updateMicrophoneChecked: PropTypes.func.isRequired,
};

// Container stuff...

const mapStateToProps = null;

const mapDispatchToProps = {
  updateMicrophoneChecked,
};

export default connect(mapStateToProps, mapDispatchToProps)(MicrophoneCheckDialog);
