import styled from 'styled-components';

const Line = styled.span`
  display: block;
  font-size: 1rem;
  font-weight: bold;
  line-height: 1.778rem;
`;

export default Line;
