import React from 'react';
import PropTypes from 'prop-types';

import ChoiceList from '../choice/ChoiceList';
import Theme from '../category/Theme';

const ThemeList = ({
  themes, pageId, basePath, onThemeClick,
}) => (
  <ChoiceList>
    { themes
      && themes
        .slice((pageId * 8), ((pageId + 1) * 8))
        .map((item) => (
          <Theme
            to={`${basePath}/${item.id}`}
            name={item.name}
            description={item.description}
            color={item.color}
            icon={item.icon ? `${process.env.REACT_APP_TUTO_API_URL}/category/${item.id}/icon` : ''}
            type="theme"
            key={item.id}
            onClick={() => onThemeClick(item)}
          />
        ))}

    { !themes
      && <span>Oeps! We hebben geen themas voor deze groep.</span>}
  </ChoiceList>
);

ThemeList.propTypes = {
  themes: PropTypes.array.isRequired,
  pageId: PropTypes.number.isRequired,
  basePath: PropTypes.string.isRequired,
  onThemeClick: PropTypes.func.isRequired,
};

export default ThemeList;
