import { createStore, applyMiddleware, compose } from 'redux';
import thunkMiddleware from 'redux-thunk';
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import reducers from './reducers';

const persistConfig = {
  key: 'root',
  whitelist: ['authentication', 'MicrophoneCheck'],
  storage,
};

const persistedReducer = persistReducer(persistConfig, reducers);

let composeEnhancers = compose;

// If Redux Dev Tools and Saga Dev Tools Extensions are installed, enable them
/* istanbul ignore next */
if (process.env.NODE_ENV !== 'production' && typeof window === 'object') {
  /* eslint-disable no-underscore-dangle */
  if (window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__) {
    composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({});
  }
}

export const store = createStore(
  persistedReducer,
  composeEnhancers(
    applyMiddleware(thunkMiddleware),
  ),
);

export const persistor = persistStore(store);

export default () => ({ store, persistor });
