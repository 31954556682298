import { Howl } from 'howler';
import outputMp3 from './output.mp3';
import outputM4a from './output.m4a';
import outputAc3 from './output.ac3';
import outputOgg from './output.ogg';
import outputEnMp3 from './output-en.mp3';
import outputEnM4a from './output-en.m4a';
import outputEnAc3 from './output-en.ac3';
import outputEnOgg from './output-en.ogg';
import outputDeMp3 from './output-de.mp3';
import outputDeM4a from './output-de.m4a';
import outputDeAc3 from './output-de.ac3';
import outputDeOgg from './output-de.ogg';

/**
 * Sprite for being able to play 'well-done' audio fragments.
 * The audio itself was created via https://cloud.google.com/text-to-speech/.
 */
const sound = {
  nl: new Howl({
    src: [
      outputOgg,
      outputM4a,
      outputMp3,
      outputAc3,
    ],
    sprite: {
      'je-bent-er-bijna': [0, 1388.843537414966],
      'kom-op-je-kunt-het': [3000, 1656.4172335600906],
      'op-naar-de-volgende-pagina': [6000, 2402.81179138322],
      'zet-hem-op': [10000, 1051.927437641723],
    },
  }),
  en: new Howl({
    src: [
      outputEnOgg,
      outputEnM4a,
      outputEnMp3,
      outputEnAc3,
    ],
    sprite: {
      encouraging1: [0, 1368.0045351473924],
      encouraging2: [3000, 1872.018140589569],
      encouraging3: [6000, 1080],
      encouraging4: [9, 1068],
    },
  }),
  de: new Howl({
    src: [
      outputDeOgg,
      outputDeM4a,
      outputDeMp3,
      outputDeAc3,
    ],
    sprite: {
      encouraging1: [0, 1224.013],
      encouraging2: [3000, 2304.013],
      encouraging3: [7000, 1000.05],
      encouraging4: [10000, 1100.7],
    },
  }),
};

export default sound;
