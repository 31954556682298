import { Link } from 'react-router';
import styled from 'styled-components';
import playerStart from './icons/player-start.png';
import playerStop from './icons/player-stop.png';
import playerRecord from './icons/player-record.png';
import playerRecordProgress from './icons/record-progress.svg';
import playerProgressBlue from './icons/record-progress-blue.svg';
import playerRecordDisabled from './icons/player-record-disabled.png';
import playerPlayback from './icons/player-playback.png';
import playerPause from './icons/player-pause.png';
import playerSpeed1 from './icons/player-speed-1.png';
import playerSpeed2 from './icons/player-speed-2.png';
import playerSpeed3 from './icons/player-speed-3.png';
import playerMute from './icons/player-mute.png';
import playerUnMute from './icons/player-unmute.png';
import recordingPlay from './icons/recording-play.png';
import recordingStop from './icons/recording-stop.png';
import pageNext from './icons/page-next.png';
import pagePrev from './icons/page-prev.png';
import pageNextDisabled from './icons/page-next-disabled.png';
import pagePrevDisabled from './icons/page-prev-disabled.png';
import tabNext from './icons/tab-next.png';
import tabPrev from './icons/tab-prev.png';
import tabNextDisabled from './icons/tab-next-disabled.png';
import tabPrevDisabled from './icons/tab-prev-disabled.png';
import exerciseEnd from './icons/exercise-end.png';
import exerciseEndDisabled from './icons/exercise-end-disabled.png';

const controlIcons = {
  play: playerStart,
  stop: playerStop,
  record: playerRecord,
  'record-progress': playerRecordProgress,
  'record-disabled': playerRecordDisabled,
  'load-progress': playerProgressBlue,
  playback: playerPlayback,
  pause: playerPause,
  'speed-1': playerSpeed1,
  'speed-2': playerSpeed2,
  'speed-3': playerSpeed3,
  mute: playerMute,
  unmute: playerUnMute,
  'recording-play': recordingPlay,
  'recording-stop': recordingStop,
  next: pageNext,
  prev: pagePrev,
  'next-disabled': pageNextDisabled,
  'prev-disabled': pagePrevDisabled,
  'tab-next': tabNext,
  'tab-prev': tabPrev,
  'tab-next-disabled': tabNextDisabled,
  'tab-prev-disabled': tabPrevDisabled,
  end: exerciseEnd,
  'end-disabled': exerciseEndDisabled,
};

export default styled(Link).attrs((props) => ({
  style: {
    boxShadow: `0 0 0 ${props.volume * 0.01}rem rgb(178, 99, 69)`,
    transition: 'box-shadow 0.2s',
  },
}))`
  ${(props) => props.hidden && 'display: none;'}

  background-image: url(${(props) => controlIcons[props.control]});
  background-size: 2.2rem;
  background-position: center;
  background-repeat: no-repeat;
  ${(props) => !props.control.includes('-disabled') && 'cursor: pointer;'}
  height: 2rem;
  margin-right: 0.516rem;
  position: relative;
  width: 2rem;
  border-radius: 50%;
`;
