import styled from 'styled-components';

const Body = styled.div`
  display: flex;
  flex: 1;
  flex-direction: row;
  font-size: .75rem;
  justify-content: center;
  margin: 0 auto;
  max-width: 1400px;
  position: relative;
  width: 100%;
  ${({ fillHeight }) => fillHeight && 'height: 100%;'}
`;

export default Body;
