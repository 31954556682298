// Need to import the pre-compiled version because of older webpack/babel deps in this project;
import {
  createMediaStream,
  createRecorder,
  createAmplitudePlugin,
} from '@itslanguage/recorder';
import debug from 'debug';
import axios from 'axios';
import { browserHistory } from 'react-router';

const logger = debug('its-tuto:logs');

const itslApi = {};

export function setupUserAccessToken() {
  axios.defaults.headers.common.Authorization = `Bearer ${localStorage.getItem('token')}`;
}

export function getTenantId() {
  const { hostname } = document.location;
  const { REACT_APP_TUTO_DEFAULT_TENANT } = process.env;
  const hostnameParts = hostname.split('.');
  const [firstPart] = hostnameParts;
  let tenantId = REACT_APP_TUTO_DEFAULT_TENANT;

  // If we are approaching from a subdomain, use the subdomain as tenantId but ignore
  // if it is www or starts with `review`. Otherwise we use the default set tenantId.
  // If there is a subdomain, it will always use the first portion of a subdomain:
  // i.e.: many.subdomains.here.tuto.extension will use `many` as tenantId.
  if (hostnameParts.length > 2
    && firstPart !== 'www'
    && !firstPart.startsWith('review')
    && !firstPart.startsWith('staging')) {
    tenantId = firstPart;
  }

  return tenantId;
}

export async function doAuthentication({
  schoolcode, username, emailaddress, password,
}) {
  const { REACT_APP_TUTO_API_URL } = process.env;
  const response = await axios({
    method: 'post',
    url: `${REACT_APP_TUTO_API_URL}/login`,
    data: {
      email: emailaddress,
      username,
      password,
      schoolcode,
    },
  });
  localStorage.setItem('token', response.data.access_token);
  axios.defaults.headers.common.Authorization = `Bearer ${localStorage.getItem('token')}`;
  axios.interceptors.response.use(
    (request) => request,
    (error) => {
      if (error.response.status === 401) {
        browserHistory.push('/login');
      }
      return Promise.reject(error);
    },
  );
  return response;
}

// Easy way to use secureLink; it checks if `access_token` is already in the url;
export function secureLink(urlToLoad = '') {
  logger(urlToLoad);
  return urlToLoad;
}

// Easy way to load our player;
export const audioPlayer = new Audio();

// Easy way to load our recorder;
let recorder;
const DEFAULT_MEDIA_TYPE = 'audio/wav';

/**
 * Get a recorder to record audio with.
 * Uses localstorage to get mimeType.
 *
 * @returns {Promise} - When successful it returns a recorder.
 */
export async function getRecorder() {
  if (typeof recorder === 'undefined') {
    try {
      if (recorder) {
        // It can be set at this point when reloading for some reason
        return recorder;
      }
      const stream = await createMediaStream();
      logger('Micropohone access permitted');

      const localStorageSettings = JSON.parse(
        localStorage.getItem('recorderSettings'),
      );

      const recorderSettings = {
        mimeType: DEFAULT_MEDIA_TYPE,
        ...localStorageSettings,
      };

      recorder = createRecorder(
        stream,
        [createAmplitudePlugin()],
        recorderSettings.mimeType,
      );
    } catch (error) {
      if (error.name === 'NotFoundError' || error.name === 'DevicesNotFoundError') {
        // required track is missing
        logger('NotFoundError: No microphone to use found');
      } else if (error.name === 'NotReadableError' || error.name === 'TrackStartError') {
        // webcam or mic are already in use
        logger('NotReadableError: microphone already in use');
      } else if (error.name === 'OverconstrainedError' || error.name === 'ConstraintNotSatisfiedError') {
        // constraints can not be satisfied by avb. devices
        logger('OverconstrainedError: constraints can not be satisfied by the microphone');
      } else if (error.name === 'NotAllowedError' || error.name === 'PermissionDeniedError') {
        // permission denied in browser
        logger('NotAllowedError: permission denied in browser');
      } else if (error.name === 'TypeError' || error.name === 'TypeError') {
        // empty constraints object
        logger('TypeError: empty constraints object');
      } else {
        // other errors
        logger('Some undefined error when trying to get access to the microphone');
      }

      // re-trow the error to be used in the application!
      throw error;
    }
  }

  // Reuse recorder instance;
  return recorder;
}

/**
 * Given an array with words. Words are objects like
 * [{ textIndex: 1, sentence: 1, displayText: 'Word' }]. Return an array with sequences of
 * consecutive words. Simple example with just numbers:
 * [3,5,6,8,9,1] => [[1],[3],[5,6],[8,9]]
 *
 * @param {Array} words - Array with words.
 * @param {Array} [alwaysGroup = []] - Optional array with textIndex values to always group.
 * @returns {Array} - Array with sequences of words.
 */
export function groupSequenceWords(words = [], alwaysGroup = []) {
  return words
    // Start by sorting the array;
    .sort((wordA, wordB) => wordA.textIndex - wordB.textIndex)
    .reduce((result, word, index, collection) => {
      // Start to check if the current or previous item is in the alwaysGroup array OR
      // if it is the first item;
      if (index === 0
        || alwaysGroup.includes(word.textIndex)
        || alwaysGroup.includes(collection[index - 1].textIndex)) {
        // Add a new group;
        result.push([word]);
      } else if (index >= 1 && word.textIndex - collection[index - 1].textIndex === 1) {
      // Check if the current word is consecutive to the last item in the result array;
      // Skip the first one;
        result[result.length - 1].push(word);
      } else {
        // Fallback is to add as new group anyway;
        result.push([word]);
      }

      return result;
    }, []);
}

/**
 * Given an array with words. Words are objects like
 * [{ textIndex: 1, sentence: 1, displayText: 'Word' }]. Return an array with sequences of
 * words, grouped by textIndex. Simple example with just numbers:
 * [1,2,3,3,4] => [[1],[2],[3,3],[4]]
 *
 * @param {Array} words - Array with words.
 * @returns {Array} - Array with array of words, grouped by textIndex.
 */
export function groupWordsByTextIndex(words = []) {
  return words
    // Start by sorting the array;
    .sort((wordA, wordB) => wordA.textIndex - wordB.textIndex)
    .reduce((result, word, index, collection) => {
      // Start to check if it is the first item;
      if (index === 0) {
        // Add a new group;
        result.push([word]);
      } else if (index >= 1 && word.textIndex === collection[index - 1].textIndex) {
        // Check if the previous item has the same textIndex, add it to that array;
        result[result.length - 1].push(word);
      } else {
        // Fallback is to add as new group anyway;
        result.push([word]);
      }

      return result;
    }, []);
}

/**
 * Get an array of textIndex values that correspond to a targetword. Note that textIndex values
 * correspond to the index of a specific word in a specific text. It relates to the SRT of a
 * SpeechChallenge.
 *
 * @param {Array} words - Array with words to filter.
 * @returns {Array} - Array with textIndex values.
 */
export function filterTargetWordIndexes(words = []) {
  return words
    .filter((word) => word.targetWord)
    .map((word) => word.textIndex);
}

/**
 * Based on a word (from the feedback) map it to a result that the annotate tool is able to display
 * correctly.
 *
 * For more information about the mapping see the wiki
 * https://gitlab.com/dcentralized/itslanguage/its-tuto/-/wikis/Mapping-feedback-to-reference-annotation
 *
 * @param {object} word - A word as returned in the feedback.
 * @returns {array} - An array with one or more "labels" that can be understood by the annotate
 *                    tool.
 */
export function mapToFragmentLabels(word) {
  // eslint-disable-next-line default-case
  switch (word.label) {
    case 'OW':
      return [
        {
          comment: '',
          layer: 'Words',
          label: word.expected,
        },
        {
          comment: '',
          layer: 'Errors',
          label: 'OmissionWord',
        },
      ];
    case 'FS':
      if (word.recognized !== '') {
        return [
          {
            comment: `CW^${word.textIndex}^${word.expected}`,
            layer: 'PromptChanges',
            label: word.recognized,
          },
          {
            comment: '',
            layer: 'Errors',
            label: 'InsertionWord,FalseStart',
          },
        ];
      }
      return [
        {
          comment: '',
          layer: 'Errors',
          label: 'InsertionSound,FalseStart',
        },
      ];

    case 'RW':
      return [
        {
          comment: `CW^${word.textIndex}^${word.expected}`,
          layer: 'PromptChanges',
          label: word.recognized,
        },
        {
          comment: '',
          layer: 'Errors',
          label: 'Repetition,InsertionWord',
        },
      ];
    case 'PC':
      return [
        {
          comment: `CW^${word.textIndex}^${word.expected}`,
          layer: 'PromptChanges',
          label: word.recognized,
        },
        {
          comment: '',
          layer: 'Errors',
          label: 'Substitution,InsertionWord,PhoneticChange',
        },
      ];
  }
  return 'error';
}

/**
 * Take feedback and create a reference annotation for it so it can be annotated in the annotator
 * tool.
 *
 * @param {Array} sentences - An array with the final feedback for the sentences.
 * @param {string} challengeId - The Id of the challenge to create the reference annotation for.
 * @param {string} organisationId - The Id of the organisation to create the reference annotation
 * for.
 * @param {string} recordingId - The Id of the recording to create the reference annotation for.
 * @returns {Promise} - A promise that will tell if creating either succeeded of failed.
 */
export async function createAnnotation(sentences, challengeId, organisationId, recordingId) {
  const url = `/organisations/${organisationId}/challenges/speech/${challengeId}/recordings/${recordingId}/reference`;
  const fragments = sentences.reduce((acc, sentence) => {
    let fragment = [];

    fragment = sentence.words.map((word, index, total) => {
      if (word.label === 'CW') {
        return {
          start: (word.startTiming / 1000),
          end: (word.endTiming / 1000),
          labels: [
            {
              // Also add an EOS if we now this word is the word with the EOS;
              comment: index === total.length - 1 ? '<EoS>' : '',
              layer: 'Words',
              label: word.recognized,
            },
          ],

        };
      }
      return {
        start: (word.startTiming / 1000),
        end: (word.endTiming / 1000),
        labels: mapToFragmentLabels(word),
      };
    });

    return [
      ...acc,
      ...fragment,
    ];
  }, []);

  return itslApi.communication.authorisedRequest('PUT', url, {
    annotations: fragments,
  });
}
