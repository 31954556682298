import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import styled from 'styled-components';
import { withTranslation } from 'react-i18next';

import axios from 'axios';
import {
  updateChoicePage,
  resetChoicePageNavState,
  resetChoicePageState,
  defaultChoicePageStates,
} from '../choice/ChoiceActions';
import loadingIcon from '../login/loading.svg';
import ChooseChoice from '../choice/ChooseChoice';
import ThemeList from './ThemeList';
import Overview from './Overview';
import Progress from './ProgressBody';
import Assignment from './AssignmentBody';

const Loading = styled.div`
  background-image: url('${loadingIcon}');
  background-repeat: no-repeat;
  background-position: 50% 45%;
  display: flex;
  flex: 1 1;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
`;

export class LegacyChooseProgress extends Component {
  static propTypes = {
    params: PropTypes.shape().isRequired,
    updateChoicePage: PropTypes.func.isRequired,
    location: PropTypes.shape().isRequired,
    activeList: PropTypes.shape().isRequired,
  };

  constructor(props) {
    super(props);

    this.state = {
      category: {
        speechChallenges: [],
      },
      subCategories: [],
      speechRecordings: [],
      speechChallenges: [],
      progress: [],
      isLoading: false,
    };

    this.getCategories = this.getCategories.bind(this);
    this.getProgress = this.getProgress.bind(this);
    this.getSpeechChallenges = this.getSpeechChallenges.bind(this);
    this.choicesPerPage = this.choicesPerPage.bind(this);
    this.updateChoices = this.updateChoices.bind(this);
    this.updateContent = this.updateContent.bind(this);
    this.handleCategoryClick = this.handleCategoryClick.bind(this);
  }

  UNSAFE_componentWillMount() {
    this.updateContent();
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const { params } = this.props;
    const nextParams = Object.entries(nextProps.params);
    if (!nextParams.every(([key, value]) => params[key] === value)) {
      this.updateContent(nextProps);
    }
  }

  getCategories(categoryId, groupId) {
    if (categoryId) {
      return axios({
        method: 'get',
        url: `${process.env.REACT_APP_TUTO_API_URL}/category?parent=${categoryId}`,
      });
    }
    return axios({
      method: 'get',
      url: `${process.env.REACT_APP_TUTO_API_URL}/category?group=${groupId}`,
    });
  }

  getProgress(categories) {
    return Promise.all(categories.map((category) => axios({
      method: 'get',
      url: `${process.env.REACT_APP_TUTO_API_URL}/progress?category=${category.id}`,
    }).then((response) => response.data))).then((progresses) => {
      const progress = [].concat(...progresses);

      const users = progress.map((element) => element.user);
      const userIDs = users.map((user) => user.id)
        .filter((userID, index, array) => array.indexOf(userID) === index);
      // Unite the user and their categories.
      return userIDs.map((userID) => ({
        user: users.find((user) => user.id === userID),
        /* There are three states regarding progress: `not-started`, `started`, and `done`. The
         * API only returns a progress resource for things which are either `started` or `done`.
         * Provide a mock for the missing categories.
         */
        progress: categories.map((category) => {
          const p = progress.find((pItem) => pItem.user.id === userID && pItem.category.id === category.id);
          return p || { category: { id: category.id }, challenges: [] };
        }),
      }));
    });
  }

  getSpeechChallenges(category) {
    // Get all the speech challenges.
    return Promise.all(category.prompts.map((prompt) => axios({
      method: 'get',
      url: `${process.env.REACT_APP_TUTO_API_URL}/prompt/${prompt.id}`,
    }).then((response) => response.data)));
  }

  updateContent(nextProps) {
    this.setState({
      isLoading: true,
    });
    const {
      params: {
        groupId, themeId, weekId, assignmentId,
      },
    } = nextProps || this.props;
    const categoryId = assignmentId || weekId || themeId;

    const { category: { id = null } } = this.state;
    if (categoryId !== id) {
      Promise.all([
        axios({
          method: 'get',
          url: `${process.env.REACT_APP_TUTO_API_URL}/category/${categoryId}`,
        }).then((response) => response.data).catch(() => ({ speechChallenges: [] })),
        this.getCategories(categoryId, groupId).then((response) => response.data),
      ]).then(([category, subCategories]) => {
        if (!categoryId) {
          this.updateChoices(subCategories);
        }

        let progressAndChallengesWithRecordings = [
          Promise.resolve([]),
          Promise.resolve([]),
        ];

        if (category.id) {
          const progressionCategories = assignmentId ? [category] : subCategories;
          progressAndChallengesWithRecordings = [
            this.getProgress(progressionCategories),
            this.getSpeechChallenges(category),
          ];
        }

        return Promise.all([
          Promise.resolve(category),
          Promise.resolve(subCategories),
          ...progressAndChallengesWithRecordings,
        ]);
      }).then(([category, subCategories, progress, speechChallenges]) => {
        // Progress already contains the recordings. Extract them so they'll be
        // easier to use during render.
        const speechRecordings = progress.map((prog) => {
          const current = prog.progress.find((p) => p.category.id === categoryId);
          if (current) {
            current.challenges = current.speechRecordings;
          }
          return {
            user: prog.user,
            recordings: current ? current.challenges : [],
          };
        });

        this.setState({
          category,
          subCategories,
          progress,
          speechChallenges,
          speechRecordings,
          isLoading: false,
        });
      });
    }
  }

  updateChoices(categories) {
    const { t } = this.props;
    const numberOfCategories = categories.length;

    this.props.updateChoicePage({
      choiceProgressSteps: [{ isActive: true, name: t('progress.theme') }],
      choicesCount: numberOfCategories,
      choicePagesCount: Math.ceil(numberOfCategories / this.choicesPerPage()),
      activeList: {
        choices: categories,
      },
    });
  }

  choicesPerPage() {
    return this.props.params.themeId ? 4 : 8;
  }

  handleCategoryClick(category) {
    this.setState({ category });
    this.getCategories(category.id);
  }

  render() {
    if (this.state.isLoading) {
      return (
        <Loading>
          <p>Even wachten...</p>
        </Loading>
      );
    }

    const { params: { groupId, themeId, assignmentId }, t } = this.props;
    const { location: { pathname } } = this.props;

    let basePath = pathname.split('/');
    basePath.pop();
    basePath = basePath.join('/');

    if (!themeId) {
      const { activeList: { pageId, choices } } = this.props;
      return (
        <ChooseChoice
          showProgressionSteps
          goBackLink={`/groepen/${groupId}`}
          choiceTitle={t('progress.title')}
        >
          <ThemeList
            basePath={pathname}
            pageId={pageId}
            themes={choices}
            onThemeClick={this.handleCategoryClick}
          />
        </ChooseChoice>
      );
    }

    const { category, progress, subCategories } = this.state;
    if (!assignmentId) {
      return (
        <Overview
          basePath={basePath}
          category={category}
          headers={subCategories}
          hasChildren={!assignmentId}
          onCategoryClick={this.handleCategoryClick}
        >
          <Progress progress={progress} />
        </Overview>
      );
    }

    const { speechChallenges, speechRecordings } = this.state;
    return (
      <Overview
        basePath={basePath}
        category={category}
        headers={speechChallenges}
        hasChildren={!assignmentId}
      >
        <Assignment assignments={speechRecordings} />
      </Overview>
    );
  }
}

// container part below

const mapStateToProps = ({ Choices }) => ({
  ...Choices,
  choiceProgressSteps: [...Choices.choiceProgressSteps],
  activeList: { ...Choices.activeList },
});

const mapDispatchToProps = {
  updateChoicePage,
  resetChoicePageNavState,
  resetChoicePageState,
  defaultChoicePageStates,
};

const ChooseProgress = withTranslation()(LegacyChooseProgress);

export default connect(mapStateToProps, mapDispatchToProps)(ChooseProgress);
