import styled from 'styled-components';

const Bar = styled.header`
  align-items: center;
  background-color: #153952;
  color: #fff;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  height: 41px;
  justify-content: space-between;
  padding: 11px 17px;
`;

export default Bar;
