import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import axios from 'axios';
import { withTranslation } from 'react-i18next';
import {
  updateChoicePage,
  prevChoicePage,
  nextChoicePage,
  resetChoicePageNavState,
  resetChoicePageState,
  defaultChoicePageStates,
} from '../choice/ChoiceActions';
import {
  updateBackgroundColor,
  resetBackgroundColor,
} from '../app/AppActions';
import ChooseChoice from '../choice/ChooseChoice';
import CategoryList from '../choice/ChoiceList';
import Folder from './Folder';
import Theme from './Theme';

const defaultProgressItemNames = [
  'category.theme',
  'category.week',
  'category.assignment',
];

export class LegacyChooseCategory extends Component {
  static propTypes = {
    params: PropTypes.shape().isRequired,
    resetChoicePageState: PropTypes.func.isRequired,
    resetChoicePageNavState: PropTypes.func.isRequired,
    location: PropTypes.shape().isRequired,
    updateChoicePage: PropTypes.func.isRequired,
    updateBackgroundColor: PropTypes.func.isRequired,
    resetBackgroundColor: PropTypes.func.isRequired,
    prevChoicePage: PropTypes.func.isRequired,
    nextChoicePage: PropTypes.func.isRequired,
    activeList: PropTypes.shape().isRequired,
  };

  static defaultProps = {
    ...defaultChoicePageStates,
  };

  constructor(props) {
    super(props);

    this.categoryType = null;

    this.state = {
      progress: [],
    };

    this.nextPage = this.nextPage.bind(this);
    this.prevPage = this.prevPage.bind(this);
  }

  UNSAFE_componentWillMount() {
    this.categoryType = this.getCategoryType();
    this.getCategories(this.getCategoryId(this.props));
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if ((nextProps.params.themeId !== this.props.params.themeId)
      || (nextProps.params.weekId !== this.props.params.weekId)) {
      this.categoryType = this.getCategoryType(nextProps);
      this.getCategories(this.getCategoryId(nextProps));
    }
  }

  componentWillUnmount() {
    this.props.resetChoicePageState();
    this.categoryType = null;
  }

  /**
   * Query the backend for categories. Based on the optional passed categoryId
   * all categories with the same parent (== categoryId) or all top level
   * categories will be gathered.
   *
   * @param {string} categoryId
   */
  getCategories(categoryId = null) {
    this.props.resetChoicePageNavState();

    const { REACT_APP_TUTO_API_URL } = process.env;

    if (categoryId) {
      axios({
        method: 'get',
        url: `${REACT_APP_TUTO_API_URL}/category?parent=${categoryId}`,
      }).then((response) => {
        const categories = response.data;
        this.getProgress([categories]).then(() => {
          this.updateChoicePage(categories.sort((a, b) => a.order - b.order));
        });
      });
    } else {
      axios({
        method: 'get',
        url: `${REACT_APP_TUTO_API_URL}/category?group=${this.props.location.query.groupId}`,
      }).then((response) => {
        const categories = response.data;
        this.updateChoicePage(categories.sort((a, b) => a.order - b.order));
      });
    }
  }

  /**
   * Determine the categoryId to get from the backend based on React.Component.prop.
   * react-router will add the param properties to the props object. This function
   * extracts them and then determines which category should be fetched.
   *
   * @param {object} params
   * @returns {*}
   */
  getCategoryId({ params = {} }) {
    let categoryId = null;
    const { themeId, weekId } = params;

    if (themeId && !weekId) {
      categoryId = themeId;
    }

    if (themeId && weekId) {
      categoryId = weekId;
    }

    return categoryId;
  }

  /**
   * Get the type of category. Can only be "theme" or "folder" in this project.
   *
   * @returns {string} The category type.
   */
  getCategoryType(props = this.props) {
    let categoryType = null;
    const { params: { themeId, weekId } } = props;

    if (!themeId && !weekId) {
      categoryType = 'theme';
    } else {
      categoryType = 'folder';
    }

    return categoryType;
  }

  /**
   * Get progress for the current user in the context of a Category.
   *
   * @param {Array} categories - Array with categories to get progress on.
   */
  getProgress(categories) {
    const { REACT_APP_TUTO_API_URL } = process.env;
    return Promise.all(categories[0].map((category) => axios({
      method: 'get',
      url: `${REACT_APP_TUTO_API_URL}/progress?category=${category.id}`,
    }).then((response) => {
      const progress = response.data;
      this.setState({ progress });
    })));
  }

  getLastRecordingIndex(challenges = []) {
    let pageId = 0;

    for (const challenge of challenges) {
      if (!challenge.recording) {
        break;
      }

      if (pageId + 1 < challenges.length) {
        pageId += 1;
      }
    }

    return pageId;
  }

  getBackLink() {
    const {
      params: {
        themeId,
        weekId,
      },
    } = this.props;

    let link = {
      pathname: '/',
    };

    if (themeId && !weekId) {
      link = {
        pathname: '/themas',
        query: {
          groupId: this.props.location.query.groupId,
        },
      };
    }

    if (themeId && weekId) {
      link = {
        pathname: `/themas/${themeId}/weken`,
        query: {
          groupId: this.props.location.query.groupId,
        },
      };
    }

    return link;
  }

  getBasePath(themeId, weekId) {
    let basePath = '/';

    if (!themeId && !weekId) {
      basePath += 'themas/';
    }

    if (themeId && !weekId) {
      basePath += `themas/${themeId}/weken/`;
    }

    if (themeId && weekId) {
      basePath += `themas/${themeId}/weken/${weekId}/leesopdrachten/`;
    }

    return basePath;
  }

  /**
   * Based on the fetched categories, update the page where the choices are made.
   *
   * @param {Array.<Category>} categories - An array with Category objects.
   */
  updateChoicePage(categories) {
    const numberOfCategories = categories.length;
    this.updateProgressSteps().then((progressSteps) => {
      this.props.updateChoicePage({
        choiceProgressSteps: progressSteps,
        choicesCount: numberOfCategories,
        choicePagesCount: Math.ceil(numberOfCategories / 8),
        activeList: {
          choices: categories,
        },
      });

      if (categories[0] && this.getCategoryType() === 'folder') {
        this.props.updateBackgroundColor(categories[0].color);
      } else {
        this.props.resetBackgroundColor();
      }
    });
  }

  /**
   * Update the progress steps based on params in the url
   *
   * @returns {Promise.<[]>} A promise with an array for the progress stepper.
   */
  updateProgressSteps() { // eslint-disable-line consistent-return
    const {
      params: {
        themeId,
        weekId,
      },
      t,
    } = this.props;

    // First level. Choose the theme..
    if (!themeId && !weekId) {
      return Promise.resolve([{ isActive: true, name: t(defaultProgressItemNames[0]) }]);
    }

    // Theme is now know, choose the week
    if (themeId && !weekId) {
      return axios({
        method: 'get',
        url: `${process.env.REACT_APP_TUTO_API_URL}/category/${themeId}`,
      }).then((result) => [
        { isActive: false, name: result.name || t(defaultProgressItemNames[0]) },
        { isActive: true, name: t(defaultProgressItemNames[1]) },
      ]);
    }

    // All set? Week known. Now choose the assignment!
    if (themeId && weekId) {
      return Promise.all([
        axios({
          method: 'get',
          url: `${process.env.REACT_APP_TUTO_API_URL}/category/${themeId}`,
        }),
        axios({
          method: 'get',
          url: `${process.env.REACT_APP_TUTO_API_URL}/category/${weekId}`,
        }),
      ])
        .then(([theme, week]) => [
          { isActive: false, name: theme.name || t(defaultProgressItemNames[0]) },
          { isActive: false, name: week.name || t(defaultProgressItemNames[1]) },
          { isActive: true, name: t(defaultProgressItemNames[2]) },
        ]);
    }
  }

  prevPage() {
    this.props.prevChoicePage(this.props.activeList.pageId);
  }

  nextPage() {
    this.props.nextChoicePage(this.props.activeList.pageId);
  }

  render() {
    const { activeList: { choices, pageId }, t } = this.props;
    const { params: { themeId, weekId } } = this.props;
    const basePath = this.getBasePath(themeId, weekId);

    // XXX this render function should be refactored in the future to be smaller and simpler.
    return (
      <ChooseChoice showProgressionSteps goBackLink={this.getBackLink()} title={t('category.title')}>
        <CategoryList>
          { choices
            && choices
              .slice((pageId * 8), ((pageId + 1) * 8))
              .map((item) => {
                const { groupId } = this.props.location.query;

                if (this.categoryType === 'theme') {
                  return (
                    <Theme
                      to={{
                        pathname: basePath + item.id,
                        query: {
                          groupId: this.props.location.query.groupId,
                        },
                      }}
                      name={item.name}
                      description={item.description}
                      color={item.color}
                      icon={item.icon ? `${process.env.REACT_APP_TUTO_API_URL}/category/${item.id}/icon` : ''}
                      type={this.categoryType}
                      key={item.id}
                    />
                  );
                }

                let query = {};

                const [progress = {}] = this.state.progress.filter((progressItem) => progressItem.category === item.id);

                const { percentage = 0 } = progress;

                if (progress.prompts && progress.prompts.length > 0) {
                  query = {
                    pageId: this.getLastRecordingIndex(progress.prompts) + 1,
                  };
                } else {
                  query = {
                    groupId,
                  };
                }

                return (
                  <Folder
                    to={{
                      pathname: basePath + item.id,
                      query,
                    }}
                    name={item.name}
                    description={item.description}
                    color={item.color}
                    icon={item.icon ? `${process.env.REACT_APP_TUTO_API_URL}/category/${item.id}/icon` : ''}
                    type={this.categoryType}
                    percentage={Number(percentage)}
                    key={item.id}
                  />
                );
              })}

          { !choices
            && <span>{t('category.missing')}</span>}
        </CategoryList>
      </ChooseChoice>
    );
  }
}
// container part below
const mapStateToProps = ({ Choices }) => ({
  ...Choices,
  choiceProgressSteps: [...Choices.choiceProgressSteps],
  activeList: { ...Choices.activeList },
});

const mapDispatchToProps = {
  updateChoicePage,
  prevChoicePage,
  nextChoicePage,
  resetChoicePageNavState,
  resetChoicePageState,
  defaultChoicePageStates,

  updateBackgroundColor,
  resetBackgroundColor,
};

const ChooseCategory = withTranslation()(LegacyChooseCategory);

export default connect(mapStateToProps, mapDispatchToProps)(ChooseCategory);
