import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import debug from 'debug';
import { createMediaStream } from '@itslanguage/recorder';
import { getRecorder, secureLink } from '../../shared/itslanguage';
import { enableFeedbackModel, disableFeedbackModel } from '../../reading/ReadingAssignmentActions';

import {
  bytesToSize,
  base64ArrayBuffer,
} from './utils';

import Window from './Window';
import Title from './Title';
import H2 from './H2';
import Body from './Body';
import Dropzone from './Dropzone';
import Info from './Info';
import Input from './Input';
import AudioElement from './Audio';
import Table from './Table';
import DownloadLink from './DownloadLink';
import DisableButton from './DisableButton';

const logger = debug('its-tuto:debug');

let player = null;
let recorder = null;

function startAttachedAudioPlayback() {
  if (player) {
    player.play();
    logger('playing custom audio');
  }
}

function pauseAttachedAudioPlayback() {
  if (player) {
    player.pause();
    logger('custom audio paused');
  }
}

function stopAttachedAudioPlayback() {
  if (player && (!player.ended || !player.paused)) {
    player.pause();
    logger('custom audio stopped');
  }

  if (player && player.ended) {
    logger('custom audio ended');
  }
}

function stopRecorder() {
  if (recorder && (
    recorder.state === 'recording' || recorder.state === 'paused')
  ) {
    recorder.stop();
    logger('recorder stopped');
  }

  if (player && (!player.ended || !player.paused)) {
    stopAttachedAudioPlayback();
  }
}

function mutePlayer(yesOrNo) {
  if (player) {
    player.muted = yesOrNo;
    logger(`audio from player ${yesOrNo ? 'muted' : 'unmuted'}`);
  }
}

function removeAllTracksFromRecorder() {
  if (recorder) {
    // Remove all tracks;
    recorder.stream.getTracks().forEach((track) => {
      logger(`removing track from recorder with id ${track.id}`);
      recorder.stream.removeTrack(track);
    });
  }
}

function addTracksFromStreamToRecorder(stream) {
  if (recorder && typeof stream !== 'undefined') {
    stream.getTracks().forEach((track) => {
      logger(`add track to recorder with id ${track.id}`);
      recorder.stream.addTrack(track);
    });
  }
}

function connectPlayerToRecorder() {
  let playerStream = null;

  if (player.captureStream) {
    playerStream = player.captureStream();
  } else if (player.mozCaptureStream) {
    playerStream = player.mozCaptureStream();
    logger('using mozCaptureStream for audio capture. This does not work in al versions of firefox');
  } else {
    // Note that we cannot use mozCaptureStream even if it is available in
    // firefox. It just does not work (yet) for our use case.
    logger('your browser is not capable to get stream from an audio file');
  }

  if (recorder && playerStream) {
    // Remove all tracks;
    removeAllTracksFromRecorder();

    // Add player tracks;
    addTracksFromStreamToRecorder(playerStream);

    logger('custom audio stream connected to the recorder');
  } else {
    logger('connecting player to recorder went wrong');
  }
}

async function disconnectPlayerFromRecorder() {
  if (recorder) {
    removeAllTracksFromRecorder();
    const stream = await createMediaStream();
    addTracksFromStreamToRecorder(stream);

    logger('connected custom audio stream found and removed from the recorder');
  }
}

async function initRecorder() {
  logger('initialise recorder and set some listeners');
  recorder = await getRecorder();
  recorder.addEventListener('start', startAttachedAudioPlayback);
  recorder.addEventListener('pause', pauseAttachedAudioPlayback);
  recorder.addEventListener('resume', startAttachedAudioPlayback);
  recorder.addEventListener('stop', stopAttachedAudioPlayback);
}

function initPlayer() {
  logger('initialise player and set some listeners');
  player = new Audio();
  player.addEventListener('canplaythrough', connectPlayerToRecorder);
  player.addEventListener('ended', stopRecorder);
}

function cleanup() {
  disconnectPlayerFromRecorder().then(() => {
    // Cleanup recorder events;
    if (recorder) {
      logger('executing recorder cleanup!');
      recorder.removeEventListener('start', startAttachedAudioPlayback);
      recorder.removeEventListener('pause', pauseAttachedAudioPlayback);
      recorder.removeEventListener('resume', startAttachedAudioPlayback);
      recorder.removeEventListener('stop', stopAttachedAudioPlayback);
      recorder = null; // reset the recorder;
    }

    // Cleanup player events;
    if (player) {
      logger('executing player cleanup!');
      player.removeEventListener('canplaythrough', connectPlayerToRecorder);
      player.removeEventListener('ended', stopRecorder);
      player = null; // reset the player;
    }
  });
}

export const DebugContext = React.createContext({
  feedbackUnderlinesEnabled: logger.enabled,
});

const initialState = {
  customAudio: false,
  playAttachedAudio: false,
  feedbackModelEnabled: false,
  playerReady: false,
  recorderReady: false,
  underlinesEnabled: logger.enabled,
};

const initialAudioState = {
  name: '',
  size: 0,
  type: '',
  dataUrl: '',
};

function DebugDialog(props) {
  const {
    feedbackModelEnabled,
    enableFeedbackModel: enableFeedbackModelProp,
    disableFeedbackModel: disableFeedbackModelProp,
    activePage: {
      speechChallenge,
      speechChallengeRecording,
      feedbackOnWords,
    },
  } = props;

  const debugContext = React.useContext(DebugContext);

  // State stuff
  const [state, setState] = React.useState(initialState);
  const [audio, setAudio] = React.useState(initialAudioState);

  // Change handlers
  const handleFileInputChange = (event) => {
    const [audioFile] = event.target.files;
    const { name, size, type } = audioFile;
    const reader = new FileReader();

    reader.onload = (eventData) => {
      const { result: dataBuffer } = eventData.target;
      const dataUrl = `data:${type};base64,${base64ArrayBuffer(dataBuffer)}`;
      logger('loading custom audio file done');

      // Load the player and audio stream with the audio file
      player.src = dataUrl;
      setAudio({
        name,
        size,
        type,
        dataUrl,
      });
      logger('loading custom audio file file to player done');
    };

    // Read the selected audiofiles file into the buffer!
    reader.readAsArrayBuffer(audioFile);
  };

  const handleChange = (event) => {
    event.persist();
    const { target } = event;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const { name } = target;

    logger(`Changing ${name} to ${value}`);

    setState((oldState) => ({
      ...oldState,
      [name]: value,
    }));
  };

  const disableDebugMode = () => {
    if (window) {
      // Clear the debug setting;
      localStorage.removeItem('debug');

      // Reload the page;
      window.location.reload();
    }
  };

  React.useEffect(() => {
    // Reset / re-initialize the debug window state; to be done after
    // a user has done a recording.
    const resetState = () => {
      setState(initialState);
      setAudio(initialAudioState);
    };

    if (feedbackOnWords.length > 0 && speechChallengeRecording && (recorder && recorder.state === 'inactive')) {
      // Assume recording is done!
      logger('reset debug window states!');
      resetState();
    }
  }, [feedbackOnWords, speechChallengeRecording]);

  React.useEffect(() => {
    mutePlayer(!state.playAttachedAudio);
  }, [state.playAttachedAudio]);

  React.useEffect(() => {
    if (state.feedbackModelEnabled && !feedbackModelEnabled) {
      enableFeedbackModelProp();
    }

    if (state.customAudio || (!state.feedbackModelEnabled && feedbackModelEnabled)) {
      disableFeedbackModelProp();
    }

    if (state.customAudio && state.feedbackModelEnabled) {
      disableFeedbackModelProp();
      setState((oldState) => ({
        ...oldState,
        feedbackModelEnabled: false,
      }));
      logger('Changing feedbackModelEnabled to false');
    }
  }, [state.feedbackModelEnabled, feedbackModelEnabled, enableFeedbackModelProp, disableFeedbackModelProp, state.customAudio]);

  React.useEffect(() => {
    if (state.customAudio) {
      if (!player) {
        initPlayer();
        mutePlayer(!state.playAttachedAudio);
      }

      if (!recorder) {
        initRecorder();
      }
    } else {
      cleanup();
    }
  }, [state.customAudio, state.playAttachedAudio]);

  React.useEffect(() => {
    if (state.underlinesEnabled) {
      debugContext.feedbackUnderlinesEnabled = true;
    } else {
      debugContext.feedbackUnderlinesEnabled = false;
    }
  }, [state.underlinesEnabled, debugContext.feedbackUnderlinesEnabled]);

  return (
    <Window>
      <Title>
        <Body fillHeight>
          <H2>Debugging window</H2>
          <DisableButton onClick={disableDebugMode}>Disable debug mode</DisableButton>
        </Body>
      </Title>
      <Body>
        <Dropzone>
          <h3>Underlined feedback colors</h3>
          <p>
            Green: Correct Word (CW)
            <br />
            Red: Omission Word (OW)
            <br />
            Orange: Phonetic Change (PC)
            <br />
            Pink: Clipping (Overrides other colors)
          </p>
        </Dropzone>
      </Body>
      <Body>
        <Dropzone>
          <h3>Debug options</h3>
          <input
            type="checkbox"
            disabled={state.customAudio}
            checked={state.feedbackModelEnabled}
            name="feedbackModelEnabled"
            onChange={handleChange}
          />
          {' '}
          <label htmlFor="feedbackModelDisabled">Select to enable Feedback model</label>
          <br />

          <input
            type="checkbox"
            checked={state.underlinesEnabled}
            name="underlinesEnabled"
            onChange={handleChange}
          />
          {' '}
          <label htmlFor="underlinesEnabled">Select to enable underlines during feedback</label>
          <br />
          <br />

          {
            state.customAudio && (
              <div>
                <Info>
                  Click below to select an audio file.
                </Info>
                <Input
                  type="file"
                  onChange={handleFileInputChange}
                  accept="audio/x-wav, audio/wav, audio/mp3"
                />
              </div>
            )
          }
        </Dropzone>
        <Dropzone>
          {
            state.customAudio && (
              <div>
                Filename:
                {' '}
                {audio.name}
                <br />
                Size:
                {' '}
                {bytesToSize(audio.size)}
                <br />
                Type:
                {' '}
                {audio.type}
                <br />
                Preview audio:
                <br />
                <AudioElement controls src={audio.dataUrl} />
              </div>
            )
          }
        </Dropzone>
      </Body>
      <Body>
        <Dropzone>
          <h3>General information / Results</h3>
          {
            speechChallenge && (
              <Table>
                <tbody>
                  <tr>
                    <td>Speech Challenge ID</td>
                    <td>{speechChallenge.id}</td>
                  </tr>
                  <tr>
                    <td>Speech Challenge Topic</td>
                    <td>{speechChallenge.topic}</td>
                  </tr>
                  <tr>
                    <td>Reference audio Url</td>
                    <td>
                      <DownloadLink href={secureLink(speechChallenge.referenceAudioUrl)} download>
                        {speechChallenge.referenceAudioUrl}
                      </DownloadLink>
                    </td>
                  </tr>
                  <tr>
                    <td>SRT Url</td>
                    <td>
                      <DownloadLink href={secureLink(speechChallenge.srtUrl)} download>
                        {speechChallenge.srtUrl}
                      </DownloadLink>
                    </td>
                  </tr>
                  <tr>
                    <td>imageUrl</td>
                    <td>{speechChallenge.imageUrl}</td>
                  </tr>
                  <tr>
                    <td>metadata</td>
                    <td>
                      {
                      speechChallenge.metadata && (
                        <pre>
                          {JSON.stringify(JSON.parse(speechChallenge.metadata), null, 4)}
                        </pre>
                      )
                    }
                    </td>
                  </tr>
                  {
                  speechChallengeRecording && (
                    <tr>
                      <td>
                        Latest known recording
                      </td>
                      <td>
                        <DownloadLink href={secureLink(speechChallengeRecording.combinedAudioUrl || speechChallengeRecording.audioUrl)} download>
                          {speechChallengeRecording.combinedAudioUrl || speechChallengeRecording.audioUrll}
                        </DownloadLink>
                      </td>
                    </tr>
                  )
                }
                  {
                  speechChallengeRecording && (
                    <tr>
                      <td>
                        Preview latest known recording
                      </td>
                      <td>
                        <AudioElement controls src={secureLink(speechChallengeRecording.combinedAudioUrl || speechChallengeRecording.audioUrl)} />
                      </td>
                    </tr>
                  )
                }
                </tbody>
              </Table>
            )
          }
        </Dropzone>
      </Body>
      {
        feedbackOnWords.length > 0 && (
          <Body>
            <Dropzone>
              <h3>Feedback per sentence</h3>
              <p>Received during session</p>
            </Dropzone>
          </Body>
        )
      }
    </Window>
  );
}

DebugDialog.propTypes = {
  activePage: PropTypes.shape({
    speechChallenge: PropTypes.shape(),
    speechChallengeRecording: PropTypes.shape(),
    feedbackOnWords: PropTypes.array,
  }).isRequired,
  disableFeedbackModel: PropTypes.func.isRequired,
  enableFeedbackModel: PropTypes.func.isRequired,
};

// container part below
const mapStateToProps = (state) => ({ ...state.ReadingAssignment });

const mapDispatchToProps = {
  enableFeedbackModel,
  disableFeedbackModel,
};

export default connect(mapStateToProps, mapDispatchToProps)(DebugDialog);
