import './shared/sentry';
import React, { Suspense } from 'react';
import ReactDOM from 'react-dom';
import { Router, browserHistory } from 'react-router';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import routes from './app/routes';
import configureStore from './shared/store';
import { setupUserAccessToken } from './shared/itslanguage';
import SentryErrorBoundary from './components/SentryErrorBoundary';
import LoadingIndicator from './components/LoadingIndicator';

import './shared/i18n';

const { persistor, store } = configureStore();

const onBeforeLift = () => {
  const { authentication: { user } } = store.getState();
  setupUserAccessToken(user);
};

const Loader = () => (
  <LoadingIndicator />
);

ReactDOM.render(
  <SentryErrorBoundary>
    <Suspense fallback={<Loader />}>
      <Provider store={store}>
        <PersistGate loading={null} persistor={persistor} onBeforeLift={onBeforeLift}>
          <Router history={browserHistory} routes={routes} />
        </PersistGate>
      </Provider>
    </Suspense>
  </SentryErrorBoundary>,
  document.getElementById('root'),
);
