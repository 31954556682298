import React, { Component } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Link } from 'react-router';
import progressIcon from './progress.png';
import progressIconDone from './progress-done.png';
import { verticalCenter, truncate } from '../shared/styles';

const defaultBgColor = '#d3d3d3';

const FolderItem = styled(Link)`
  ${verticalCenter({ textAlign: 'center' })}
  align-items: center;
  background-color: ${(props) => (props.color ? props.color : defaultBgColor)};
  border-radius: 4px;
  flex: 0 0 auto;
  height: 160px;
  margin: 5px;
  position: relative;
  width: 220px;

  &::before {
    background-color: ${(props) => (props.color ? props.color : defaultBgColor)};
    border-radius: 2px 2px 0 0;
    content: '';
    height: 20px;
    left: 18px;
    position: absolute;
    top: -20px;
    width: 50px;
    transform: perspective(30) rotateX(30deg);
    transform-origin: 50% 100%;
  }

  &:hover {
    transform: scale(1.08);
    z-index: 99;
  }
`;

const Name = styled.span`
  ${truncate()}
`;

const Img = styled.img`
  position: absolute;
  left: 10px;
  bottom: 10px;
  width: 22px;
  height: 22px;
`;

const FoldedPage = styled.span`
  ${verticalCenter({ textAlign: 'center' })}
  background-color: #ebead6;
  box-shadow:
    1px 1px 0 rgba(0, 0, 0, 0.1),
    3px 3px 0 rgba(235, 234, 214, 1),
    4px 4px 0 rgba(0, 0, 0, 0.125),
    6px 6px 0 rgba(235, 234, 214, 1),
    7px 7px 0 rgba(0, 0, 0, 0.15);
  margin-left: -7px;
  padding: 10px;
  position: relative;
  width: 200px;
  height: 135px;
  z-index: 1;

  &::before {
    border-bottom: 25px solid #ebead6;
    border-left: 25px solid ${(props) => (props.color ? props.color : defaultBgColor)};
    box-shadow: 2px 2px 2px rgba(0, 0, 0, 0.3);
    content: '';
    height: 0;
    left: 0%;
    position: absolute;
    top: 0%;
    width: 0;
  }
`;

const Postit = styled.span`
  ${verticalCenter({ textAlign: 'center' })}
  background-color: #fff;
  bottom: -18px;
  border: 1px solid rgb(208, 206, 206);
  box-shadow:
    1px 1px 0 rgba(0, 0, 0, 0.1),
    3px 3px 0 rgba(208, 206, 206, 1);
  font-size: 2rem;
  font-weight: bold;
  height: 60px;
  position: absolute;
  right: 22px;
  transform: rotate(-5deg);
  width: 60px;
  z-index: 2;

  &::before {
    background-color: rgba(179, 171, 159, 0.5);
    content: '';
    height: 15px;
    position: absolute;
    right: -8px;
    top: -8px;
    transform: rotate(10deg);
    width: 50px;
  }
`;

export default class Category extends Component {
  static propTypes = {
    to: PropTypes.oneOfType([PropTypes.string, PropTypes.object]).isRequired,
    name: PropTypes.string.isRequired,
    onClick: PropTypes.func,
    description: PropTypes.string,
    color: PropTypes.string,
    percentage: PropTypes.number,
  };

  static defaultProps = {
    percentage: 0,
  };

  render() {
    const {
      to, name, description, color, onClick, percentage,
    } = this.props;

    return (
      <FolderItem color={color} to={to} onClick={onClick}>
        <FoldedPage color={color}>
          <Name>{ name }</Name>
          { percentage > 0
            && <Img src={percentage < 100 ? progressIcon : progressIconDone} />}
        </FoldedPage>
        <Postit>
          { description
            && <span>{ description }</span>}
        </Postit>
      </FolderItem>
    );
  }
}
