import styled from 'styled-components';

const Dropzone = styled.div`
  background-color: #fff;
  border: 1px dashed #153952;
  border-radius: 5px;
  margin: .5rem;
  padding: .5rem;
  position: relative;
  flex: 1;
`;

export default Dropzone;
