import { groupWordsByTextIndex } from './itslanguage';

/**
 * These are the labels we expect to be returned from the feedback API.
 * If a label needs a threshold before it can be considered true or false it can be added here.
 *
 * The following guidelines are being user for determining error levels. Captured from
 * https://gitlab.com/dcentralized/itslanguage/its-cld/issues/348.
 *
 * - SL labels are not an error, unless their duration is larger than a specific threshold;
 * - FS can be ignored as the error depends on the following label (OW, CW);
 * - Word confidence score can be used to accept or reject the related labels;
 * - Pronunciation error label (PC) with score below threshold might be considered a CW;
 * - Correct label (CW) with score below threshold might be considered a PC;
 * - Score might not be meaningful for reading error labels (RW, OW);
 *
 * The threshold field currently relates to the wordConfidence field in the feedback result (the
 * intermediate result). The value will be null, 0 or between 0 and 1. The closer to 1 the most
 * likely the label in the result is correct.
 */
export const thresholds = {
  CW: 0.0,
  PC: 0.0,
};

/**
 * Assess the threshold. If the value is 0, it will always return true!
 *
 * @param {number} value - The value to assess.
 * @param {number} threshold - The threshold to assess the value against.
 * @returns {boolean} - Is the value higher then the threshold?
 */
export function assessThreshold(value, threshold) {
  return (!value || value >= threshold);
}

/**
 * Assess a word to determine its correctness (or not) based on the label it got from the
 * ITSLanguage backend. A word will always be passed as array. This is because some errors are
 * combined together (as an example if a word first has an RW label, and then an CW it should be
 * marked as correct).
 *
 * @param {Object[]} word - The word to asses. It will be an array with the same word to check.
 * @returns {bool} - The result of the assessment.
 */
export function assessWord(word) {
  return word.reduce((acc, singleWord) => {
    // If it is already correct, leave it correct. Jup.
    if (acc) { return acc; }

    // Assume false up front; Easier to return!
    let result = false;

    if (singleWord.type === 'CW') {
      // If the value is higher then the threshold, accept it!
      result = assessThreshold(singleWord.confidence, thresholds.CW);
    } else if (singleWord.type === 'PC') {
      // If the value is higher then the threshold, accept it but return false!
      // This means: for a PC that does not meet the threshold we will accept is as an CW.
      result = !assessThreshold(singleWord.confidence, thresholds.PC);
    } else if (singleWord.type === '!sil') {
      // Ignore
      result = true;
    } else if (singleWord.type === '<unk>') {
      // No way to show it
      result = true;
    }

    return result;
  }, false);
}

/**
 * Parse the feedback sentence and determine how many mistakes where made. Return the errors in an
 * array.
 *
 * @param {object} sentence - The sentence from the feedback to check.
 * @returns {Array} - Array with the mistakes. Use the lenght to determine the mistakes count.
 */
export function assessSentence(words) {
  const defaultResult = [
    0, // Number of mistakes in sentence;
    [], // Mistakes, grouped by textIndex;
    [], // Mistakes, flat: all words with error label;
  ];

  return groupWordsByTextIndex(words).reduce(([total, grouped, flat], word) => (assessWord(word)
    ? [total, grouped, flat]
    : [total + 1, [...grouped, word], [...flat, ...word]]), defaultResult);
}

/**
 * Get a value from a string with data encapsulated in a string, like the metadata field
 * on a SpeechChallenge.
 *
 * @param {string} metadata - The metadata string to inspect.
 * @param {string} key - The key to search for.
 * @param {*} [defaultValue=null] - The defaultValue for the key if not found. Null by default.
 * @returns {boolean | undefined} - The value, either from the string or the defaultValue. If
 * either the metadata or key is wrong it will return undefined.
 */
export function getSettingFromMetadataString(metadata, key, defaultValue = null) {
  let result = defaultValue;

  if (typeof metadata !== 'string' || !key) {
    // If the metadata is not a string, or if the key is not set,
    // return early and return nothing;
    return;
  }

  if (metadata.includes(key)) {
    try {
      result = metadata[key];
    } catch (e) { /* nothing to do, just ignore! */ }
  }

  return result;
}
