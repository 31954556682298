import React from 'react';
import PropTypes from 'prop-types';
import Word from '../Word';
import P from '../P';
import { DebugContext } from '../../containers/DebugDialog';

function extractWordArray(sentence, startIndicatorEnabled, debugContext) {
  const acc = {
    markLine: false,
    words: [],
  };

  return sentence.reduce((total, word) => {
    let words = [];
    let { markLine } = total;
    let { doubleLineFeed } = total;

    words.push(
      <Word
        key={word.textIndex}
        textIndex={word.textIndex}
        sentenceIndex={word.sentenceIndex}
        sentence={word.sentence}
        start={word.start}
        end={word.end}
        highlight={word.highlight}
        error={word.error}
        errorIndex={word.errorIndex}
        showFeedback={word.showFeedback}
        feedback={word.feedback}
        underlineFeedback={debugContext.feedbackUnderlinesEnabled}
        showStartIndicator={word.startIndicator}
        startIndicatorEnabled={startIndicatorEnabled}
        targetWord={word.targetWord}
        targetWordIndex={word.targetWordIndex}
        pronunciationChange={word.pronunciationChange}
        clipping={word.clipping}
        underlineTargetWord={debugContext.feedbackUnderlinesEnabled}
      >
        {word.displayText}
      </Word>,
    );
    if (word.type !== 'unk') {
      words.push(<span key={`${word.textIndex}-space`}>&nbsp;</span>);
    }

    // Also append a line break if needed!
    // The doubleLineFeed will be used to add extra
    // margin to the paragraph this line is part of.
    if (word.text.includes('[lf][lf]')) {
      doubleLineFeed = true;
    }

    if (!total.markLine && word.highlight) {
      markLine = word.highlight;
    }

    if (word.text.includes('<EoS>')) {
      words = [
        ...words,
      ];
    }

    return {
      doubleLineFeed,
      markLine,
      words: [
        ...total.words,
        ...words,
      ],
    };
  }, acc);
}

function Line(props) {
  const debugContext = React.useContext(DebugContext);
  const { sentence, startIndicatorEnabled } = props;
  const {
    markLine,
    doubleLineFeed,
    words,
  } = extractWordArray(sentence, startIndicatorEnabled, debugContext);

  return (
    <P marked={markLine} marginAfter={doubleLineFeed}>
      {words}
    </P>
  );
}

Line.propTypes = {
  sentence: PropTypes.array.isRequired,
  startIndicatorEnabled: PropTypes.bool.isRequired,
};

export default Line;
