import styled from 'styled-components';

const DownloadLink = styled.a`
  text-decoration: underline;
  
  :hover {
    text-decoration: dotted;
  }
`;

export default DownloadLink;
