import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router';
import styled, { css } from 'styled-components';

import { cell, Row } from './shared';

const Container = styled.div`
  background-color: #eee;
  display: flex;
  flex: 1;
  flex-direction: column;
`;

const Main = styled.div`
  display: flex;
  flex: 1;
  margin: 1.444rem;
`;

const Table = styled.table`
  border-collapse: collapse;
  color: #444;
  display: flex;
  flex: 1;
  flex-direction: column;
  font-size: 0.8rem;
  max-height: 100%;
  width: 100%;
`;

const Header = styled.thead`
  box-shadow: 0 5px 5px #ddd;
  z-index: 1;
`;

const headerCell = css`
  ${cell}
  color: #888;
  font-weight: normal;

  &:first-child {
    color: #222;
  }

  ${(props) => props.hasChildren && `
    &:not(:first-child)::after {
      padding: 5px;
      content: '>'
    }
  `}
`;

const StyledLink = styled(Link)`
  position: relative;
  margin-left: 1.2rem;

  &::before {
    content: 'X';
    font-size: 1.1rem;
    left: -1.2rem;
    position: absolute;
    top: -3px;
  }
`;

const HeaderCell = styled.th`
  ${headerCell}
`;

const Overview = ({
  basePath, category, headers, children, hasChildren, onCategoryClick,
}) => (
  <Container>
    <Main>
      <Table>
        <Header>
          <Row>
            <HeaderCell>
              <StyledLink to={basePath} name="back">
                {`${category.name} ${isNaN(category.description) ? `: ${category.description}` : ''}`}
              </StyledLink>
            </HeaderCell>
            { hasChildren ? headers.map((head) => (
              <HeaderCell
                key={head.id}
                hasChildren={hasChildren}
                onClick={() => onCategoryClick(head)}
              >
                <Link to={`${basePath}/${category.id}/${head.id}`}>
                  {head.name}
                </Link>
              </HeaderCell>
            )) : headers.map((head, index) => (
              <HeaderCell
                key={head.id}
                hasChildren={hasChildren}
              >
                {head.name || `Pag ${(index + 1)}` }
              </HeaderCell>
            ))}
          </Row>
        </Header>
        {children}
      </Table>
    </Main>
  </Container>
);

Overview.propTypes = {
  basePath: PropTypes.string.isRequired,
  category: PropTypes.shape().isRequired,
  headers: PropTypes.array.isRequired,
  children: PropTypes.node.isRequired,
  hasChildren: PropTypes.bool.isRequired,
  onCategoryClick: PropTypes.func,
};

export default Overview;
