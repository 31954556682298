import React, { Component } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import Button from '../reading/Buttons';

const ControlStrip = styled.div`
  display: flex;
  flex: 1;
  flex-direction: row;
  font-size: 0.889rem;
  padding: 0.7rem 0;
  justify-content: center;
`;

export default class ChoiceControls extends Component {
  static propTypes = {
    pageId: PropTypes.number.isRequired,
    pageCount: PropTypes.number.isRequired,
    nextPage: PropTypes.func.isRequired,
    prevPage: PropTypes.func.isRequired,
  };

  static defaultProps = {
    pageId: 0,
    pageCount: 1,
  };

  render() {
    const {
      pageId, pageCount, nextPage, prevPage,
    } = this.props;

    return (
      <ControlStrip>
        <Button control="tab-prev" hidden={pageId < 1} onClick={prevPage} />
        <Button control="tab-prev-disabled" hidden={pageId >= 1} />
        <Button control="tab-next" hidden={pageId >= pageCount - 1} onClick={nextPage} />
        <Button control="tab-next-disabled" hidden={pageId < pageCount - 1} />
      </ControlStrip>
    );
  }
}
