import React, { Component } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import AudioScrubber from '../audio/AudioScrubber';
import playIcon from './play.png';
import pauseIcon from './pause.png';

const AudioPlayer = styled.div`
  align-items: center;
  background-color: #FFFFFF;
  border: 1px solid #D9D8D8;
  border-radius: 100px;
  display: flex;
  height: 36px;
  max-width: 207px;
  padding-right: 10px;
`;

const Button = styled.img`
  cursor: pointer;
  height: 25px;
  margin: 5px;
  width: 25px;

  ${(props) => props.disabled && `
    display: none;
  `}
`;

const Container = styled.div`
  align-items: center;
  flex: 1 1 auto;
  justify-content: space-around;
`;

const Timer = styled.div`
  flex: 2 2 auto;
`;

const PLAYBACK_RATE = 1;

export default class AssignmentAudio extends Component {
  static propTypes = {
    audioUrl: PropTypes.string,
  };

  constructor(props) {
    super(props);

    this.state = {
      audioContext: {},
      isPlaying: false,
      percentage: 0,
      current: 0,
      duration: 0,
    };

    this.audioPlayer = new Audio();
    this.animationLoop = null;
    this.audioScrubberRef = null;

    this.start = this.start.bind(this);
    this.handleStart = this.handleStart.bind(this);
    this.stop = this.stop.bind(this);
    this.onDrag = this.onDrag.bind(this);
    this.onDragEnd = this.onDragEnd.bind(this);

    this.audioPlayer.addEventListener('canplay', this.start);
  }

  componentWillUnmount() {
    this.audioPlayer.load();
    this.audioPlayer.removeEventListener('canplay', this.start);
  }

  onDrag(percentage) {
    let current = 0;

    if (this.state.duration > 0) {
      current = (this.state.duration * percentage) / 100;
    }

    this.setState({
      current,
      percentage,
    });
  }

  onDragEnd(percentage) {
    this.audioPlayer.currentTime = (this.state.duration * percentage) / 100;
  }

  handleStart() {
    // Load audio when user clicks "play";
    this.audioPlayer.playbackRate = PLAYBACK_RATE;
    this.audioPlayer.src = this.props.audioUrl;
  }

  start() {
    this.setState({
      current: 0,
      duration: this.audioPlayer.duration,
      isPlaying: true,
    });

    this.audioPlayer.play();
    this.playerTimeUpdate();
  }

  stop() {
    this.setState({
      isPlaying: false,
    });
    this.audioPlayer.pause();
    this.cancelLoop();
  }

  playerTimeUpdate() {
    const frameLoop = () => {
      if (this.audioScrubberRef && !this.audioScrubberRef.dragging) {
        const { duration } = this.state;
        const current = this.audioPlayer.currentTime;
        let percentage = 0;

        if (duration > 0) {
          percentage = (current * 100) / duration;
        }

        this.setState({
          percentage,
          current,
        }, () => {
          this.animationLoop = requestAnimationFrame(frameLoop);
        });
      }
    };

    frameLoop();
  }

  cancelLoop() {
    cancelAnimationFrame(this.animationLoop);
  }

  render() {
    const { audioUrl } = this.props;
    const { isPlaying, duration, current } = this.state;

    return (
      <Container>
        { isPlaying ? (
          <AudioPlayer>
            <Button src={pauseIcon} onClick={this.stop} />
            <Timer>
              <AudioScrubber
                ref={(component) => { this.audioScrubberRef = component; }}
                allowScrubbing
                onDrag={this.onDrag}
                onDragEnd={this.onDragEnd}
                percentage={this.state.percentage}
                timing={{
                  current,
                  total: duration,
                }}
              />
            </Timer>
          </AudioPlayer>
        ) : (
          <Button src={playIcon} disabled={!audioUrl} onClick={this.handleStart} />
        )}
      </Container>
    );
  }
}
