import React, { Component } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { verticalCenter } from '../shared/styles';

const Scrubber = styled.div`
  ${verticalCenter({ flexDirection: 'row' })}
  align-items: center;
`;

const ScrubberContainer = styled.span`
  border: 2px solid #d2cdcd;
  height: 2px;
  margin: 0 18px;
  position: relative;
  width: 100%;
`;

const ScrubberProgress = styled.span.attrs((props) => ({
  style: {
    width: `${props.position}px`,
  },
}))`
  background: #3391E9;
  display: inline-block;
  height: 4px;
  left: -2px;
  margin: 0;
  position: absolute;
  top: -2px;
`;

const ScrubberButton = styled.button.attrs((props) => ({
  style: {
    left: `${props.position}px`,
  },
}))`
  background-color: #3391e9;
  border: 0;
  border-radius: 10px;
  height: 10px;
  margin: 0;
  padding: 0;
  position: absolute;
  top: -5px;
  user-select: none;
  width: 10px;

  &:hover, &:focus {
    outline: 0;
  }
`;

/*
 * The absolute position of an element on a page can be retrieved by traversing
 * the parents and adding the offsets.
 */
const getPosition = (element) => {
  let xPosition = 0;
  let yPosition = 0;

  while (element) {
    xPosition += (element.offsetLeft - element.scrollLeft) + element.clientLeft;
    yPosition += (element.offsetTop - element.scrollTop) + element.clientTop;
    element = element.offsetParent; // eslint-disable-line no-param-reassign
  }
  return {
    x: xPosition,
    y: yPosition,
  };
};

/**
 * Parse a number that represents seconds to 0:00 format.
 *
 * @param {string|Number} seconds
 * @return {string}
 */
const parseSecondsToDate = (seconds) => {
  const date = new Date(seconds * 1000);
  const mins = date.getMinutes();
  let secs = date.getSeconds();
  if (secs < 10) {
    secs = `0${secs}`;
  }
  return `${mins}:${secs}`;
};

export default class AudioScrubber extends Component {
  static propTypes = {
    onDrag: PropTypes.func,
    onDragEnd: PropTypes.func,
    timing: PropTypes.shape({
      current: PropTypes.number.isRequired,
      total: PropTypes.number.isRequired,
    }).isRequired,
    percentage: PropTypes.number.isRequired,
    allowScrubbing: PropTypes.bool,
  };

  constructor(props) {
    super(props);

    this.dragging = false;
    this.rangeWidth = 0;
    this.rangeLeft = 0;
    this.progressSlider = null;

    this.draggerDown = this.draggerDown.bind(this);
    this.draggerUp = this.draggerUp.bind(this);
    this.draggerMove = this.draggerMove.bind(this);
  }

  componentDidMount() {
    this.rangeWidth = parseInt(getComputedStyle(this.progressSlider).width, 10);
    this.rangeLeft = getPosition(this.progressSlider).x;

    if (this.props.allowScrubbing) {
      this.addEventsToComponent();
    }
  }

  UNSAFE_componentWillReceiveProps() {
    this.rangeWidth = parseInt(getComputedStyle(this.progressSlider).width, 10);
  }

  componentWillUnmount() {
    this.removeEventsFromComponent();
  }

  getPostionAsPercentage(event) {
    return Math.round(((event.pageX - this.rangeLeft) / this.rangeWidth) * 100);
  }

  removeEventsFromComponent() {
    document.body.removeEventListener('mousemove', this.draggerMove);
    document.body.removeEventListener('mouseup', this.draggerUp);
  }

  addEventsToComponent() {
    document.body.addEventListener('mousemove', this.draggerMove);
    document.body.addEventListener('mouseup', this.draggerUp);
  }

  draggerDown(event) {
    this.dragging = true;
    this.updateDragger(event);
    return false;
  }

  draggerUp(event) {
    if (this.dragging) {
      this.dragging = false;

      if (this.props.onDragEnd instanceof Function) {
        this.props.onDragEnd(this.getPostionAsPercentage(event));
      }
    }
  }

  draggerMove(event) {
    this.updateDragger(event);
  }

  updateDragger(event) {
    if (this.dragging && event.pageX >= this.rangeLeft
      && event.pageX <= this.rangeLeft + this.rangeWidth) {
      if (this.props.onDrag) {
        this.props.onDrag(this.getPostionAsPercentage(event));
      }
    }
  }

  render() {
    const { current, total } = this.props.timing;
    const position = (this.rangeWidth * this.props.percentage) / 100;

    let scrubberButton = null;
    if (this.props.allowScrubbing) {
      scrubberButton = <ScrubberButton position={position - 5} onMouseDown={this.draggerDown} />;
    } else {
      scrubberButton = <ScrubberButton position={position - 5} />;
    }

    return (
      <Scrubber>
        <span>{ parseSecondsToDate(current) }</span>
        <ScrubberContainer ref={(component) => { this.progressSlider = component; }}>
          <ScrubberProgress position={position} />
          {scrubberButton}
        </ScrubberContainer>
        <span>{ parseSecondsToDate(total) }</span>
      </Scrubber>
    );
  }
}
