/*
 * Action types
 */

export const UPDATE_MIC_CHECK = 'UPDATE_MIC_CHECK';

/*
 * Other constants
 */

export const defaultMicCheckStates = {
  micChecked: false,
};

/*
 * Action creators
 */

export function updateMicrophoneChecked(micChecked) {
  return {
    type: UPDATE_MIC_CHECK,
    micChecked,
  };
}

export function checkMicrophone() {
  return {
    type: UPDATE_MIC_CHECK,
    micChecked: false,
  };
}
