import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { browserHistory } from 'react-router';
import { loginReset } from './LoginActions';
import { updateMicrophoneChecked } from '../containers/MicrophoneCheckDialog/actions';

export class Logout extends Component {
  static propTypes = {
    resetLogin: PropTypes.func.isRequired,
    credentials: PropTypes.shape().isRequired,
  };

  UNSAFE_componentWillMount() {
    const {
      resetLogin,
      resetMicCheck,
      credentials,
    } = this.props;
    let redirectPath = '/';

    if (credentials && credentials.username && !credentials.emailaddress) {
      redirectPath = `/login/student/${credentials.schoolcode}`;
    }

    resetLogin();
    resetMicCheck();
    browserHistory.push(redirectPath);
  }

  // Nothing to render
  render() {
    return (
      <p>Uitloggen...</p>
    );
  }
}

// container part below
const mapStateToProps = (state) => ({ ...state.authentication });

const mapDispatchToProps = {
  resetLogin: loginReset,
  resetMicCheck: updateMicrophoneChecked,
};

export default connect(mapStateToProps, mapDispatchToProps)(Logout);
