import React, { Component } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import SubripText from '../containers/SubRipText';
import { audioPlayer } from '../shared/itslanguage';

const Page = styled.div`
  display: flex;
  flex: 1;
  flex-direction: row;
  padding: 0.778rem 1.556rem 1.556rem;
`;

const Side = styled.div`
  flex: 0 1 ${(props) => props.sideWidth};
`;

const SideCentered = styled(Side)`
  align-items: center;
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: flex-end;
  text-align: center;
`;

const ExerciseImage = styled.img`
  width: 100%;
`;

export default class Exercise extends Component {
  static propTypes = {
    speechChallenge: PropTypes.shape({
      image: PropTypes.bool,
      styling: PropTypes.shape({
        fontSize: PropTypes.string,
        fontWeight: PropTypes.string,
        lineHeight: PropTypes.string,
        pageWidth: PropTypes.string,
      }),
    }).isRequired,
    feedbackModelEnabled: PropTypes.bool,
    words: PropTypes.array.isRequired,
    firstWordPerSentence: PropTypes.array.isRequired,
    numberOfWords: PropTypes.number.isRequired,
    showStartIndicator: PropTypes.bool,
    feedbackOnWords: PropTypes.array,
    type: PropTypes.string.isRequired,
  };

  static defaultProps = {
    recordingState: null,
    feedbackModelEnabled: null,
    firstWordPerSentence: [],
    feedbackOnWords: [],
  };

  constructor(props) {
    super(props);

    this.animationLoop = null;

    this.state = {
      currentTime: 0,
      audioReady: false,
      captions: [],
    };

    this.processTimeUpdate = this.processTimeUpdate.bind(this);
    this.clearCurrentTime = this.clearCurrentTime.bind(this);

    if (props.type === 'reading') {
      audioPlayer.addEventListener('play', this.processTimeUpdate);
      audioPlayer.addEventListener('ended', this.clearCurrentTime);
    }

    try {
      this.metadata = this.props.speechChallenge.styling;
    } catch (e) {
      // Did not work. No worries though! Setting default..
      this.metadata = {
        fontSize: '20px',
        fontWeight: '400',
        lineHeight: '30px',
        pageWidth: '1000px',
      };
    }
  }

  componentWillUnmount() {
    audioPlayer.removeEventListener('play', this.processTimeUpdate);
    audioPlayer.removeEventListener('pause', this.pauseTimeUpdate);
    audioPlayer.removeEventListener('ended', this.clearCurrentTime);
  }

  processTimeUpdate() {
    const frameLoop = () => {
      const { currentTime } = audioPlayer;

      this.setState(() => ({
        currentTime: (currentTime * 1000),
      }), () => {
        this.animationLoop = requestAnimationFrame(frameLoop);
      });
    };

    frameLoop();
  }

  pauseTimeUpdate() {
    cancelAnimationFrame(this.animationLoop);
  }

  clearCurrentTime() {
    cancelAnimationFrame(this.animationLoop);
    this.setState({
      currentTime: 0,
    });
  }

  render() {
    const { image, id } = this.props.speechChallenge;
    const pageWidth = '550px';

    return (
      <Page>
        <Side sideWidth={pageWidth}>
          <SubripText
            words={this.props.words}
            feedbackModelEnabled={this.props.feedbackModelEnabled}
            firstWordPerSentence={this.props.firstWordPerSentence}
            numberOfWords={this.props.numberOfWords}
            feedbackOnWords={this.props.feedbackOnWords}
            showStartIndicator={this.props.showStartIndicator}
            currentTime={this.state.currentTime}
            metadata={this.metadata}
            playAlong={this.props.type === 'reading'}
          />
        </Side>
        { image
          && (
          <SideCentered>
            <ExerciseImage src={`${process.env.REACT_APP_TUTO_API_URL}/prompt/${id}/image`} alt="Oefening" />
          </SideCentered>
          )}
      </Page>
    );
  }
}
