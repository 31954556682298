import styled from 'styled-components';

const User = styled.span`
  color: #8796a2;
  font-size: 0.722rem;
  font-weight: bold;
  padding: 0 1rem;
`;

export default User;
