import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import styled from 'styled-components';
import Button from './Buttons';
import { showErrorMessage, showReadyMessage } from './ReadingAssignmentActions';

const ExerciseEndControls = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
`;

export class ExerciseControls extends Component {
  static propTypes = {
    activePage: PropTypes.shape().isRequired,
    showReadyMessage: PropTypes.func.isRequired,
    showErrorMessage: PropTypes.func.isRequired,
    pageCount: PropTypes.number.isRequired,
    disabled: PropTypes.bool.isRequired,
  };

  constructor(props) {
    super(props);

    this.closeExercise = this.closeExercise.bind(this);
  }

  closeExercise() {
    this.props.showErrorMessage();
  }

  render() {
    const { activePage: { pageId }, pageCount, disabled } = this.props;

    return (
      <ExerciseEndControls>
        <Button control="end-disabled" hidden={pageId === (pageCount) && !disabled} />
        <Button control="end" hidden={pageId < (pageCount) || disabled} onClick={this.closeExercise} />
      </ExerciseEndControls>
    );
  }
}

// container part below
const mapStateToProps = (state) => ({ ...state.ReadingAssignment });

const mapDispatchToProps = {
  showErrorMessage,
  showReadyMessage,
};

export default connect(mapStateToProps, mapDispatchToProps)(ExerciseControls);
