import styled from 'styled-components';

const Book = styled.div`
  background: #fff;
  box-shadow: 0 2px 4px 0 rgba(147, 146, 146, 0.5);
  display: flex;
  height: 648.42px;
  margin: 1rem;
  position: relative;
  width: 1004px;
`;

export default Book;
