import styled from 'styled-components';

export const OverviewBody = styled.tbody`
  overflow-y: scroll;
  height: 100%;
`;

export const cell = `
  display: block;
  flex: 1 1 20%;
  text-align: center;

  &:first-child {
    flex: 1 1 10rem;
    margin-left: 10px;
    text-align: left;
  }
`;

export const Cell = styled.td`
  ${cell}

  &:not(:first-child) {
    text-align: center;
  }
`;

export const Img = styled.img`
  height: 25px;
  width: 25px;
`;

export const Row = styled.tr`
  align-items: center;
  background-color: #fff;
  display: flex;
  height: 60px;
  justify-content: space-around;
  width: 100%;

  &:not(:last-child) {
    border-bottom: 2pt solid #eee;
  }
`;
