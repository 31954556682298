import React, { Component } from 'react';
import * as Sentry from '@sentry/browser';
import Main from '../Main';
import Book from '../Book';

import Button from './Button';
import Img from './Img';
import Lines from './Lines';
import Line from './Line';
import MessageContainer from './MessageContainer';
import sendMessage from './send-message.png';

class SentryErrorBoundary extends Component {
  constructor(props) {
    super(props);
    this.state = { eventId: null };
  }

  /* eslint-disable no-unused-vars */
  static getDerivedStateFromError(error) {
    return { hasError: true };
  }
  /* eslint-enable no-unused-vars */

  componentDidCatch(error, errorInfo) {
    Sentry.withScope((scope) => {
      scope.setExtras(errorInfo);
      const eventId = Sentry.captureException(error);
      this.setState({ eventId });
    });
  }

  render() {
    const { hasError, eventId } = this.state;
    const { children } = this.props;

    // Either we render "normal" (by just returning the children as is) or we
    // do something "special" when things went wrong.
    if (hasError) {
      return (
        <Main>
          <Book>
            <MessageContainer>
              <Lines>
                <Line>Oeps!</Line>
                <Line>Er is onverwachts iets helemaal mis gegaan...</Line>
              </Lines>
              <Img src={sendMessage} />
              <Lines>
                <Line>Het incident is bij ons gemeld.</Line>
                <Line>
                  Wilt u ons meer informatie over het probleem sturen?
                  Gebruik daarvoor de knop hieronder.
                </Line>
              </Lines>
              <Button
                onClick={() => Sentry.showReportDialog({
                  eventId,
                  lang: 'nl',
                  title: 'Er is een probleem opgetreden.',
                  subtitle: 'Ons team heeft een bericht ontvangen.',
                  subtitle2: '',
                  labelName: 'Naam',
                  labelEmail: 'E-mail',
                  labelComments: 'Wat ging er mis?',
                  labelClose: 'Sluit',
                  labelSubmit: 'Stuur bericht',
                  errorGeneric: 'Er ging iets mis tijdens het verzenden van het bericht. Probeer het nogmaals.',
                  errorFormEntry: 'Een aantal velden is niet juist ingevuld. Controleer de velden en probeer het nogmaals.',
                  successMessage: 'De feedback is verzonden. Super bedankt!',
                })}
              >
                Stuur ons meer informatie.
              </Button>
            </MessageContainer>
          </Book>
        </Main>
      );
    }

    // No error. Render children untouched
    return children;
  }
}

export default SentryErrorBoundary;
