/**
 * File with some cool audio utility functions everyone will want to use;
 * Rock on 🎸🤘!
 */

import { Howler } from 'howler';
import { getRandomValueFromArray } from './utils';

/**
 * An async function that will playback audio. Make sure to pass an audio object
 * created with Howl.
 *
 * @param {Howl} soundObject - The audio object to play.
 * @param {string} [soundId = null] - Id of the sound to play.
 * @returns {Promise} - Resolves once playback has ended.
 */
export function playAudio(soundObject, soundId = null) {
  Howler.stop();
  return new Promise((resolve, reject) => {
    if (!soundObject) {
      reject('no audio');
    }

    soundObject.play(soundId && soundId);

    soundObject.once('end', () => {
      resolve();
    });
  });
}

/**
 * An async function that will playback audio. Make sure to pass an audio object
 * created with Howl. It will randomly pick one of the sprites to play. If there is
 * no sprite, the audio will just be played back.
 *
 * @param {Howl} soundObject - The audio object to play.
 * @returns {Promise} - Resolves once playback has ended.
*/
export function playRandomAudioId(soundObject) {
  Howler.stop();
  return new Promise((resolve, reject) => {
    if (!soundObject) {
      reject('no audio');
    }

    if (soundObject._sprite) {
      const soundId = getRandomValueFromArray(Object.keys(soundObject._sprite));
      soundObject.play(soundId);
    } else {
      soundObject.play();
    }

    soundObject.once('end', () => {
      resolve();
    });
  });
}
