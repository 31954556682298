import React from 'react';

import Image from './Image';

const Quiet = () => {
  const quiet = `${process.env.PUBLIC_URL}/quiet.webp`;
  return (
    <Image src={quiet} alt="quiet" />
  );
};

export default Quiet;
