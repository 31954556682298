import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { verticalCenter } from '../../shared/styles';
import thumbUpIcon from './icons/thumb-up.png';
import thumbDownIcon from './icons/thumb-down.png';

const Overlay = styled.div`
  align-items: center;
  background: rgba(0, 0, 0, .4);
  bottom: 0;
  display: flex;
  flex-direction: row;
  justify-content: center;
  left: 0;
  position: fixed;
  right: 0;
  top: 0;
  z-index: 999;
`;

const Box = styled.div`
  align-items: center;
  background-color: #fff;
  border-radius: 4px;
  box-shadow: 8px 20px 10px 0 rgba(88, 88, 88, 0.5);
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  height: 296.28px;
  width: 410.42px;
`;

const Heading = styled.div`
  ${verticalCenter({ textAlign: 'center' })}
  background-color: #153952;
  color: #fff;
  font-size: 1rem;
  font-weight: 500;
  height: 58px;
  width: 100%;
`;

const Buttons = styled.div`
  ${verticalCenter({ flexDirection: 'row', textAlign: 'center' })}
  align-items: center;
  flex: 1;
`;

const Button = styled.button`
  align-items: center;
  background: linear-gradient(180deg, #e3f3fa 0%, #b1dded 100%);
  border: 1px solid #5ecef7;
  border-radius: 4px;
  box-shadow: 2px 3px 0 0 rgba(205, 205, 205, 0.5);
  color: #2a73b3;
  display: flex;
  flex-direction: column;
  font-size: 1.125rem;
  height: 148px;
  justify-content: space-around;
  margin: 8px;
  text-align: center;
  width: 155.49px;

  &:hover {
    background: linear-gradient(180deg, #e3f3fa 0%, #bdedfa 100%);
    cursor: pointer;
  }

  &:focus {
    outline: none;
  }
`;

const ThumbIcon = styled.img`
  width: 50px;
`;

const AreYouSure = ({
  onThumbUpClick, onThumbDownClick, title, yes, no,
}) => (
  <Overlay>
    <Box>
      <Heading>{title}</Heading>
      <Buttons>
        <Button onClick={onThumbUpClick}>
          <ThumbIcon src={thumbUpIcon} alt={yes} />
          <span>{yes}</span>
        </Button>
        <Button onClick={onThumbDownClick}>
          <ThumbIcon src={thumbDownIcon} alt={no} />
          <span>{no}</span>
        </Button>
      </Buttons>
    </Box>
  </Overlay>
);

AreYouSure.propTypes = {
  onThumbUpClick: PropTypes.func.isRequired,
  onThumbDownClick: PropTypes.func.isRequired,
};

export default AreYouSure;
