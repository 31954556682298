import { Howl } from 'howler';
import outputMp3 from './output.mp3';
import outputM4a from './output.m4a';
import outputAc3 from './output.ac3';
import outputOgg from './output.ogg';
import outputEnMp3 from './output-en.mp3';
import outputEnM4a from './output-en.m4a';
import outputEnAc3 from './output-en.ac3';
import outputEnOgg from './output-en.ogg';
import outputDeMp3 from './output-de.mp3';
import outputDeM4a from './output-de.m4a';
import outputDeAc3 from './output-de.ac3';
import outputDeOgg from './output-de.ogg';

/**
 * Sprite for the start beep, interrupt beep and "lees het nog eens" feedback.
 */
const sound = {
  nl: new Howl({
    src: [
      outputMp3,
      outputM4a,
      outputAc3,
      outputOgg,
    ],
    sprite: {
      again: [0, 1051],
      interrupt: [3000, 806],
      start: [5000, 500],
      'tw-1': [7000, 5455],
      'tw-10': [14000, 4248],
      'tw-11': [20000, 3969],
      'tw-2': [25000, 6152],
      'tw-3': [33000, 5293],
      'tw-4': [40000, 5409],
      'tw-5': [47000, 5107],
      'tw-6': [54000, 11214],
      'tw-7': [67000, 5037],
      'tw-8': [74000, 5757],
      'tw-9': [81000, 4689],
    },
  }),
  en: new Howl({
    src: [
      outputEnMp3,
      outputEnM4a,
      outputEnAc3,
      outputEnOgg,
    ],
    sprite: {
      again: [105000, 1128],
      interrupt: [108000, 805],
      start: [110000, 500],
      'tw-1': [36000, 5455],
      'tw-10': [0, 4247],
      'tw-11': [6000, 3969],
      'tw-12': [11000, 6634],
      'tw-13': [19000, 6826],
      'tw-14': [27000, 7424],
      'tw-2': [43000, 6151],
      'tw-3': [51000, 5292],
      'tw-4': [58000, 5408],
      'tw-5': [65000, 5106],
      'tw-6': [72000, 11213],
      'tw-7': [85000, 5037],
      'tw-8': [92000, 5757],
      'tw-9': [99000, 4688],
    },
  }),
  de: new Howl({
    src: [
      outputDeMp3,
      outputDeM4a,
      outputDeAc3,
      outputDeOgg,
    ],
    sprite: {
      again: [0, 1728],
      interrupt: [3000, 805],
      start: [5000, 500],
      'tw-1': [43000, 5455],
      'tw-10': [7000, 4247],
      'tw-11': [13000, 3969],
      'tw-12': [18000, 6634],
      'tw-13': [26000, 6826],
      'tw-14': [34000, 7424],
      'tw-2': [50000, 6151],
      'tw-3': [58000, 5292],
      'tw-4': [65000, 5408],
      'tw-5': [72000, 5106],
      'tw-6': [79000, 11213],
      'tw-7': [92000, 5037],
      'tw-8': [99000, 5757],
      'tw-9': [106000, 4688],
    },
  }),
};

export default sound;
