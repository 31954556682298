/*
 * Action types
 */

export const UPDATE_BACKGROUND_COLOR = 'UPDATE_BACKGROUND_COLOR';

/*
 * Other costants
 */
export const defaultAppStates = {
  backgroundColor: '#7cb5cb',
};

/*
 * Action creators
 */

export function updateBackgroundColor(backgroundColor) {
  return {
    type: UPDATE_BACKGROUND_COLOR,
    backgroundColor,
  };
}

export function resetBackgroundColor() {
  return {
    type: UPDATE_BACKGROUND_COLOR,
    ...defaultAppStates,
  };
}
