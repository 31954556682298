import styled from 'styled-components';

const DisableButton = styled.button`
  cursor: pointer;
  height: 100%;
  margin-right: 0.5rem;
  position: absolute;
  right: 0;
`;

export default DisableButton;
