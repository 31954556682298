import styled from 'styled-components';
import { Link as RouterLink } from 'react-router';

const Link = styled(RouterLink)`
  background: transparent;
  border: 1px solid #fff;
  border-radius: 3px;
  color: #fff;
  font-size: 0.722rem;
  margin-right: 0.2rem;
  padding: 0.2rem 0.5rem;

  &:hover {
    background-color: #7b9fb8;
    cursor: pointer;
  }
`;

export default Link;
