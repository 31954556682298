import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import axios from 'axios';
import { withTranslation } from 'react-i18next';
import {
  updateChoiceProgressSteps,
  updateChoicesCount,
  updateChoicePagesCount,
  updateActiveChoices,
  resetChoicePageState,
  defaultChoicePageStates,
} from '../choice/ChoiceActions';
import {
  resetBackgroundColor,
} from '../app/AppActions';
import ChooseChoice from '../choice/ChooseChoice';
import GroupList from '../choice/ChoiceList';
import Group from './Group';

export class LegacyChooseGroup extends Component {
  static propTypes = {
    resetBackgroundColor: PropTypes.func.isRequired,
    resetChoicePageState: PropTypes.func.isRequired,
    updateChoiceProgressSteps: PropTypes.func.isRequired,
    updateChoicesCount: PropTypes.func.isRequired,
    updateChoicePagesCount: PropTypes.func.isRequired,
    updateActiveChoices: PropTypes.func.isRequired,
    prevChoicePage: PropTypes.func,
    activeList: PropTypes.shape().isRequired,
    nextChoicePage: PropTypes.func,
  };

  static defaultProps = {
    ...defaultChoicePageStates,
  };

  constructor(props) {
    super(props);

    this.nextPage = this.nextPage.bind(this);
    this.prevPage = this.prevPage.bind(this);
  }

  UNSAFE_componentWillMount() {
    this.props.resetBackgroundColor();
    this.getGroups();
  }

  /**
   * Query the backend for group.
   */
  getGroups() {
    const { t } = this.props;
    this.props.resetChoicePageState();

    const { REACT_APP_TUTO_API_URL } = process.env;
    axios({
      method: 'get',
      url: `${REACT_APP_TUTO_API_URL}/group`,
    }).then((response) => {
      const groups = response.data;
      const numberOfGroups = groups.length;

      this.props.updateChoiceProgressSteps([{ isActive: true, name: t('grade.choose') }]);
      this.props.updateChoicesCount(numberOfGroups);
      this.props.updateChoicePagesCount(Math.ceil(numberOfGroups / 8));
      this.props.updateActiveChoices(groups);
    });
  }

  prevPage() {
    this.props.prevChoicePage(this.props.activeList.pageId);
  }

  nextPage() {
    this.props.nextChoicePage(this.props.activeList.pageId);
  }

  render() {
    const { activeList: { choices, pageId }, t } = this.props;

    return (
      <ChooseChoice showProgressionSteps choiceTitle={t('grade.title')}>
        <GroupList>
          { choices
            && choices
              .slice((pageId * 8), ((pageId + 1) * 8))
              .map((item) => (
                <Group to={`/groepen/${encodeURIComponent(item.id)}`} key={item.id}>
                  {item.name}
                </Group>
              ))}

          { !choices
            && <span>Oeps! We hebben geen groepen om weer te geven.</span>}
        </GroupList>
      </ChooseChoice>
    );
  }
}

// container part below
const mapStateToProps = ({ Choices }) => ({
  ...Choices,
  choiceProgressSteps: [...Choices.choiceProgressSteps],
  activeList: { ...Choices.activeList },
});

const mapDispatchToProps = {
  updateChoiceProgressSteps,
  updateChoicesCount,
  updateChoicePagesCount,
  updateActiveChoices,
  resetChoicePageState,
  resetBackgroundColor,
};

const ChooseGroup = withTranslation()(LegacyChooseGroup);

export default connect(mapStateToProps, mapDispatchToProps)(ChooseGroup);
