import styled from 'styled-components';

const Table = styled.table`
  margin: 0;
  padding: 0;
  width: 100%;
  
  td {
    vertical-align: top;
  }

  td pre {
    margin: 0;
    padding: 0;
  }
`;

export default Table;
