import React, { Component } from 'react';
import { Helmet } from 'react-helmet';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import styled from 'styled-components';
import debug from 'debug';
import axios from 'axios';
import { browserHistory } from 'react-router';
import { withTranslation } from 'react-i18next';
import { ToastContainer } from 'react-toastify';
import MicrophoneCheckDialog from '../containers/MicrophoneCheckDialog';
import DebugDialog from '../containers/DebugDialog';
import { resetBackgroundColor } from './AppActions';
import { checkMicrophone } from '../containers/MicrophoneCheckDialog/actions';
import Header from '../components/Header';
import Book from '../components/Book';
import Main from '../components/Main';
import { getTenantId } from '../shared/itslanguage';
import { GlobalStyle } from '../shared/styles';
import 'react-toastify/dist/ReactToastify.css';

const DEBUG = debug('its-tuto:debug');

const AppContainer = styled.div`
  ${(props) => props.backgroundColor
    && `background: radial-gradient(circle, #fff 0%, ${props.backgroundColor} 100%);`
}
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  height: 100%;
`;

export class LegacyApplication extends Component {
  static propTypes = {
    isAuthenticated: PropTypes.bool.isRequired,
    resetBackgroundColor: PropTypes.func.isRequired,
    backgroundColor: PropTypes.string.isRequired,
    children: PropTypes.node.isRequired,
    user: PropTypes.object,
    micChecked: PropTypes.bool,
  };

  static defaultProps = {
    micChecked: false,
  };

  constructor(props) {
    super(props);

    this.state = {
      username: '',
      usertype: '',
    };

    this.tenantId = getTenantId();
    this.checkMicrophone = this.checkMicrophone.bind(this);
  }

  UNSAFE_componentWillMount() {
    if (this.props.isAuthenticated) {
      this.getUserdetails();
    } else {
      this.props.resetBackgroundColor();
    }
  }

  UNSAFE_componentWillReceiveProps(props) {
    if (props.isAuthenticated && this.props.user !== props.user) {
      this.getUserdetails();
    } else if (!props.isAuthenticated) {
      this.props.resetBackgroundColor();
    }
  }

  getUserdetails() {
    const { REACT_APP_TUTO_API_URL } = process.env;
    const { t } = this.props;

    axios({
      method: 'get',
      url: `${REACT_APP_TUTO_API_URL}/user`,
    }).then((response) => {
      this.setState({
        username: response.data.username,
        usertype: response.data.role.includes('TEACHER') ? t('login.teacher') : t('login.student'),
      });
    }).catch(() => {
      browserHistory.push('/logout');
    });
  }

  checkMicrophone() {
    const { checkMicrophone } = this.props;
    checkMicrophone();
  }

  render() {
    const {
      micChecked,
      isAuthenticated,
      backgroundColor,
      children,
      t,
    } = this.props;
    const { username, usertype } = this.state;
    const { tenantId } = this;

    return (
      <>
        <Helmet>
          <link rel="shortcut icon" href={`${process.env.PUBLIC_URL}/${tenantId}/favicon.png`} />
          <title>
            {t('title')}
          </title>
        </Helmet>
        <AppContainer backgroundColor={backgroundColor}>
          <Header
            authenticated={isAuthenticated}
            username={username}
            usertype={usertype}
            micCheckClicked={this.checkMicrophone}
          />
          <Main>
            <GlobalStyle />
            <ToastContainer style={{ width: '50%' }} />
            <Book>
              { isAuthenticated && !micChecked && (
                <MicrophoneCheckDialog />
              )}
              {children}
            </Book>
          </Main>
          {DEBUG.enabled && <DebugDialog />}
        </AppContainer>
      </>
    );
  }
}

// Container stuff...
const mapStateToProps = (state) => ({
  ...state.authentication,
  ...state.App,
  ...state.MicrophoneCheck,
});

const mapDispatchToProps = {
  resetBackgroundColor,
  checkMicrophone,
};

const Application = withTranslation()(LegacyApplication);

export default connect(mapStateToProps, mapDispatchToProps)(Application);
