/**
 * Display a more recognizable name for the user.
 * Format: firstname infix lastname[0] (user id).
 *
 * For example: Sophie K (sophie).
 *
 * @param {object} user - The user object.
 * @returns {string} - String of user.
 */
export function displayName(user) {
  return `${user.first_name} ${user.infix} ${user.last_name} (${user.id})`;
}
