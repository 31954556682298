import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import styled from 'styled-components';
import { withTranslation } from 'react-i18next';
import { loginUser, loginReset } from './LoginActions';
import loadingIcon from './loading.svg';

const Form = styled.form`
  color: #4d4d4d;
  margin: 3.944rem 0;
`;

const Label = styled.label`
  display: block;
  font-size: 0.667rem;
  margin-bottom: 0.667rem;
`;

const Input = styled.input`
  background-color: #fff;
  border: 1px solid ${(props) => (props.error === 'error' ? '#e96a6a' : '#deeef4')};
  border-radius: 4px;
  box-shadow: 2px 3px 0 0 rgba(205, 205, 205, 0.5);
  display: block;
  font-size: 0.667rem;
  margin-bottom: 0.889rem;
  padding: 1rem;
  width: 100%;

  &:focus {
    outline: none;
    box-shadow: 2px 3px 0 0 rgba(222, 238, 244, 0.5);
  }

  &:invalid {
    outline: none;
    border: 1px solid #e96a6a;
  }

  &:disabled {
    background: #c5c5c5;
    cursor: not-allowed;
  }
`;

const Button = styled.button`
  background: linear-gradient(180deg, #77b4f6 0%, #357bb1 100%);
  border-radius: 4px;
  border-width: 0;
  box-shadow: 0 3px 0 0 rgba(189, 187, 187, 0.5);
  color: #fff;
  display: block;
  font-size: 0.778rem;
  height: 48.92px;
  line-height: 1;
  margin-top: 1.667rem;
  padding: 0 1rem;
  position: relative;
  text-align: left;
  width: 100%;

  &:hover {
    background: #357bb1;
    cursor: pointer;
  }

  &:active {
    background: #02487e;
  }

  &:focus {
    outline: none;
  }

  &:disabled {
    background: #919191;
    cursor: not-allowed;

    &::after {
      background-image: url(${loadingIcon});
      background-size: 1.5rem 1.5rem;
      background-repeat: no-repeat;
      content: ' ';
      height: 1.5rem;
      margin-top: -0.35rem;
      position: absolute;
      right: 1rem;
      width: 1.5rem;
    }
  }
`;

const Error = styled.div`
  color: #e96a6a;
  font-size: 0.667rem;
  line-height: 1.111rem;
  margin-top: 1.337rem;
`;

export class LegacyLoginForm extends Component {
  static propTypes = {
    isFetching: PropTypes.bool.isRequired,
    isAuthenticated: PropTypes.bool.isRequired,
    errorMessage: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.object,
    ]).isRequired,
    loginReset: PropTypes.func.isRequired,
    requestLogin: PropTypes.func.isRequired,
    userType: PropTypes.string,
    orgId: PropTypes.string,
  };

  constructor(props) {
    super(props);

    this.state = {
      schoolcode: '',
      username: '',
      emailaddress: '',
      password: '',
    };

    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleChange = this.handleChange.bind(this);
  }

  UNSAFE_componentWillMount() {
    if (this.props.orgId) {
      this.setState({
        schoolcode: this.props.orgId,
      });
    }
  }

  componentWillUnmount() {
    if (!this.props.isAuthenticated) {
      this.props.loginReset();
    }
    this.setState({});
  }

  handleSubmit(event) {
    event.preventDefault();
    this.props.requestLogin(this.state);
  }

  handleChange(event) {
    const newState = {};
    const newValue = event.target.value;
    newState[event.target.name] = event.target.name === 'username' ? newValue.toLowerCase() : newValue;
    this.setState(newState);
  }

  render() {
    const { orgId, t } = this.props;

    return (
      <Form onSubmit={this.handleSubmit}>

        { this.props.userType === 'student'
          && (
          <div>
            <Label htmlFor="schoolcode">{t('login.schoolcode')}</Label>
            <Input
              type="text"
              name="schoolcode"
              placeholder={t('login.placeholder.schoolcode')}
              value={this.state.schoolcode}
              onChange={this.handleChange}
              error={this.props.errorMessage && 'error'}
              disabled={orgId}
            />

            <Label htmlFor="username">{t('login.username')}</Label>
            <Input
              type="text"
              name="username"
              placeholder={t('login.placeholder.username')}
              value={this.state.username}
              onChange={this.handleChange}
              error={this.props.errorMessage && 'error'}
            />
          </div>
          )}

        { this.props.userType === 'teacher'
          && (
          <div>
            <Label htmlFor="emailaddress">{t('login.email')}</Label>
            <Input
              type="text"
              name="emailaddress"
              placeholder={t('login.placeholder.email')}
              value={this.state.emailaddress}
              onChange={this.handleChange}
              error={this.props.errorMessage && 'error'}
            />
          </div>
          )}

        <Label htmlFor="password">{t('login.password')}</Label>
        <Input
          type="password"
          name="password"
          placeholder={t('login.placeholder.password')}
          value={this.state.password}
          onChange={this.handleChange}
          error={this.props.errorMessage && 'error'}
        />

        <Button type="submit" disabled={this.props.isFetching}>{t('login.button')}</Button>

        { this.props.errorMessage
          && (
          <Error>
            <p>{t('login.failed')}</p>
          </Error>
          )}
      </Form>
    );
  }
}

// container part below
const mapStateToProps = (state) => ({ ...state.authentication });

const mapDispatchToProps = (dispatch) => ({
  requestLogin: (credentials, location) => dispatch(loginUser(credentials, location)),
  loginReset: () => dispatch(loginReset()),
});

const LoginForm = withTranslation()(LegacyLoginForm);

export default connect(mapStateToProps, mapDispatchToProps)(LoginForm);
