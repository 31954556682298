import styled from 'styled-components';

const Button = styled.button`
  border: 1px solid #d3d3d3;
  display: inline-block;
  font-size: 1rem;
  font-weight: normal;
  padding: 1rem 0.8rem;
  position: relative;
  user-select: none;  
  text-align: left;

  &:hover {
    background-color: #eee;
    cursor: pointer;
  }
`;

export default Button;
