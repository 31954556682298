import styled from 'styled-components';
import { verticalCenter } from '../../shared/styles';

const Balloon = styled.div`
  ${(props) => (props.hide ? 'display: none;' : verticalCenter())}

  background: #fff;
  border-radius: 10px;
  bottom: 100px;
  box-shadow: 0 0 5px 2px rgba(0,0,0,0.5);
  color: #000;
  font-size: 0.772rem;
  font-weight: 300;
  height: 53px;
  left: 1.556rem;
  padding: 15px;
  position: absolute;
  width: 353px;

  &::after {
    bottom: -10px;
    border-color: transparent #fff #fff transparent;
    border-style: solid;
    border-width: 10px;
    box-shadow: 2px 2px 2px rgba(0,0,0,0.5);
    content: '';
    display: block;
    left: 52px;
    position: absolute;
    transform: rotate(45deg);
  }
`;

export default Balloon;
