import React, { Component } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Link } from 'react-router';
import { verticalCenter, truncate } from '../shared/styles';

const GroupItem = styled(Link)`
  ${verticalCenter({ textAlign: 'center' })}
  align-items: center;
  background-color: #fff;
  box-shadow: 2px 3px 0 0 rgba(205,205,205,0.5);
  border-radius: 4px;
  flex: 0 0 auto;
  height: 200px;
  margin: 5px;
  padding: 10px;
  position: relative;
  width: 220px;

  &:hover {
    transform: scale(1.08);
    z-index: 99;
  }
`;

const Name = styled.span`
  ${truncate()}
  color: #153952;
  font-size: 1.16rem;
  width: 100%;
`;

export default class Group extends Component {
  static propTypes = {
    to: PropTypes.string.isRequired,
    children: PropTypes.string.isRequired,
    onClick: PropTypes.func,
  };

  static defaultProps = {
    to: '',
    children: '',
  };

  render() {
    const { to, children, onClick } = this.props;

    return (
      <GroupItem to={to} onClick={onClick}>
        <Name>{ children }</Name>
      </GroupItem>
    );
  }
}
