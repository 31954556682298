import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import LoadingIndicator from '../../components/LoadingIndicator';
import Text from '../../components/Text';
import Lines from '../../components/Lines';
import { DebugContext } from '../DebugDialog';
import { assessSentence } from '../../shared/feedback';

class SubRipText extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      markedWord: -1,
      words: [],
      feedbackProcessed: [], // array with words that have been processed
    };
  }

  UNSAFE_componentWillMount() {
    const { words } = this.props;

    if (words.length) {
      this.setState({
        words,
      });
    }
  }

  UNSAFE_componentWillReceiveProps(newProps) {
    const {
      currentTime, playAlong, feedbackOnWords, words, showStartIndicator,
    } = this.props;

    // Process SRT data!
    if (words.length !== newProps.words.length) {
      this.setState({
        words: newProps.words,
      });
    }

    // Process the time as soon as it changes!
    if ((playAlong && currentTime) && currentTime !== newProps.currentTime) {
      // currentTime will be reset to 0 after playback. We might check this later.
      if (newProps.currentTime !== 0) {
        this.markWord(newProps.currentTime);
      } else {
        this.unMarkAll(true);
      }
    }

    // Process starting point
    if (newProps.words.length && (newProps.showStartIndicator !== showStartIndicator) && newProps.showStartIndicator) {
      this.markStartPoint(newProps.feedbackOnWords, newProps.startAtWord);
    } else if (newProps.words.length && (newProps.showStartIndicator !== showStartIndicator) && !newProps.showStartIndicator) {
      this.unMarkStartPoint();
    }

    // Let's go along and process received feedback!
    if ((newProps.feedbackModelEnabled
        || this.context.feedbackUnderlinesEnabled)
        && newProps.feedbackOnWords.length
        && newProps.feedbackOnWords.length !== feedbackOnWords.length) {
      this.processFeedback(newProps.feedbackOnWords);
    }

    // If the feedbackOnWords prop get's cleared, make sure to un-mark all
    if (feedbackOnWords.length && !newProps.feedbackOnWords.length) {
      this.unMarkAll(false, true);
    }
  }

  unMarkAll(all = false, clearFeedbackProcessed) {
    const words = [...this.state.words];

    words.forEach((word, index) => {
      if (word.feedback || all) {
        words[index] = {
          ...words[index],
          showFeedback: false,
          feedback: false,
          highlight: false,
          error: false,
          pronunciationChange: false,
          clipping: false,
        };
      }
    });

    this.setState((prevState) => ({
      words,
      markedWord: -1,
      feedbackProcessed: clearFeedbackProcessed ? [] : prevState.feedbackProcessed,
    }));
  }

  markWord(time) {
    const words = [...this.state.words];
    const currentlyMarked = this.state.markedWord;
    const markedWord = words.findIndex(({ start, end }) => start < time && end > time);

    if (markedWord !== -1 && markedWord !== currentlyMarked) {
      if (currentlyMarked !== -1) {
        words[currentlyMarked] = {
          ...words[currentlyMarked],
          highlight: false,
        };
      }

      words[markedWord] = {
        ...words[markedWord],
        highlight: true,
      };

      this.setState({
        words,
        markedWord,
      });
    }
  }

  markStartPoint(feedback, startAtWord) {
    const words = [...this.state.words];
    const { numberOfWords } = this.props;

    if (feedback && feedback.length) {
      const lastFeedbackItem = feedback[feedback.length - 1];

      if (lastFeedbackItem.sentence === numberOfWords) {
        // End of assignment! We do not have to show anything.
        return;
      }
    }

    if (startAtWord >= 0) {
      const startPointIndex = words.findIndex(({ textIndex }) => textIndex === startAtWord);
      words[startPointIndex] = {
        ...words[startPointIndex],
        startIndicator: true,
      };

      this.setState({
        words,
      });
    }
  }

  unMarkStartPoint() {
    const words = [...this.state.words];

    words.forEach((word, index) => {
      words[index] = {
        ...words[index],
        startIndicator: false,
      };
    });

    this.setState({
      words,
    });
  }

  processFeedback(feedback) {
    if (feedback) {
      const originalWords = [...this.state.words];
      const feedbackToProcess = [...this.state.feedbackProcessed, ...feedback];
      const [,, errors] = assessSentence(feedbackToProcess);

      if (feedbackToProcess.length > 0) {
        feedbackToProcess.forEach((label) => {
          const { textIndex, clipping } = label;

          let errorIndex = null;
          if (errors.length > 0) {
            errorIndex = errors[0].textIndex;
          }

          let error = false;
          // Does the word have an error?
          if (label.type === '<unk>') {
            error = true;
          } else {
            error = Boolean(errors.find((errorWord) => errorWord.textIndex === textIndex));
          }

          let pronunciationChange = false;
          if (label.type === 'PC') {
            pronunciationChange = true;
          }

          // Get the word to update;
          const originalWordIndex = originalWords.findIndex((orginalWord) => orginalWord.textIndex === textIndex);
          // Update the word;
          originalWords[originalWordIndex] = {
            ...originalWords[originalWordIndex],
            showFeedback: true,
            feedback: true,
            error,
            errorIndex,
            pronunciationChange,
            clipping,
          };
        });
      }

      this.setState({
        words: originalWords,
      });
    }
  }

  extractSentencesFromWords() {
    const acc = {
      1: [],
    };

    return this.state.words.reduce((total, word) => {
      const sentences = { ...total };

      const sentenceId = Object.keys(sentences).length;
      sentences[sentenceId].push(word);

      if (word.text.includes('[lf]')) {
        // Add the next sentence array!
        sentences[sentenceId + 1] = [];
      }

      return sentences;
    }, acc);
  }

  render() {
    const { fontSize, fontWeight, lineHeight } = this.props.metadata;

    const { words } = this.state;
    const { playAlong } = this.props;
    let sentences = {};

    if (words.length > 0) {
      sentences = this.extractSentencesFromWords();
    }

    return (
      <Text
        fontSize={fontSize}
        fontWeight={fontWeight}
        lineHeight={lineHeight}
      >
        {!words.length ? (
          <LoadingIndicator />
        )
          : (
            <Lines
              sentences={sentences}
              startIndicatorEnabled={!playAlong}
            />
          )}
      </Text>
    );
  }
}

SubRipText.propTypes = {
  metadata: PropTypes.shape({
    fontSize: PropTypes.string.isRequired,
    fontWeight: PropTypes.string.isRequired,
    lineHeight: PropTypes.string.isRequired,
    pageWidth: PropTypes.string.isRequired,
  }).isRequired,
  feedbackModelEnabled: PropTypes.bool,
  words: PropTypes.array.isRequired,
  firstWordPerSentence: PropTypes.array.isRequired,
  numberOfWords: PropTypes.number.isRequired,
  playAlong: PropTypes.bool.isRequired,
  currentTime: PropTypes.number.isRequired,
  startAtWord: PropTypes.number.isRequired,
  feedbackOnWords: PropTypes.arrayOf(PropTypes.shape({
    type: PropTypes.string,
    word: PropTypes.string,
    textIndex: PropTypes.number,
    confidence: PropTypes.number,
  })),
  showStartIndicator: PropTypes.bool,
};

SubRipText.defaultProps = {
  showStartIndicator: false,
  feedbackModelEnabled: null,
};

SubRipText.contextType = DebugContext;

// container part below
const mapStateToProps = ({ ReadingAssignment: { activePage: { startAtWord } } }) => ({
  startAtWord,
});

export default connect(mapStateToProps)(SubRipText);
