import React, { Component } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import axios from 'axios';
import { audioPlayer } from '../shared/itslanguage';
import Button from './Buttons';

const ControlGroup = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
`;

const SPEED_1 = 0.8;
const SPEED_2 = 1;
const SPEED_3 = 1.2;
const DEFAULT_SPEED = SPEED_2;

export default class ReadingControls extends Component {
  static propTypes = {
    audioUrl: PropTypes.string,
  };

  constructor(props) {
    super(props);

    this.startPlayer = this.startPlayer.bind(this);
    this.pausePlayer = this.pausePlayer.bind(this);
    this.stopPlayer = this.stopPlayer.bind(this);
    this.setPlaybackRate = this.setPlaybackRate.bind(this);
    this.toggleMute = this.toggleMute.bind(this);

    this.state = {
      isPlaying: false,
      isMuted: false,
      playbackRate: DEFAULT_SPEED,
    };

    this.loadPlayer();
  }

  componentDidUpdate(prevProps) {
    const { audioUrl: oldAudioUrl } = prevProps;
    const { audioUrl: newAudioUrl } = this.props;

    if (oldAudioUrl !== newAudioUrl) {
      audioPlayer.src = newAudioUrl;
    }
  }

  componentWillUnmount() {
    audioPlayer.load();
    audioPlayer.removeEventListener('ended', this.stopPlayer);
  }

  setPlaybackRate() {
    const { playbackRate } = this.state;
    let newRate = DEFAULT_SPEED;

    switch (playbackRate) {
      case SPEED_1:
        newRate = SPEED_2;
        break;
      case SPEED_2:
        newRate = SPEED_3;
        break;
      case SPEED_3:
        newRate = SPEED_1;
        break;
      default:
    }

    this.setState({ playbackRate: newRate });
    audioPlayer.playbackRate = newRate;
  }

  startPlayer() {
    this.setState({ isPlaying: true });
    audioPlayer.play();
  }

  pausePlayer() {
    this.setState({ isPlaying: false });
    audioPlayer.pause();
  }

  stopPlayer() {
    this.setState({ isPlaying: false });
    audioPlayer.load();
  }

  loadPlayer() {
    axios({
      method: 'get',
      url: this.props.audioUrl,
      responseType: 'blob',
    }).then((result) => {
      audioPlayer.src = URL.createObjectURL(result.data);
      audioPlayer.playbackRate = this.state.playbackRate;
      audioPlayer.addEventListener('ended', this.stopPlayer);
    });
  }

  toggleMute() {
    const mute = this.state.isMuted;
    this.setState({ isMuted: !mute });
    audioPlayer.muted = !mute;
  }

  render() {
    return (
      <ControlGroup>
        <Button control="play" onClick={this.startPlayer} hidden={this.state.isPlaying} />
        <Button control="pause" onClick={this.pausePlayer} hidden={!this.state.isPlaying} />
        <Button control="speed-1" onClick={this.setPlaybackRate} hidden={!(this.state.playbackRate === SPEED_1)} />
        <Button control="speed-2" onClick={this.setPlaybackRate} hidden={!(this.state.playbackRate === SPEED_2)} />
        <Button control="speed-3" onClick={this.setPlaybackRate} hidden={!(this.state.playbackRate === SPEED_3)} />
        <Button control="mute" onClick={this.toggleMute} hidden={!this.state.isMuted} />
        <Button control="unmute" onClick={this.toggleMute} hidden={this.state.isMuted} />
      </ControlGroup>
    );
  }
}
