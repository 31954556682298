import React from 'react';

import Container from './Container';
import Lines from './Lines';
import Line from './Line';
import Image from './Image';
import Button from './Button';

function ModalMessage(props) {
  const {
    imageSrc,
    lines,
    buttonIcon,
    onClose,
    buttonText,
  } = props;

  return (
    <Container>
      { lines
      && (
      <Lines>
        {
          // eslint-disable-next-line react/no-array-index-key
          lines.map((line, index) => (line ? (
            <Line key={`message-${index}`}>{line}</Line>
          ) : (
            <br key={`message-${index}`} />
          )))
        }
      </Lines>
      )}

      { imageSrc && <Image src={imageSrc} alt="blabal" />}

      { buttonText && <Button icon={buttonIcon} onClick={onClose}>{buttonText}</Button> }
    </Container>
  );
}

export default ModalMessage;
