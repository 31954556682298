import React from 'react';
import PropTypes from 'prop-types';

import { OverviewBody, Cell, Row } from './shared';
import AssignmentAudio from './AssignmentAudio';
import { displayName } from './utils';

const AssignmentBody = ({ assignments }) => (
  <OverviewBody>
    { assignments.map(({ user, recordings }) => (
      <Row key={user.id}>
        <Cell>
          {displayName(user)}
        </Cell>
        { recordings.map(({ id }) => (
          <Cell key={`${user.id}_${id}`}>
            <AssignmentAudio audioUrl={id && `${process.env.REACT_APP_TUTO_API_URL}/recording/${id}/attempt/${localStorage.getItem('token')}`} />
          </Cell>
        ))}
      </Row>
    ))}
  </OverviewBody>
);

AssignmentBody.propTypes = {
  assignments: PropTypes.array.isRequired,
};

export default AssignmentBody;
