import React from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { getTenantId } from '../shared/itslanguage';
import malmbergMock from './mock.png';

const Header = styled.header`
  background-color: #153952;
  color: #fff;
  height: 167px;
  text-align: center;
  width: 100%;
`;

const Img = styled.img`
  height: 42px;
  margin-top: 30px;
`;

const MockImage = styled.img`
  height: ${(props) => (props.bigger ? '240.09' : '167.91')}px;
  margin-top: ${(props) => (props.bigger ? '97.47' : '13.74')}px;
`;

const Subtitle = styled.h1`
  font-size: 0.722rem;
  font-weight: normal;
  line-height: 2rem;
  margin: 0;
  padding: 0;
`;

export default (props) => {
  const tenantId = getTenantId();
  const logo = `${process.env.PUBLIC_URL}/${tenantId}/logo.png`;
  const { t } = useTranslation();

  return (
    <Header>
      <Img src={logo} alt={t('login.logo')} />
      <Subtitle>{t('login.subtitle')}</Subtitle>
      <MockImage {...props} src={malmbergMock} alt="Product mockup" />
    </Header>
  );
};
