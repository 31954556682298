import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import LoginHeader from './LoginHeader';
import navBack from './nav-back.png';
import teacherIcon from './teacher.png';
import studentIcon from './student.png';
import LoginForm from './LoginForm';

const Section = styled.section`
  display: flex;
  flex-direction: row;
  flex: 1;
  justify-content: center;
`;

const Part = styled.div`
  background: ${(props) => (props.primary ? '#153952' : '#fff')};
  color: ${(props) => (props.primary ? '#fff' : 'inherit')};
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: flex-start;
  padding: 1.667rem;
`;

const BackLink = styled(Link)`
  font-size: 0.722rem;
  font-weight: normal;
  margin-left: 1.75rem;
  text-decoration: underline;

  &::before {
    background-image: url(${navBack});
    background-size: 1.5rem 1.5rem;
    background-repeat: no-repeat;
    content: ' ';
    height: 1.5rem;
    margin-top: -0.25rem;
    margin-left: -1.75rem;
    position: absolute;
    width: 1.5rem;
  }
`;

const UserHeader = styled.h1`
  font-size: 1.111rem;
  font-weight: normal;
  margin-left: 3.833rem;

  &::first-letter {
    text-transform: uppercase;
  }

  &::before {
    background-image: url(${(props) => props.userType});
    background-size: 2.5rem 2.5rem;
    background-repeat: no-repeat;
    content: ' ';
    height: 2.5rem;
    margin-top: -0.5rem;
    margin-left: -3.833rem;
    position: absolute;
    width: 2.5rem;
  }
`;

const LoginSection = styled.section`
  padding: 3.611rem 3.888rem;
`;

const userIcon = {
  teacher: teacherIcon,
  student: studentIcon,
};

const LoginUser = (props) => {
  const { userType, orgId } = props.params;
  const { t } = useTranslation();

  return (
    <Section>
      <Part primary>
        <BackLink to="/login">{t('login.back')}</BackLink>
        <LoginHeader bigger />
      </Part>
      <Part>
        <LoginSection>
          <header>
            <UserHeader userType={userIcon[userType]}>{t(`login.${userType}`)}</UserHeader>
          </header>
          <LoginForm userType={userType} orgId={orgId} />
        </LoginSection>
      </Part>
    </Section>
  );
};

LoginUser.propTypes = {
  params: PropTypes.shape({
    userType: PropTypes.string,
    orgId: PropTypes.string,
  }).isRequired,
};

export default LoginUser;
