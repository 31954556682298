import React from 'react';
import PropTypes from 'prop-types';

import doneIcon from './progress-done.png';
import progressIcon from './progress.png';
import {
  OverviewBody, Cell, Img, Row,
} from './shared';
import { displayName } from './utils';

const ProgressBody = ({ progress }) => (
  <OverviewBody>
    { progress.map((child) => (
      <Row key={child.user.id}>
        <Cell>
          {displayName(child.user)}
        </Cell>
        { child.progress.map(({ percentage, category }) => {
          const percentageNumber = Number(percentage);

          return (
            <Cell key={`${child.user.id}_${category.id}`}>
              {/* Only render the progress image if there is progress to render. */}
              { percentageNumber > 0
                && (
                <Img
                  src={percentageNumber === 100 ? doneIcon : progressIcon}
                  alt={`Voorgang: ${percentageNumber}%`}
                />
                )}
            </Cell>
          );
        })}
      </Row>
    ))}
  </OverviewBody>
);

ProgressBody.propTypes = {
  progress: PropTypes.array.isRequired,
};

export default ProgressBody;
