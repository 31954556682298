import styled from 'styled-components';

const Message = styled.div`
  background-color: #e96a6a;
  border: 1px solid #d4213f;
  color: #fff;
  padding: .5rem;
  position: absolute;
  text-align: center;
  top: 43px;
  left: calc(50% - 175px);
  width: 350px;
`;

export default Message;
