import styled from 'styled-components';
import PropTypes from 'prop-types';

const Text = styled.div`
  font-family: "Roboto";
  font-weight: ${(props) => props.fontWeight};
  font-size: ${(props) => props.fontSize};
  line-height: ${(props) => props.lineHeight};
  margin: 0;
`;

Text.propTypes = {
  fontWeight: PropTypes.string,
  fontSize: PropTypes.string,
  lineHeight: PropTypes.string,
};

Text.defaultProps = {
  fontWeight: '400',
  fontSize: '20px',
  lineHeight: '30px',
};

export default Text;
