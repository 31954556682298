import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Link } from 'react-router';
import { verticalCenter, truncate, lighten } from '../shared/styles';

const defaultBgColor = '#d3d3d3';

const ThemeItem = styled(Link)`
  background-color: ${(props) => (props.color ? props.color : defaultBgColor)};
  height: 160px;
  margin: 5px;
  padding: 15px;
  position: relative;
  width: 220px;

  &::before {
    background-color: #969593;
    content: '';
    display: block;
    height: 14px;
    left: 10px;
    position: absolute;
    top: -14px;
    width: 200px;
    transform: perspective(200) rotateX(30deg);
    transform-origin: 50% 100%;
  }

  &:hover {
    transform: scale(1.08);
    z-index: 99;
  }
`;

const Description = styled.span`
  ${verticalCenter()}
  background-color: ${(props) => lighten(props.color ? props.color : defaultBgColor, 0.3)};
  display: inline-block;
  height: 57px;
  overflow: hidden;
  padding: 10px;
  width: 100%;
`;

const Title = styled.h2`
  ${truncate()}
  font-size: 0.778rem;
  font-weight: bold;
  margin: 0;
  padding: 0;
`;

const SubTitle = styled.span`
  ${truncate()}
  font-size: 0.772rem;
  margin: 0;
  padding: 0;
`;

const Postit = styled.span`
  ${verticalCenter()}
  background-color: #fff;
  bottom: -18px;
  border: 1px solid rgb(208, 206, 206);
  box-shadow:
    1px 1px 0 rgba(0, 0, 0, 0.1),
    3px 3px 0 rgba(208, 206, 206, 1);
  font-size: 2rem;
  font-weight: bold;
  height: 60px;
  position: absolute;
  right: 22px;
  transform: rotate(-5deg);
  width: 60px;
  z-index: 2;

  &::before {
    background-color: rgba(179, 171, 159, 0.5);
    content: '';
    height: 15px;
    position: absolute;
    right: -8px;
    top: -8px;
    transform: rotate(10deg);
    width: 50px;
  }
`;

const Icon = styled.img`
  height: 100%;
  width: 100%;
`;

const Category = ({
  to, name, description, color, icon, onClick,
}) => (
  <ThemeItem color={color} to={to} onClick={onClick}>
    <Description color={color}>
      <Title>{ name }</Title>
      { description
      && <SubTitle>{ description }</SubTitle>}
    </Description>
    <Postit>
      { icon
      && <Icon src={icon} alt={name} />}
    </Postit>
  </ThemeItem>
);

Category.propTypes = {
  to: PropTypes.oneOfType([PropTypes.string, PropTypes.object]).isRequired,
  name: PropTypes.string.isRequired,
  onClick: PropTypes.func,
  description: PropTypes.string,
  color: PropTypes.string,
  icon: PropTypes.string,
};

export default Category;
