import React, { Component } from 'react';
import PropTypes from 'prop-types';
import debug from 'debug';
import { connect } from 'react-redux';
import { Howl } from 'howler';

import {
  establishConnection, cleanup,
} from '@itslanguage/websocket';
import axios from 'axios';
import { withTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import {
  updateSpeechChallengeRecording,
  updateFeedbackOnWords,
  resetFeedbackOnWords,
  prepareRecording,
  startRecording,
  stopRecording,
  pauseRecording,
  showConnectionErrorMessage,
} from './ReadingAssignmentActions';
import {
  audioPlayer,
  getRecorder,
  secureLink,
  groupSequenceWords,
  filterTargetWordIndexes,
} from '../shared/itslanguage';
import { wait } from '../shared/utils';
import { playAudio, playRandomAudioId } from '../shared/audio';
import { assessSentence } from '../shared/feedback';
import ButtonGroup from '../components/ButtonGroup';
import Balloon from '../components/Balloon';
import Button from './Buttons';
import Quiet from '../components/Quiet';
import AudioScrubber from '../audio/AudioScrubber';
import feedbackModelSounds from '../audiofiles/feedback';
import encouragingSounds from '../audiofiles/encouraging';
import wellDoneSounds from '../audiofiles/wellDone';

const logger = debug('its-tuto:logs');

export class LegacyRecordingControls extends Component {
  static propTypes = {
    feedbackModelEnabled: PropTypes.bool.isRequired,
    feedbackAudioUrl: PropTypes.string,
    activePage: PropTypes.shape({
      speechChallenge: PropTypes.shape({
        id: PropTypes.string.isRequired,
      }).isRequired,
      speechChallegeRecording: PropTypes.shape({
        audioUrl: PropTypes.string,
      }),
      feedbackOnWords: PropTypes.array,
    }).isRequired,
    prepareRecording: PropTypes.func.isRequired,
    startRecording: PropTypes.func.isRequired,
    stopRecording: PropTypes.func.isRequired,
    pauseRecording: PropTypes.func.isRequired,
    updateFeedbackOnWords: PropTypes.func.isRequired,
    updateSpeechChallengeRecording: PropTypes.func.isRequired,
    words: PropTypes.array,
    numberOfWords: PropTypes.number,
  };

  constructor(props) {
    super(props);

    // Prep some props;
    this.animationLoop = null;
    this.audioScrubberRef = null;
    this.startAudio = null;
    this.recorder = null;
    this.socket = null;
    this.previous_recording = null;
    this.chunks = [];

    // Prepare scoping;
    this.handleRecorderAmplitude = this.handleRecorderAmplitude.bind(this);
    this.handleRecorderRecording = this.handleRecorderRecording.bind(this);
    this.handleRecorderRecorded = this.handleRecorderRecorded.bind(this);
    this.collectRecorderData = this.collectRecorderData.bind(this);
    this.enableRecordingButton = this.enableRecordingButton.bind(this);
    this.disableRecordingButton = this.disableRecordingButton.bind(this);
    this.prepareRecordingHandler = this.prepareRecordingHandler.bind(this);
    this.startRecording = this.startRecording.bind(this);
    this.stopRecording = this.stopRecording.bind(this);
    this.clickStopRecording = this.clickStopRecording.bind(this);
    this.startPlayer = this.startPlayer.bind(this);
    this.pausePlayer = this.pausePlayer.bind(this);
    this.stopPlayer = this.stopPlayer.bind(this);
    this.resumeRecording = this.resumeRecording.bind(this);
    this.loadPlayerEnded = this.loadPlayerEnded.bind(this);
    this.playerTimeUpdate = this.playerTimeUpdate.bind(this);
    this.cancelLoop = this.cancelLoop.bind(this);

    // Prepare state;
    this.state = {
      isRecording: false,
      isRecordingPaused: false,
      isPlaying: false,
      hasRecording: false,
      hasRecorded: false,
      canRecord: true,
      processRecord: false,
      loadingPostponed: false,
      isLoading: false,
      repeatSentence: true,
      volume: 0,
      percentage: 0,
      current: 0,
      duration: 0,
      feedbackId: null,
      numberOfMistakes: 0,
      mistakesSinceLastError: 0,
      numberOfTargetWordMistakes: 0,
      repeatedSentence: 0,
      hasSentenceBeenRepeated: false,
      targetRulesPlayed: [],
      currentRecordingId: 0,
      previousWordIndex: -1,
      feedbackQueue: [],
      visualFeedbackOnWords: [],
      actualFeedbackOnWords: [],
    };

    this.initPlayer();
  }

  initPlayer() {
    audioPlayer.addEventListener('ended', this.stopPlayer);
    audioPlayer.addEventListener('play', this.playerTimeUpdate);
  }

  componentDidMount() {
    const { activePage: { speechChallengeRecording } } = this.props;
    if (speechChallengeRecording) {
      this.getRecordings(speechChallengeRecording);
    }
    this.prepareRecordingHandler();
  }

  componentDidUpdate(prevProps) {
    const { activePage: { speechChallengeRecording: oldRecording } } = prevProps;
    const { activePage: { speechChallengeRecording: newRecording } } = this.props;

    if (oldRecording !== newRecording
      && (newRecording && newRecording.combinedAudioUrl)) {
      this.getRecordings(newRecording);
    }
  }

  componentWillUnmount() {
    this.stopRecording();
    // If the player exists, unload!
    if (audioPlayer) {
      audioPlayer.load();
      audioPlayer.removeEventListener('canplay', this.loadPlayerEnded);
      audioPlayer.removeEventListener('ended', this.stopPlayer);
      audioPlayer.removeEventListener('play', this.playerTimeUpdate);
    }

    // If the recorder exists, unload!
    if (this.recorder) {
      cleanup();
      this.recorder.removeEventListener('start', this.handleRecorderRecording);
      this.recorder.removeEventListener('stop', this.handleRecorderRecorded);
      this.recorder.removeEventListener('dataavailable', this.collectRecorderData);
      this.recorder.removeEventListener('recorderready', this.enableRecordingButton);
      this.recorder.removeEventListener('amplitudelevels', this.handleRecorderAmplitude);
    }
    this.props.resetFeedbackOnWords();
  }

  async initRecorder() {
    if (this.recorder) {
      cleanup();
    }
    this.recorder = await getRecorder();
    logger('Recorder is ready!');
    // @todo: get and log sampleRate of the recorder;
    //   logger(`Your microphone sample rate is: ${audioContext.sampleRate}kHz`);
    //   logger('Note that the sample rate is not send to the backend in this version of its-tuto');

    this.recorder.addEventListener('start', this.handleRecorderRecording);
    this.recorder.addEventListener('stop', this.handleRecorderRecorded);
    this.recorder.addEventListener('dataavailable', this.collectRecorderData);
    this.recorder.addEventListener('recorderready', this.enableRecordingButton);
    this.recorder.addEventListener('amplitudelevels', this.handleRecorderAmplitude);
  }

  collectRecorderData({ data }) {
    this.chunks.push(data);
  }

  getRecordings(resultSet) {
    // Get the combinedAudioUrl. Should be part of the resultSet.
    const { combinedAudioUrl = null } = resultSet;
    if (combinedAudioUrl) {
      this.loadPlayer(secureLink(combinedAudioUrl));
      this.setState({ hasRecording: true });
    }
  }

  handleRecorderAmplitude({ data }) {
    if (data.volume === 0 && this.state.isRecording) {
      const { t } = this.props;
      this.setState({ isRecording: false });
      toast.error(t('reading.microphone.error.volume'));
      this.endRecording();
    }
    // Volume level will be a value between 0.00 and 1.00
    const volume = Math.min(
      Math.max(parseInt(data.volume * 30 * 64, 10), 4),
      24,
    );

    this.setState(() => ({ volume }));
  }

  handleRecorderRecording() {
    logger('Recording of audiofiles is started');
  }

  handleRecorderRecorded() {
    // todo, composing the following blob works fine for opus, but not for wav.
    const blob = new Blob(this.chunks, { type: this.recorder.mimeType });
    logger('Recording is done, this is the audiofiles blob', blob);

    if (this.state.hasRecorded) {
      if (this.recorder.mimeType !== 'audio/wav') {
        this.loadPlayer(URL.createObjectURL(blob));
      }
      this.setState({ isRecording: false, processRecord: true });
    } else {
      this.setState({ isRecording: false });
    }
  }

  enableRecordingButton() {
    this.setState({ canRecord: true });
  }

  disableRecordingButton() {
    this.setState({ canRecord: true });
  }

  toastMistake(feedbackWords) {
    const { t } = this.props;
    const errors = feedbackWords[1][0];
    const showWordErrorToast = false;
    const options = {
      position: 'top-center',
      autoClose: 1500,
    };

    if (errors[0].clipping) {
      options.autoClose = 5000;
      options.enableHtml = true;

      const clippingMsg = () => (
        <Quiet />
      );
      toast.error(clippingMsg, options);
    } else if (showWordErrorToast && errors[0].type === 'PC') {
      toast.error(`${t('reading.error.phonetic_change_word_1')}${errors[0].word}${t('reading.error.phonetic_change_word_2')}`, options);
    } else if (showWordErrorToast) {
      toast.error(`${t('reading.error.omission_word_1')}${errors[0].word}${t('reading.error.omission_word_2')}`, options);
    }
  }

  toastError(error) {
    const { t } = this.props;
    const options = {
      position: 'top-center',
      autoClose: 5000,
      enableHtml: true,
    };

    const ErrorMsg = () => (
      <div>
        <h3>{t('reading.error.toast')}</h3>
        <br />
        <b>{t('reading.error.toast_reason')}</b>
        <br />
        {error}
      </div>
    );

    toast.error(ErrorMsg, options);
  }

  // Called when feedback comes in and processes it
  processFeedback(result) {
    if (this.state.isRecordingPaused) {
      logger('recording is paused. Will return');
      return;
    }
    const { feedbackModelEnabled } = this.props;

    // The result is a JSON object as string, so parse it here.
    let feedbackResult = JSON.parse(result).labels;
    const { activePage } = this.props;

    const { feedbackOnWords } = activePage;
    const { words } = this.props;

    // All previous feedback should no longer affect the feedback model
    feedbackOnWords.forEach((word) => {
      word.type = 'CW'; // eslint-disable-line no-param-reassign
    });

    // Add sentence to the feedback result.
    let highestSentence = 0;
    let lastSentence = 0;
    feedbackResult.forEach((part, index, arr) => {
      words.forEach((word) => {
        if (arr[index].textIndex === word.textIndex) {
          arr[index].sentence = word.sentence; // eslint-disable-line no-param-reassign

          if (word.sentence > highestSentence) {
            highestSentence = word.sentence;
          }
        }
        lastSentence = word.sentence;
      });
    });

    logger('Got feedback from backend:', feedbackResult);

    const newFeedbackResult = [];
    feedbackResult.forEach((result) => {
      if ((result.sentence < highestSentence)) {
        this.state.feedbackQueue.push(result);
      } else {
        newFeedbackResult.push(result);
      }
    });
    feedbackResult = newFeedbackResult;

    let queueFeedback = true;
    let skipQueue = false;
    let newFeedback = [];
    // If the sentence of the incoming feedback is higher
    // then we can proceed with processing the feedback
    const queuedFeedback = this.state.feedbackQueue.concat(feedbackResult);
    queuedFeedback.forEach((result) => {
      feedbackResult.forEach((incomingResult) => {
        if (result.sentence < incomingResult.sentence) {
          queueFeedback = false;
        }
        if (incomingResult.sentence === lastSentence) {
          skipQueue = true;
        }
      });
    });
    // skipQueue on the last sentence and directly update the feedback on words
    if (skipQueue) {
      feedbackResult = this.state.feedbackQueue.concat(feedbackResult);
      this.setState({
        feedbackQueue: [],
        actualFeedbackOnWords: this.state.actualFeedbackOnWords.concat(feedbackResult),
      });
      newFeedback = this.state.actualFeedbackOnWords;
    } else if (queueFeedback) {
      const queue = this.state.feedbackQueue.concat(feedbackResult);
      this.setState({
        feedbackQueue: JSON.parse(JSON.stringify(queue)),
      });
      // Make a cosmetic feedback on words that will show the progress
      feedbackResult = queue;
      feedbackResult.forEach((result) => {
        result.type = 'CW'; // eslint-disable-line no-param-reassign
      });
      this.setState({
        visualFeedbackOnWords: this.state.actualFeedbackOnWords.concat(feedbackResult),
      });
      newFeedback = this.state.visualFeedbackOnWords;
    } else {
      const newQueue = feedbackResult;
      feedbackResult = this.state.feedbackQueue;
      this.setState({
        feedbackQueue: newQueue,
        actualFeedbackOnWords: this.state.actualFeedbackOnWords.concat(feedbackResult),
      });
      newFeedback = this.state.actualFeedbackOnWords;
    }

    let latestWordIndex = 0;
    newFeedback.forEach((label) => {
      if ((label.type === 'OW' || label.type === 'PC')
          && label.textIndex > latestWordIndex) {
        // Truncate to omit unk labels.
        latestWordIndex = Math.trunc(label.textIndex);
      }
    });
    // Check if we processed this error before;
    const hadFeedbackBefore = this.state.previousWordIndex === latestWordIndex;

    // If we had feedback before ignore errors by setting everything to CW
    if (hadFeedbackBefore) {
      newFeedback.forEach((label) => {
        label.type = 'CW'; // eslint-disable-line no-param-reassign
      });
    }

    this.setState({ previousWordIndex: latestWordIndex });

    this.props.resetFeedbackOnWords();
    this.props.updateFeedbackOnWords(newFeedback);

    const feedbackWords = assessSentence(feedbackResult);
    const mistakes = feedbackWords[0];

    // Update mistakes for this try!
    this.setState(() => ({ mistakesSinceLastError: mistakes }));
    logger(`Number of mistakes considered found: ${mistakes}`);
    if (mistakes > 0) {
      // Update total mistakes in RecordingControls
      // Used to determine the type of feedback to give
      this.setState((prevState) => ({
        numberOfMistakes: prevState.numberOfMistakes + 1,
      }));
      // Update total mistakes in ReadingAssignent
      // Shown to user at the end.
      this.props.handleMistake();
    }

    if (!feedbackModelEnabled) {
      // Stop after the last sentence as soon as we know we hit that!
      if ((mistakes === 0)
          && (feedbackResult[feedbackResult.length - 1] && feedbackResult[feedbackResult.length - 1].textIndex) === this.props.numberOfWords) {
        this.stopRecording();
      }
      return; // We don't have to continue now; the feedback model is disabled!
    }

    if (mistakes > 0) {
      this.pauseRecording();

      // Notify the user depending on the mistake
      this.toastMistake(feedbackWords);

      const { sentence } = feedbackResult.find((label) => (label.type === 'OW' || label.type === 'PC'));
      // The feedback Model
      // Replay the faulty words: 1 or 2 mistakes
      // Replay sentence repeat: 3 or more mistakes
      const { i18n } = this.props;
      if (feedbackWords[0] > 2) {
        feedbackModelSounds[i18n.languages[0]].play('interrupt');
        this.replaySentenceFeedback(sentence).then(() => {
          this.fixSentenceError(true);
          this.resumeRecording(sentence);
        });
      } else if (feedbackWords[0] > 0) {
        feedbackModelSounds[i18n.languages[0]].play('interrupt');
        this.playWordFeedback(feedbackWords[2]).then(() => {
          this.fixSentenceError();
          this.resumeRecording(sentence + 1);
        });
      }
    }

    // Stop after the last sentence as soon as we know we hit that!
    if ((mistakes === 0)
        && feedbackResult[feedbackResult.length - 1].textIndex === this.props.numberOfWords) {
      this.setState({ feedbackQueue: [] });
      this.stopRecording();
    }
  }

  fixSentenceError(sameSentence = false) {
    const { activePage: { feedbackOnWords } } = this.props;

    const error = feedbackOnWords.find((label) => (label.type === 'OW' || label.type === 'PC'));

    let newFeedback = feedbackOnWords.filter((item) => item.sentence <= error.sentence);
    if (sameSentence) {
      newFeedback = feedbackOnWords.filter((item) => item.sentence <= error.sentence - 1);
    }

    this.props.resetFeedbackOnWords();
    this.props.updateFeedbackOnWords(newFeedback);
    this.setState({
      feedbackQueue: [],
      visualFeedbackOnWords: newFeedback,
      actualFeedbackOnWords: newFeedback,
    });
  }

  endRecording(recordingId) {
    const { feedbackModelEnabled } = this.props;
    const { activePage } = this.props;
    const { feedbackOnWords } = activePage;
    const { i18n } = this.props;

    if (this.state.isRecording) {
      logger('Stopping recorder');
      this.recorder.stop();
      // Also cleanup socket
      cleanup();
    }

    if (!recordingId) {
      this.setState({
        isRecording: false, processRecord: false, loadingPostponed: '',
      });
      return;
    }

    const { REACT_APP_TUTO_API_URL } = process.env;

    const recording_data = {
      its_api_id: recordingId.recordingId,
      prompt_id: activePage.speechChallenge.id,
    };

    if (this.previous_recording) {
      recording_data.previous_recording_id = this.previous_recording.id;
    }

    axios({
      method: 'post',
      url: `${REACT_APP_TUTO_API_URL}/recording`,
      data: recording_data,
    }).then((response) => {
      this.previous_recording = response.data;
      this.setState({ currentRecordingId: response.data.id });

      if (!this.state.isPlaying) {
        this.setState({ isRecording: false, processRecord: false, hasRecording: true });
        this.props.updateSpeechChallengeRecording(response.data);
      } else {
        this.setState({
          isRecording: false, processRecord: false, loadingPostponed: '',
        });
      }
    });

    // We get here when we're done.
    if (!feedbackOnWords) {
      this.setState({
        isRecording: false, processRecord: false, loadingPostponed: '',
      });
      return;
    }

    let error = false;
    feedbackOnWords.forEach((label) => {
      if (label.type !== 'CW') {
        error = true;
      }
    });

    // Play encouragement or welldone now depending on the number of mistakes!
    // This depends on the last word being detected in the latest feedback.
    if (feedbackModelEnabled
        && feedbackOnWords && !error
        && this.props.numberOfWords === feedbackOnWords[feedbackOnWords.length - 1].textIndex) {
      if (this.state.numberOfMistakes > 0) {
        playRandomAudioId(encouragingSounds[i18n.languages[0]]);
      } else {
        playRandomAudioId(wellDoneSounds[i18n.languages[0]]);
      }
      wait(2500).then(() => {
        this.props.nextPage();
      });
    }
  }

  prepareRecordingHandler() {
    this.setState({
      isRecording: false, isRecordingPaused: false, hasRecorded: false, processRecord: true,
    });
    this.prepareRecording();
  }

  /* eslint-disable no-unused-vars */
  async prepareRecording(textIndex = 0) {
    const { i18n } = this.props;
    if (!this.state.isRecording) {
      const { words } = this.props;

      // If the recording is paused we want to resume from the old feedback
      if (!this.state.isRecordingPaused && this.state.hasRecorded) {
        this.props.resetFeedbackOnWords();
      }

      // This handles the starting indicator
      this.props.prepareRecording(textIndex);

      const speechChallengeId = this.props.activePage.speechChallenge.id;
      const { REACT_APP_TUTO_API_URL } = process.env;
      const { REACT_APP_TUTO_ITS_API_URL } = process.env;

      const prompt_response = await axios({
        method: 'get',
        url: `${REACT_APP_TUTO_API_URL}/prompt/${speechChallengeId}`,
      });
      const prompt = prompt_response.data;

      const response = await axios({
        method: 'get',
        url: `${REACT_APP_TUTO_API_URL}/wstoken`,
      });

      await this.initRecorder();

      this.socket = establishConnection(
        response.data,
        `${REACT_APP_TUTO_ITS_API_URL}/prompt`,
        this.recorder,
        (feedback) => {
          logger(feedback);
          this.processFeedback(feedback);
        },
        (rec) => {
          logger(rec);
          this.endRecording(rec);
        },
      );
      this.socket.on('error', (error) => {
        this.toastError(error);
        // Reset state as it is probably not correct.
        this.setState({
          isRecording: false, isRecordingPaused: false, hasRecorded: false, processRecord: false,
        });
      });
      prompt.id = prompt.its_api_id;
      let startingPoint = textIndex - 1;
      if (startingPoint < 0) {
        startingPoint = 0;
      }
      // Catch potential exception when running in cypress
      try {
        this.recorder.start(1000);
        await this.socket.emit('start_recording', {
          text: prompt.srt,
          language: prompt.language,
          age_group: 'adult',
          prompt_id: prompt.id,
          text_index: startingPoint,
        });
        await playAudio(feedbackModelSounds[i18n.languages[0]], 'start');
      } catch (e) {
        logger(e);
      }

      this.startRecording();
    }
  }
  /* eslint-enable no-unused-vars */

  startRecording() {
    this.setState({ isRecording: true, isRecordingPaused: false, hasRecorded: false });
    logger('starting recorder');
    this.props.startRecording();
  }

  stopRecording() {
    this.props.stopRecording();
    logger('stopping recorder');
    this.recorder.stop();
    wait(500);
    this.setState({ isRecording: false, hasRecorded: true });
    this.socket.emit('end_recording');
  }

  pauseRecording() {
    logger('pausing recorder');
    this.recorder.pause();
    this.setState({ isRecording: false, isRecordingPaused: true });
    this.props.pauseRecording();
    // Pause will stop the backend. This will trigger the end of recording callback.
    // After which the frontend will check if the recording is paused. If is it will
    // 'resume' the prompt by starting again.
    this.stopRecording();
  }

  resumeRecording(startingSentence) {
    const { words } = this.props;

    // Remove the callbacks
    stopRecording();
    cleanup();

    const filteredWords = words.filter((word) => word.textIndex % 1 === 0);
    const startingWord = filteredWords.find((word) => word.sentence === startingSentence);

    if (startingWord === undefined) {
      this.setState({ feedbackQueue: [] });
      this.props.nextPage();
      return;
    }

    const startingPoint = startingWord.textIndex;

    logger('Resumeing (starting) recorder');
    this.prepareRecording(startingPoint).then(() => {
      this.setState({ isRecording: true, isRecordingPaused: false });
    });
  }

  async playWordFeedback(feedbackWords) {
    this.setState({ isRecording: false });

    const { words } = this.props;
    const targetWordTextIndexes = filterTargetWordIndexes(words);
    const ease = 20; // add some ease to the playback, in ms.

    // Filter and group the received feedback words;
    const groupedFeedbackWords = groupSequenceWords(
      feedbackWords,
      targetWordTextIndexes,
    );

    // Compose a sprite for all the captured feedback groups;
    const sprite = groupedFeedbackWords.reduce((result, group, index) => {
      const localSprite = {};

      // Get the start time from the first word in the group;
      const { start } = words.find(({ textIndex }) => textIndex === group[0].textIndex);

      // Get the end time from the last word in the group (could be the first if there is just one);
      const { end } = words.find(({ textIndex }) => textIndex === group[group.length - 1].textIndex);

      // Calculate duration by subtracting the final point minus the start point;
      const duration = (end - start) - ease;

      localSprite[`group-${index}`] = [
        start,
        duration,
        false, // loop = false
      ];

      return {
        ...result,
        ...localSprite,
      };
    }, {});

    const result = await axios({
      method: 'get',
      url: this.props.feedbackAudioUrl,
      responseType: 'blob',
    });
    const url = URL.createObjectURL(result.data);

    // Create an Howl instance to playback the feedback.
    //
    // We use html: false to enable to more precise playback (i.e. it stops at the exact required
    // moment because it does not use setTimeout to stop, howlerjs internal).
    //
    // IMPORTANT: We download the source without proper mimetype. For that we are adding the
    // format. We rely on the source to be MP3. If on any point in time that would change this
    // will break.
    const feedbackAudio = new Howl({
      html5: false,
      format: ['mp3'],
      src: url,
      sprite,
    });

    for (let groupIndex = 0; groupIndex < groupedFeedbackWords.length; groupIndex += 1) {
      const { i18n } = this.props;
      const feedbackGroup = groupedFeedbackWords[groupIndex][0];
      const { targetWordIndex } = words.find(({ textIndex }) => textIndex === feedbackGroup.textIndex);

      // Playback the group sprite;
      await playAudio(feedbackAudio, `group-${groupIndex}`);

      // Wait a bit;
      await wait(500);

      if ((groupedFeedbackWords[groupIndex].length === 1
        && targetWordTextIndexes.includes(feedbackGroup.textIndex))
        && !this.state.targetRulesPlayed.includes(targetWordIndex)
      ) {
        this.setState((prevState) => ({
          numberOfTargetWordMistakes: prevState.numberOfTargetWordMistakes + 1,
        }));

        const { numberOfTargetWordMistakes } = this.state;
        // Skip playing target words if previously feedback was played on this page
        if (numberOfTargetWordMistakes > 1) {
          break;
        }

        // Play target rule;
        await playAudio(
          feedbackModelSounds[i18n.languages[0]],
          `tw-${targetWordIndex}`,
        );

        // Wait a bit more;
        await wait(500);

        // Play the reference audio again;
        await playAudio(feedbackAudio, `group-${groupIndex}`);

        // Final wait before continuation;
        await wait(500);

        this.setState((prevState) => (
          {
            ...prevState,
            targetRulesPlayed: [
              ...prevState.targetRulesPlayed,
              targetWordIndex,
            ],
          }
        ));
      }
    }
  }

  /**
   * Replay Sentence means: give notification (auditive) to the user to re-read the sentence.
   */
  async replaySentenceFeedback(sentence) {
    this.setState({ isRecording: false });

    const { words, i18n } = this.props;
    const ease = 20; // add some ease to the playback, in ms.

    const wordSentence = words
      .filter((word) => word.sentence === sentence)[0];

    const wordsInSentence = words
      .filter((word) => word.sentence === wordSentence.sentence)
      .filter((word) => word.textIndex % 1 === 0)
      .sort((wordA, wordB) => wordA.textIndex - wordB.textIndex);

    const start = wordsInSentence[0].start - ease;
    const feedbackAudioLength = (wordsInSentence[wordsInSentence.length - 1].end - start) + ease;

    const result = await axios({
      method: 'get',
      url: this.props.feedbackAudioUrl,
      responseType: 'blob',
    });
    const url = URL.createObjectURL(result.data);

    // Create an Howl instance to playback the feedback.
    //
    // We use html: false to enable to more precise playback (i.e. it stops at the exact required
    // moment because it does not use setTimeout to stop, howlerjs internal).
    //
    // IMPORTANT: We download the source without proper mimetype. For that we are adding the
    // format. We rely on the source to be MP3. If on any point in time that would change this
    // will break.
    const feedbackAudio = new Howl({
      html5: false,
      format: ['mp3'],
      src: url,
      sprite: {
        feedback: [start, feedbackAudioLength, false],
      },
    });

    // Use a little delay to play the feedback
    await wait(500);
    await playAudio(feedbackAudio, 'feedback');
    if (!this.state.hasSentenceBeenRepeated) {
      await wait(500);
      await playAudio(feedbackModelSounds[i18n.languages[0]], 'again');
    }
  }

  loadPlayer(url) {
    this.setState({ isLoading: true });
    // Unload previously event handlers first;
    audioPlayer.removeEventListener('canplay', this.loadPlayerEnded);

    // Load the URL on the player;
    audioPlayer.addEventListener('canplay', this.loadPlayerEnded);
    audioPlayer.src = url;
  }

  playerTimeUpdate() {
    const frameLoop = () => {
      if (this.audioScrubberRef && !this.audioScrubberRef.dragging) {
        const { duration } = this.state;
        const current = audioPlayer.currentTime;
        let percentage = 0;

        if (duration > 0) {
          percentage = (current * 100) / duration;
        }

        this.setState({
          percentage,
          current,
        }, () => {
          this.animationLoop = requestAnimationFrame(frameLoop);
        });
      }
    };

    frameLoop();
  }

  cancelLoop() {
    cancelAnimationFrame(this.animationLoop);
  }

  loadPlayerEnded() {
    const { duration } = audioPlayer;
    this.setState({
      duration,
    });
    this.setState({ isLoading: false });
  }

  startPlayer() {
    const audioUrl = `${process.env.REACT_APP_TUTO_API_URL}/recording/${this.state.currentRecordingId}/attempt/${localStorage.getItem('token')}`;
    this.loadPlayer(audioUrl);
    this.setState({ isPlaying: true });
    audioPlayer.play();
  }

  pausePlayer() {
    this.setState({ isPlaying: false });
    audioPlayer.pause();
  }

  stopPlayer() {
    audioPlayer.load();
    this.cancelLoop();

    if (this.state.loadingPostponed) {
      const { audioUrl } = this.state.loadingPostponed;
      this.loadPlayer(secureLink(audioUrl));
      this.props.updateSpeechChallengeRecording(this.state.loadingPostponed);
    }

    this.setState({ isPlaying: false, loadingPostponed: false });
  }

  clickStopRecording() {
    this.props.resetFeedbackOnWords();
    this.setState({ feedbackQueue: [] });
    this.stopRecording();
  }

  render() {
    return (
      <ButtonGroup>
        <Balloon hide={!this.state.hasRecording || !this.state.isPlaying}>
          <AudioScrubber
            ref={(component) => { this.audioScrubberRef = component; }}
            allowScrubbing={false}
            percentage={this.state.percentage}
            timing={{
              current: this.state.current,
              total: this.state.duration,
            }}
          />
        </Balloon>
        <Button control="record-progress" hidden={!this.state.processRecord || this.state.isRecording} />
        <Button control="record-disabled" hidden={this.state.canRecord || this.state.processRecord} />
        <Button control="record" onClick={this.prepareRecordingHandler} hidden={!this.state.canRecord || this.state.isRecording || this.state.processRecord} disabled={!this.state.canRecord} />
        <Button control="stop" onClick={this.clickStopRecording} hidden={!this.state.isRecording} volume={this.state.volume} />
        <Button control="playback" hidden={this.state.hasRecording && !this.state.isRecording} />
        <Button control="recording-play" onClick={this.startPlayer} hidden={this.state.isRecording || !this.state.hasRecording || this.state.isPlaying} />
        <Button control="load-progress" hidden={this.state.isRecording || !this.state.hasRecording || !this.state.isPlaying || !this.state.isLoading} />
        <Button control="recording-stop" onClick={this.stopPlayer} hidden={this.state.isRecording || !this.state.hasRecording || !this.state.isPlaying || this.state.isLoading} />
      </ButtonGroup>
    );
  }
}

// container part below
const mapStateToProps = (state) => ({
  ...state.ReadingAssignment,
  schoolcode: state.authentication.credentials.schoolcode,
});

const mapDispatchToProps = {
  updateSpeechChallengeRecording,
  updateFeedbackOnWords,
  resetFeedbackOnWords,
  prepareRecording,
  startRecording,
  stopRecording,
  pauseRecording,
  showConnectionErrorMessage,
};

const RecordingControls = withTranslation()(LegacyRecordingControls);

export default connect(mapStateToProps, mapDispatchToProps)(RecordingControls);
