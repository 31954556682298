/*
 * Action types
 */

export const UPDATE_CHOICE_PAGE_STATUS = 'UPDATE_CHOICE_PAGE_STATUS';
export const UPDATE_CHOICE_PROGRESS_STEPS = 'UPDATE_CHOICE_PROGRESS_STEPS';
export const PREV_CHOICE_PAGE = 'PREV_CHOICE_PAGE';
export const NEXT_CHOICE_PAGE = 'NEXT_CHOICE_PAGE';
export const UPDATE_CHOICES_COUNT = 'UPDATE_CHOICES_COUNT';
export const UPDATE_CHOICE_PAGES_COUNT = 'UPDATE_CHOICE_PAGES_COUNT';
export const UPDATE_ACTIVE_CHOICES = 'UPDATE_ACTIVE_CHOICES';
export const RESET_CHOICE_PAGE_NAV_STATE = 'RESET_CHOICE_PAGE_NAV_STATE';
export const RESET_CHOICE_PAGE_STATE = 'RESET_CHOICE_PAGE_STATE';

/*
 * Other costants
 */
export const defaultChoicePageStates = {
  choiceProgressSteps: [],
  choicesCount: 0,
  choicePagesCount: 0,
  activeList: {
    pageId: 0,
    choices: [],
  },
};

/*
 * Action creators
 */

export function updateChoicePage(choicePageStatus) {
  return {
    type: UPDATE_CHOICE_PAGE_STATUS,
    ...choicePageStatus,
  };
}

export function updateChoiceProgressSteps(items) {
  return {
    type: UPDATE_CHOICE_PROGRESS_STEPS,
    items,
  };
}

export function prevChoicePage(currentPageId, pageStep = 1) {
  return {
    type: PREV_CHOICE_PAGE,
    pageId: currentPageId - pageStep,
  };
}

export function nextChoicePage(currentPageId, pageStep = 1) {
  return {
    type: NEXT_CHOICE_PAGE,
    pageId: currentPageId + pageStep,
  };
}

export function updateChoicesCount(choicesCount) {
  return {
    type: UPDATE_CHOICES_COUNT,
    choicesCount,
  };
}

export function updateChoicePagesCount(choicePagesCount) {
  return {
    type: UPDATE_CHOICE_PAGES_COUNT,
    choicePagesCount,
  };
}

export function updateActiveChoices(choices) {
  return {
    type: UPDATE_ACTIVE_CHOICES,
    choices,
  };
}

export function resetChoicePageNavState() {
  return {
    type: RESET_CHOICE_PAGE_NAV_STATE,
  };
}

export function resetChoicePageState() {
  return {
    type: RESET_CHOICE_PAGE_STATE,
  };
}
